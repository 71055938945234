import React, { memo, useCallback } from "react";
import { map } from "lodash";
import { useMutation } from "@apollo/client";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { StyledAddButton } from "theme/StyledComponents";
import { DELETE_INTEGRATION } from "../../../api/integrations";
import { useSubmit } from "hooks/form.hooks";
import WebhookForm from "./WebhookForm";
import CollapsibleCard from "components/CollapsibleCard";

function IntegrationForm(props) {
  const integrations = useSelector((state) => state?.integrations);
  const [deleteIntegration] = useMutation(DELETE_INTEGRATION, {
    variables: {
      integrationId: props?.integrationId
    },
    skip: !props.integrationId
  });

  const handleDeleteIntegration = useSubmit({
    mutation: deleteIntegration,
    dataPath: DELETE_INTEGRATION.definitions[0].name.value,
    onSuccess: props.refetchIntegrations
  });

  const { register, control, getValues } = useForm({
    defaultValues: { ...props.integration, id: props.integrationId } // useFieldArray overwrites id
  });

  // this is needed for free-form ms teams inputs
  const { fields, append, remove } = useFieldArray({
    control,
    name: "webhooks"
  });

  const handleAddWebhook = useCallback(
    () =>
      append({
        channelName: "",
        channelId: ""
      }),
    [append]
  );

  const TitleRow = () => (
    <>
      <div className="me-4" style={{ fontSize: "1.2rem" }}>
        {props?.integration?.type}
      </div>
      <div className="me-5" style={{ fontSize: "1.2rem" }}>
        {props?.integration?.integrationTeamName} workspace
      </div>
    </>
  );

  const IntegrationCardBody = () => (
    <>
      <strong className="mb-3">Feedback Channels (Webhooks)</strong>

      {map(fields, (webhook, i) => (
        <span className="w-100 mb-3" key={webhook.id}>
          <WebhookForm
            parentForm={{ register, getValues, remove, refetchIntegrations: props?.refetchIntegrations }}
            index={i}
            channels={
              props.integration.type === "SLACK"
                ? integrations?.slackChannels ?? []
                : integrations?.msTeamsChannels ?? []
            }
          />
        </span>
      ))}

      <div className="w-100 mt-2 d-flex justify-content-center">
        <StyledAddButton
          onClick={handleAddWebhook}
          style={{ width: "2.5rem", height: "2.5rem" }}
          className="d-flex justify-content-center align-items-center btn shadow border-0"
          data-testid={`ADD_WEBHOOK_${props.integration.type}`}
        >
          <i className="fa fa-plus" />
        </StyledAddButton>
      </div>
    </>
  );

  return (
    <CollapsibleCard TitleRow={TitleRow} Body={IntegrationCardBody} onDelete={handleDeleteIntegration} defaultOpen />
  );
}

IntegrationForm.propTypes = {
  integrationId: PropTypes.string.isRequired,
  refetchIntegrations: PropTypes.func.isRequired,
  integration: PropTypes.shape({
    id: PropTypes.string,
    integrationTeamName: PropTypes.string,
    type: PropTypes.oneOf(["SLACK", "TEAMS"]),
    createdAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    webhooks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        channelName: PropTypes.string,
        channelId: PropTypes.string,
        createdAt: PropTypes.string
      })
    )
  }).isRequired
};

export default memo(IntegrationForm, () => true);
