import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useUpdateQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const cb = useCallback(
    (searchParams, replace = false) => {
      if (replace) {
        history.replace({ ...location, search: searchParams.toString() });
      } else {
        history.push({ ...location, search: searchParams.toString() });
      }
    },
    [history, location],
  );

  return cb;
};
