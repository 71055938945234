import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import jwt from "jwt-decode";
import { useForm } from "react-hook-form";

import apiHandler from "api";
import endpoint from "api/endpoint";
import { saveAuthToken, saveUserData } from "features/login/loginSlice";
import config from "../../config";
import { FormGroup, Spinner } from "reactstrap";
import Logo from "assets/header-logo.png";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import {
  StyledPillButton,
  FormErrorText,
  AuthenticationCard,
  AuthenticationContainer,
  AuthFlowLink,
} from "theme/StyledComponents";
import { showAlert } from "features/Common/CommonSlice";
import { passphraseValidation } from "utils/form.utils";
import { loginRoute, passphraseRecoverRoute } from "routes";

const CompleteRegistration = ({ location }) => {
  const urlParams = new URLSearchParams(location.search);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState();
  const emailAddress = urlParams.get("email")?.replace(/\s/g, "+"); // TODO(MB) this is to handle not encoding/decoding url params correctly yet

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { firstName: "", lastName: "", title: "", passphrase: "" },
  });

  const completeRegistration = async (formValues) => {
    setIsLoading(true);
    const payload = { ...formValues, query: location.search };
    await apiHandler({
      url: endpoint.COMPLETE,
      method: "POST",
      data: payload,
    })
      .then(({ data }) => {
        if (!!data && data.success) {
          const { token } = data;
          dispatch(saveAuthToken(token));
          dispatch(saveUserData(jwt(token)));
          dispatch(showAlert({ message: "Success" }));
          history.push(config.HOME_ROUTE);
        } else if (!!data && data.success === false) {
          dispatch(showAlert({ message: data.message, type: "danger" }));
        }
      })
      .catch((err) => {
        console.log("registration error:", err);
        dispatch(showAlert({ message: err, type: "danger" }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading)
    return (
      <div
        style={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner size="lg" color="dark" />
      </div>
    );

  const validation = { required: "Required" };

  return (
    <AuthenticationContainer>
      <AuthenticationCard>
        <img alt="Katilyst logo" src={Logo} width="100%" />

        <p className="mt-3 text-center" style={{ fontSize: "1.125rem" }}>
          {emailAddress}
        </p>
        <FormGroup className="my-3 d-flex flex-column align-items-center w-100">
          <span className="mb-1 w-100">
            <UncontrolledInput
              formRegister={register}
              name="firstName"
              labelText="FIRST NAME"
              validation={validation}
              height="3.5rem"
              className={errors?.firstName?.message && "error"}
              style={{ width: "100%" }}
            />
            <FormErrorText className="m-0">
              {errors?.firstName?.message}
            </FormErrorText>
          </span>
          <span className="mb-1 w-100">
            <UncontrolledInput
              formRegister={register}
              name="lastName"
              labelText="LAST NAME"
              validation={validation}
              height="3.5rem"
              className={errors?.lastName?.message && "error"}
              style={{ width: "100%" }}
            />
            <FormErrorText className="m-0">
              {errors?.lastName?.message}
            </FormErrorText>
          </span>
          <span className="mb-1 w-100">
            <UncontrolledInput
              formRegister={register}
              name="title"
              labelText="TITLE"
              height="3.5rem"
              style={{ width: "100%" }}
            />
            <FormErrorText className="m-0">
              {errors?.title?.message}
            </FormErrorText>
          </span>
          <span className="mb-1 w-100">
            <UncontrolledInput
              formRegister={register}
              name="passphrase"
              labelText="PASSPHRASE"
              validation={passphraseValidation}
              height="3.5rem"
              passwordInput
              className={errors?.passphrase?.message && "error"}
              style={{ width: "100%" }}
            />
            <FormErrorText className="m-0">
              {errors?.passphrase?.message}
            </FormErrorText>
          </span>
        </FormGroup>

        <StyledPillButton
          type="button"
          className="submitBtn mx-auto"
          onClick={handleSubmit(completeRegistration)}
          style={{ width: "12rem" }}
        >
          Finish Registration
        </StyledPillButton>
        <AuthFlowLink to={loginRoute.path} className="text-center my-3">
          Already have an account? <span>Sign in</span>
        </AuthFlowLink>
        <AuthFlowLink
          to={passphraseRecoverRoute.path}
          className="text-center d-block"
        >
          Forgot passphrase?
        </AuthFlowLink>
      </AuthenticationCard>
    </AuthenticationContainer>
  );
};

export default CompleteRegistration;
