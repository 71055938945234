import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import { v4 as uuid } from "uuid";

// * Components
import { UncontrolledTooltip } from "reactstrap";
import { StyledController } from "../theme/StyledController";
import { Field } from "../theme/StyledField";

// * REACT-SELECT Docs: https://react-select.com/home
// * REACT-HOOK-FORM Controller docs: https://react-hook-form.com/docs/useform/control
function CustomSelect({
  control,
  customEmit = false,
  defaultValue,
  disabled = false,
  displayKey,
  emitValue = () => ({}),
  extraWidth = false,
  formRegister = () => ({}),
  formState,
  height,
  isAdmin = false,
  label,
  loading = false,
  name,
  options = [],
  tooltips = false,
  width,
  validation = {},
  valueKey = "id",
  ...rest
}) {
  // * Select requires certain fields for value access -> get data into right format
  const formatOptions = () => {
    if (options.length > 0) {
      const format = [];
      options.map((option) => {
        format.push({
          value: !!valueKey ? option[valueKey] : option?.id,
          tooltip: option?.tooltipText,
          label: option[displayKey] || option?.value,
          display: option.display ?? null,
          new: option.new ?? false
        });
      });
      return format;
    }
  };

  // * Select requires certain fields for value access -> get default value into right format
  const formatInitialDefault = () => {
    const setDefault = defaultValue ? defaultValue : options[0];
    if (setDefault) {
      let value = setDefault;
      return {
        value: !!valueKey ? value[valueKey] : value?.id,
        title: value?.message,
        label: value[displayKey] || value?.value,
        display: value.display ?? null,
        new: value.new ?? false
      };
    }
  };

  const [formattedOptions, setFormattedOptions] = useState(formatOptions);
  const [formattedDefault, setFormattedDefault] = useState(formatInitialDefault);

  // * CustomSingleOption allows for display of tooltips in custom defined component
  const CustomSingleOption = (props) => {
    const target = `abc-${props.data.value}`;
    return (
      <components.Option {...props}>
        <div key={uuid()} style={{ display: "contents" }}>
          {tooltips && (
            <UncontrolledTooltip placement="right" target={target} style={{ zIndex: 2005, marginLeft: "200px" }}>
              {props.data.tooltip}
            </UncontrolledTooltip>
          )}
          <div
            id={target}
            data-new={props.data.new || false}
            aria-label={props.data.label}
            style={{ marginLeft: "2px" }}
          >
            {props.data.label}
          </div>
        </div>
      </components.Option>
    );
  };

  // * Anytime the defaultValue changes - set formattedDefault
  useEffect(() => {
    if (defaultValue) {
      const val = {
        value: !!valueKey ? defaultValue[valueKey] : defaultValue?.id,
        tooltip: defaultValue?.message,
        label: defaultValue[displayKey] || defaultValue?.value
      };
      setFormattedDefault(val);
    }
  }, [defaultValue, displayKey, valueKey]);

  // * Anytime the options changes - ensure correct format
  useEffect(() => {
    setFormattedOptions(formatOptions);
  }, [options]);

  // * Custom emit for non react-hook-form use components
  const handleEmitValue = (value) => emitValue(value);

  // * CSS and CUSTOM STYLES DISTRICT 🏢
  const containerStyles = (base) => {
    return {
      ...base,
      backgroundColor: disabled ? "#ececec" : "transparent",
      cursor: disabled ? "not-allowed" : "pointer"
    };
  };

  const customTheme = (theme) => {
    return {
      ...theme,
      borderRadius: 5,
      spacing: {
        ...theme.spacing,
        controlHeight: 35,
        baseUnit: 0,
        menuGutter: 1
      }
    };
  };

  const controlStyles = (base) => {
    return {
      ...base,
      width: width ?? base.width,
      borderColor: "lightgrey",
      textAlign: "left",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
      background: "transparent",
      fontWeight: 500,
      fontSize: "16px",
      fontFamily: "system-ui",
      color: disabled ? "#aaa" : "black"
    };
  };

  const menuStyles = (base) => {
    return {
      ...base,
      overflowY: "scroll",
      zIndex: 2000
    };
  };

  const optionStyles = (base) => {
    return {
      ...base,
      cursor: "pointer",
      marginTop: 6,
      marginLeft: 2,
      marginBottom: 6,
      padding: "2%",
      textAlign: "left",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "system-ui",
      width: extraWidth ? "100%" : base.width
    };
  };

  const placeholderStyles = (base) => {
    return {
      ...base,
      marginLeft: 6,
      marginRight: 5,
      width: width ?? base.width,
      color: "black",
      fontWeight: 400,
      fontSize: "16px",
      fontFamily: "system-ui"
    };
  };

  const singleValueStyles = (base) => {
    return {
      ...base,
      marginLeft: "6px",
      fontWeight: "normal",
      color: "black"
    };
  };

  // * selectRef allows for focus and blur on click entry to element
  const selectRef = useRef(0);
  const focus = () => {
    selectRef.current?.focus();
  };

  // * The Component - StyledController controls form register from react-hook-form
  return (
    <Field {...rest} width={width ?? "20rem"} height={height ?? "3rem"} onClick={focus}>
      <StyledController
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              ref={selectRef}
              aria-labelledby="aria-label"
              components={{ Option: CustomSingleOption }}
              data-testid={`CUSTOM_SELECT_${name}`}
              defaultValue={value}
              filterable={true}
              formRegister={{ ...formRegister, validation }}
              inputId={`${name}-${valueKey}-select`}
              isDisabled={disabled}
              isLoading={loading}
              maxMenuHeight={200}
              menuPortalTarget={document.body}
              name={name}
              onChange={(selected) => {
                if (customEmit) {
                  return handleEmitValue(selected.value);
                }
                return !onChange(selected.value);
              }}
              openMenuOnFocus={true}
              options={formattedOptions}
              placeholder={formattedDefault?.label}
              theme={customTheme}
              value={value}
              styles={{
                container: (base) => containerStyles(base),
                menu: (base) => menuStyles(base),
                menuList: (base) => menuStyles(base),
                singleValue: (base) => singleValueStyles(base),
                placeholder: (base) => placeholderStyles(base),
                option: (base) => optionStyles(base),
                control: (base) => controlStyles(base),
                menuPortal: (base) => {
                  return {
                    ...base,
                    zIndex: 2001,
                    width: extraWidth ? "20%" : base.width
                  };
                },
                dropdownIndicator: (base) => ({
                  ...base,
                  marginRight: 3
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none"
                })
              }}
            />
          );
        }}
      />
      <div className="label">{label}</div>
    </Field>
  );
}

export default CustomSelect;
