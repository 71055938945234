import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
  query getAccounts {
    getAccounts {
      id
      name
      emailDomain
      accountTier
      admins {
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_ROLES = gql`
  query getRolesForDisplay {
    getRolesForDisplay {
      id
      color
      displayValue
      message
      role
      tooltipText
    }
  }
`;
