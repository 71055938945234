import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectProjectList, setProjectData } from "features/userData/userDataSlice";
import { useQueryParams } from "./useQueryParams";
import { projectNotFound } from "routes";

export const useCheckUserHasProjects = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectList = useSelector(selectProjectList);
  const queryParams = useQueryParams();

  const noProjects = projectList === undefined || projectList?.length === 0;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (noProjects) {
        history.push({ pathname: projectNotFound.path, search: queryParams.toString() });
        dispatch(setProjectData({}));
      }
    }, 6500);

    return () => clearTimeout(timer);
  }, [noProjects, history.location.pathname]); // eslint-disable-line
};
