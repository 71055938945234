const endpoint = {
  SERVER_LOCAL: process.env.REACT_APP_API_BASE,
  SERVER_LOCAL_GRAPHQL: `${process.env.REACT_APP_API_BASE}/graphql`,
  COMPLETE: "/complete-registration",
  CREATE: "/create",
  LOGIN: "/login",
  LOGIN_SSO: "/login/sso",
  SIGNUP: "/register",
  PROFILE: "/profile",
  PROFILE_AVATAR: "/profile/avatar",
  ATTRIBUTE_ICON: (id) => `/attributes/${id}/icon`,
  ATTRIBUTE_VALUE_ICON: (id) => `/attribute-value/${id}/icon`,
};

export default endpoint;
