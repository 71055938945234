import { memo } from "react";
import { Link } from "react-router-dom";

import { useQueryParams } from "hooks/application";

// HOC that returns a Link component with location.search maintained if not passed explicitly
const KatilystLink = (props) => {
  const queryParams = useQueryParams();
  const pathname = typeof props?.to === "string" ? props.to : props?.to?.pathname;
  const search =
    typeof props?.to?.search === "string" && props.to.search.length > 0 ? props.to.search : queryParams.toString();

  return <Link {...props} to={{ pathname, search }} />;
};

export default memo(KatilystLink);
