import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";

import { ActionFormButton, FormErrorText, StyledCardShadow } from "../../theme/StyledComponents";
import ConfirmationModal from "../ConfirmationModal";
import { UncontrolledInput } from "../CustomInput/CustomInput";

import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CHECK_CONFIG_NAME_UNIQUE, DELETE_UPLOAD_ACTION_MAPPING_CONFIG } from "../../api/csv-upload";
import { checkConfigName } from "../../utils/csv.utils";

// * SaveColumnAndActionMappingModal: handles create, update, and delete of ActionUploadConfigs
const SaveColumnAndActionMappingModal = ({
  columnAndActionMaps = [],
  projectId,
  configId,
  emitSavedMapping,
  emitRefetchActionMappingConfigs,
  toggleSaveColumnAndActionMappingModal,
  currentConfigName,
  handleError,
  handleSuccess
}) => {
  const [checkConfigNameUnique] = useMutation(CHECK_CONFIG_NAME_UNIQUE);
  const [deleteActionMappingConfig] = useMutation(DELETE_UPLOAD_ACTION_MAPPING_CONFIG);

  const [actionMaps, setColumnAndActionMaps] = useState(columnAndActionMaps);
  const [confirmationBodyText, setConfirmationBodyText] = useState(null);
  const [createNew, setCreateNew] = useState(false);
  const [hoverId, setHoverId] = useState(null); // * hover css toggle on individual rows
  const [hoverNew, setHoverNew] = useState(false); // * hover css toggle on "NEW" button
  const [idForDelete, setIdForDelete] = useState(null);
  const [mapping, setMapping] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [uniqueNameWarning, setUniqueNameWarning] = useState(null);

  const toggleConfirm = () => setShowConfirm(!showConfirm);

  const toggleCreateNew = () => {
    setHoverNew(false);
    setCreateNew(!createNew);
  };

  const {
    reset,
    register,
    watch,
    getValues,
    formState: { dirtyFields }
  } = useForm();

  const mappingName = watch("mappingName");

  const handleSelectedMapping = () => {
    if (createNew) {
      const value = getValues().mappingName;
      emitSavedMapping({ id: null, configName: value });
    } else {
      emitSavedMapping({ id: mapping, configName: null });
    }
  };

  const selectConfig = (id) => {
    setMapping(id);
    setHoverId(null);
  };

  const handleSetIdForDelete = (id) => {
    setCreateNew(false);
    setIdForDelete(id);
    setConfirmationBodyText("Delete selected config?");
    toggleConfirm();
  };

  const deleteSelected = async () => {
    await deleteActionMappingConfig({
      variables: { configId: idForDelete, projectId: projectId },
      onError: (e) => {
        handleError(e.message);
      },
      onCompleted: (res) => {
        if (res.deleteActionMappingConfig.success) {
          setConfirmationBodyText(null);
          emitRefetchActionMappingConfigs(true);
          setColumnAndActionMaps(actionMaps.filter((map) => map.id !== idForDelete));
          setIdForDelete(null);
          toggleConfirm();
          handleSuccess("Config deleted.");
        }
      }
    });
  };

  useCheckConfigNameUnique(mappingName, dirtyFields, checkConfigNameUnique, projectId, setUniqueNameWarning);

  const handleCancelAndResetModal = () => {
    reset();
    toggleSaveColumnAndActionMappingModal();
  };

  const addHover = (id) => {
    if (mapping !== id) {
      setHoverId(id);
    }
  };

  const removeHover = () => {
    setHoverId(null);
  };

  return (
    <>
      {showConfirm && (
        <ConfirmationModal
          type="warning"
          toggle={toggleConfirm}
          handleSubmit={createNew ? handleSelectedMapping : deleteSelected}
          bodyText={confirmationBodyText}
        />
      )}
      <Modal isOpen>
        <ModalBody className="text-center">
          <div className="d-flex flex-row justify-content-around mt-2">
            <h5 className="m-auto">Save Column and Action Mapping</h5>
            {!createNew && (
              <div className="d-flex flex-row justify-content-end me-2">
                <ActionFormButton
                  id="submit"
                  type="button"
                  onClick={handleSelectedMapping}
                  data-testid="SUBMIT_SAVE_ACTION_MAPPING_MODAL"
                  className="btn btn-outline-success shadow border-0 ms-2"
                  disabled={!mapping || uniqueNameWarning}
                >
                  <i className="fa fa-check" />
                </ActionFormButton>

                <ActionFormButton
                  id="cancel"
                  onClick={() => toggleSaveColumnAndActionMappingModal()}
                  className="btn shadow border-0 btn-outline-danger ms-2"
                  data-testid="CLOSE_ACTION_MAPPING_MODAL"
                >
                  <i className="fa fa-times" />
                </ActionFormButton>
              </div>
            )}
          </div>
          {createNew ? (
            <div>
              <div className="d-flex flex-row justify-content-around mt-2">
                <UncontrolledInput
                  labelText="NAME"
                  formRegister={register}
                  name="mappingName"
                  maxWidth="30rem"
                  width="30rem"
                  testId="SAVE_CONFIGmappingName_INPUT"
                  className={uniqueNameWarning && "error"}
                  defaultValue={currentConfigName ?? ""}
                />
                <div className="d-flex flex-row justify-content-end me-2">
                  <ActionFormButton
                    id="submitSaveMappingName"
                    type="button"
                    onClick={handleSelectedMapping}
                    className="btn btn-outline-success shadow border-0 ms-2"
                    disabled={!mappingName || uniqueNameWarning}
                  >
                    <i className="fa fa-check" />
                  </ActionFormButton>
                  <ActionFormButton
                    id="cancel"
                    onClick={() => handleCancelAndResetModal()}
                    className="btn shadow border-0 btn-outline-danger ms-2"
                    data-testid="CLOSE_ACTION_MAPPING_MODAL"
                  >
                    <i className="fa fa-times" />
                  </ActionFormButton>
                </div>
              </div>
              <FormErrorText className="d-flex justify-content-start" id="uniqueNameWarning">
                {uniqueNameWarning}
              </FormErrorText>
            </div>
          ) : (
            <>
              {actionMaps.length > 0 && (
                <StyledCardShadow className="text-center mt-2" style={{ height: "250px", overflowY: "scroll" }}>
                  {actionMaps.map((cam) => {
                    return (
                      <div
                        key={cam.id}
                        className="mt-1 m-auto"
                        style={
                          hoverId === cam.id && mapping !== cam.id
                            ? {
                                ...styles.mapping,
                                backgroundColor: "#F3F8FF"
                              }
                            : { ...styles.mapping }
                        }
                      >
                        <div
                          className="d-flex flex-row justify-content-between m-auto w-100"
                          style={
                            mapping === cam.id && hoverId !== cam.id
                              ? {
                                  backgroundColor: "#e2edff",
                                  color: "black",
                                  padding: ".5rem",
                                  borderRadius: "5px"
                                }
                              : { color: "black", padding: ".5rem" }
                          }
                        >
                          <div
                            onMouseEnter={() => addHover(cam.id)}
                            onMouseLeave={() => removeHover()}
                            onClick={() => selectConfig(cam.id)}
                            data-testid={`select-${cam.configName}`}
                            className="w-100 text-start m-auto ms-3"
                          >
                            {cam.configName}
                          </div>
                          {configId !== cam.id ? (
                            <div className="d-flex flex-row justify-content-end">
                              <ActionFormButton
                                id="cancel"
                                onClick={() => handleSetIdForDelete(cam.id)}
                                className="btn border-0 btn-outline-danger"
                                data-testid={`delete-${cam.configName}`}
                              >
                                <i className="fa fa-times" />
                              </ActionFormButton>
                            </div>
                          ) : (
                            <div style={{ height: "35px" }} />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </StyledCardShadow>
              )}
              <div
                onMouseEnter={() => setHoverNew(true)}
                onMouseLeave={() => setHoverNew(false)}
                className="p-1 m-auto mt-2"
                data-testid="TOGGLE_SAVE_NEW_CONFIG_INPUT"
                style={
                  hoverNew
                    ? { ...styles.newInput, backgroundColor: "#b7efc7" }
                    : { ...styles.newInput, backgroundColor: "#C5F0D0" }
                }
                onClick={toggleCreateNew}
              >
                New...
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

// * custom hook
const useCheckConfigNameUnique = (mappingName, dirtyFields, checkConfigNameUnique, projectId, setUniqueNameWarning) => {
  useEffect(() => {
    if (mappingName && !!dirtyFields["mappingName"]) {
      checkConfigName(mappingName, projectId, checkConfigNameUnique).then((res) => {
        if (res.data.checkConfigNameUnique.success) {
          setUniqueNameWarning(null);
        } else {
          setUniqueNameWarning(res.data.checkConfigNameUnique.message);
        }
      });
    }
  }, [mappingName, dirtyFields, checkConfigNameUnique, projectId, setUniqueNameWarning]);
};

// * styles
const styles = {
  mapping: {
    borderWidth: "1px",
    borderColor: "#EFEFEF",
    borderStyle: "solid",
    borderRadius: "5px",
    width: "100%",
    cursor: "pointer"
  },
  newInput: {
    cursor: "pointer",
    borderRadius: "5px"
  }
};

export default SaveColumnAndActionMappingModal;
