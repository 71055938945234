import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Alert, Spinner } from "reactstrap";
import { get } from "lodash";
import {
  StyledPillButton,
  FormErrorText,
  AuthenticationContainer,
  AuthenticationCard,
  AuthFlowLink
} from "theme/StyledComponents";
import Logo from "assets/header-logo.png";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { useMutation } from "@apollo/client";
import { TRIGGER_RESET_PASSPHRASE } from "api/users";
import { loginRoute } from "routes";
import Joi from "joi";

const RecoverPassphrase = () => {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: { email: "" }
  });

  const emailInput = watch("email");

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [isLoading, setIsLoading] = useState();
  const [focus, setFocus] = useState(true);
  const [emailValid, setEmailValid] = useState(false);

  const [triggerResetPassphrase] = useMutation(TRIGGER_RESET_PASSPHRASE);

  const triggerPassphraseFlow = useCallback(
    (formValues) => {
      if (!!emailInput && emailValid) {
        setIsLoading(true);
        const { email } = formValues;
        triggerResetPassphrase({
          variables: { email: email.trim().toLowerCase() }
        })
          .then(({ data }) => {
            const response = get(data, `${TRIGGER_RESET_PASSPHRASE.definitions[0].name.value}`);
            if (!!data && response?.success) {
              setSuccessMessage(response.message);
              setShowForm(false);
              setShowSuccess(true);
              setShowError(false);
            } else if (!!data && response?.success === false) {
              setErrorMessage(response.message);
              setShowForm(false);
              setShowError(true);
            }
          })
          .catch((err) => {
            setErrorMessage(err);
            setShowError(true);

            console.log("registration error:", err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [emailInput, emailValid, triggerResetPassphrase]
  );

  // * check email for validity as soon as input isDirty
  useEffect(() => {
    if (!!emailInput) {
      const schema = Joi.string().email({ tlds: { allow: false } });
      const test = !schema.validate(emailInput).error;
      setEmailValid(test);
    }
  }, [setEmailValid, emailInput]);

  if (isLoading)
    return (
      <div
        style={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh"
        }}
      >
        <Spinner size="lg" color="dark" />
      </div>
    );

  return (
    <AuthenticationContainer>
      <AuthenticationCard>
        <img alt="Katilyst logo" src={Logo} width="100%" />
        <Form>
          <Alert color="danger" isOpen={showError} className="my-5">
            {errorMessage}
            &nbsp;
          </Alert>
          <Alert color="success" isOpen={showSuccess} className="my-5">
            {successMessage}
          </Alert>
          {showForm && (
            <>
              <FormGroup className="mt-5 mb-4" onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
                <span>
                  <UncontrolledInput
                    style={{ width: "100%" }}
                    formRegister={register}
                    validation={{
                      required: "Required"
                    }}
                    name="email"
                    labelText="EMAIL"
                    height="3.5rem"
                    className={!emailValid && !!emailInput && !focus && "error"}
                  />
                  <FormErrorText className="m-0" data-testid="EMAIL_VALIDATION_WARNING">
                    {!emailValid && !!emailInput && !focus && "Please enter a valid email"}
                  </FormErrorText>
                </span>
              </FormGroup>

              <StyledPillButton
                style={{ width: "17rem" }}
                type="submit"
                className="mx-auto"
                onClick={handleSubmit(triggerPassphraseFlow)}
              >
                Send Forgot Passphrase Email
              </StyledPillButton>
            </>
          )}
        </Form>
        <AuthFlowLink to={loginRoute.path} className="text-center my-3">
          Already have an account? <span>Log in</span>
        </AuthFlowLink>
      </AuthenticationCard>
    </AuthenticationContainer>
  );
};

export default React.memo(RecoverPassphrase);
