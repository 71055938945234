import React from "react";
import { Tooltip } from "reactstrap";

import { TruncateText } from "theme/StyledComponents";

export const OverflowTooltip = (props) => {
  const [tooltipEnabled, setTooltipEnabled] = React.useState(false);
  const [cursor, setCursor] = React.useState("default");

  const handleShouldShow = React.useCallback(({ currentTarget }) => {
    const shouldShow = currentTarget.scrollWidth > currentTarget.clientWidth;
    if (shouldShow) {
      setTooltipEnabled(true);
      setCursor("help");
    }
  }, []);

  const hideTooltip = React.useCallback((e) => {
    e.preventDefault();
    if (
      (e?.relatedTarget && e.relatedTarget?.getAttribute("class")?.includes("tooltip")) ||
      e?.relatedTarget?.id === props.target
    ) {
      // tooltip itself is causing the mouseLeave event
      return;
    } else {
      setTooltipEnabled(false);
    }
  }, []);

  React.useEffect(() => {
    let tooltip = null;
    if (tooltipEnabled) {
      tooltip = document.getElementsByClassName(`${props.target}-tooltip-auto`)[0];

      tooltip?.addEventListener("mouseleave", hideTooltip);
    }

    return () => tooltip?.removeEventListener("mouseleave", hideTooltip);
  }, [tooltipEnabled]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <Tooltip
        target={props.target}
        isOpen={tooltipEnabled}
        placement="auto"
        placementPrefix={`${props.target}-tooltip`}
        onMouseLeave={hideTooltip}
        className="text-start shadow"
        style={{
          textAlign: "left",
          maxWidth: "25rem",
          width: "25rem"
        }}
      >
        {props.text}
      </Tooltip>
      <TruncateText
        id={props.target}
        style={{
          cursor,
          lineHeight: "2rem"
        }}
        onMouseEnter={handleShouldShow}
        onMouseLeave={hideTooltip}
      >
        {props.text}
      </TruncateText>
    </div>
  );
};
