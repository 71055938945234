import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setRedirect } from "features/userData/userDataSlice";
import { useQueryParams } from "./useQueryParams";
import { useUpdateQueryParams } from "./useUpdateQueryParams";

export function useSetRedirectToPersistedState() {
  const updateQueryParams = useUpdateQueryParams();
  const queryParams = useQueryParams();
  const dispatch = useDispatch();
  const redirectPath = queryParams.get("rd");

  useEffect(() => {
    if (redirectPath) {
      console.count(`SETTING REDIRECT TO ${redirectPath}`);
      queryParams.delete("rd");
      updateQueryParams(queryParams, true); // we want to use history.replace so you can navigate backwards
      dispatch(setRedirect(redirectPath));
    }
  }, [redirectPath, dispatch, queryParams, updateQueryParams]);
}
