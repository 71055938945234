import React from "react";
import { Route } from "react-router-dom";
import { useHistory, Redirect } from "react-router-dom";
import Login from "containers/Login/Login";
import { useIsUserAuthorized } from "hooks/application.hooks";
import { myAttributesRoute } from "routes";
import { useQueryParams } from "hooks/application";

function ProtectedRoute({ component: Component, requiredRoles = [], ...rest }) {
  const queryParams = useQueryParams();
  const { authenticated, authorized } = useIsUserAuthorized(requiredRoles);
  const history = useHistory();

  return (
    <Route
      {...rest}
      render={() => {
        if (authenticated) {
          return (
            <>{authorized ? <Component /> : <Redirect to={`${myAttributesRoute.path}${queryParams.toString()}`} />}</>
          );
        } else {
          // TODO(MB) this iis weird, come back to it
          history.push("/login");
          return <Login />;
        }
      }}
    />
  );
}

export default ProtectedRoute;
