import { useContext } from "react";
import { SplitContext } from "@splitsoftware/splitio-react";
import { useSelector } from "react-redux";

import { selectUserAccountAccess } from "../../../features/userData/userDataSlice";
import { ACCOUNT_TIER } from "../utils";

export default function useFeatureFlag(treatmentName, attributes = {}) {
  const { client } = useContext(SplitContext);
  const { tier = ACCOUNT_TIER.FREEMIUM } = useSelector(selectUserAccountAccess) || {};

  const treatmentValue = client.getTreatment(treatmentName, {
    ...attributes,
    ACCOUNT_TIER: tier,
  });

  return treatmentValue === "on";
}
