import { CardHeader } from "reactstrap";
import styled from "styled-components";

export const StyledAttributeHeading = styled.div`
  display: flex;
  justify-content: center;

  h3 {
    line-height: 50px;
  }

  img {
    width: 55px;
  }
`;

export const AddNewAttributeBar = styled.div`
  display: flex;
  justify-content: center;
  transition: 0.3s;
  margin: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 2px rgb(0 0 0 / 8%);
  padding: 10px 20px;

  @media (max-width: 767px) {
    margin: 10px 0;
    padding: 5px;
  }
`;

export const AddNewAttributeBlock = styled.div`
  background: #fff;
  transition: 0.3s;
  padding: 10px;
  margin: 10px 0;

  @media (max-width: 767px) {
    border-bottom: 1px solid #c5c5c5;
    margin: 0;
    padding: 0;
  }
`;

export const SelectAttributeSpan = styled.span`
  background-color: #dcf7e3;
  color: #5a864b;
  padding: 10px;
  margin: 0px 20px;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;

  @media (max-width: 767px) {
    margin: 0 5px;
    padding: 5px;
  }
`;

export const StyledIcon = styled.i`
  background: ${(props) => props.background || "#80808036"};
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
  width: 45px;
  height: 45px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 100;
  ${(props) => props.externalCss}

  @media(max-width: 768px) {
    margin: 5px auto;
  }
`;

export const StyledCardHeader = styled(CardHeader)`
  text-align: left;
  background: transparent;
  border-bottom: 1px solid #c5c5c5;
  cursor: pointer;
`;

export const StyledHeadingSpan = styled.span`
  margin: 0 50px 0 10px;
  line-height: 40px;
  font-size: 1.25rem;

  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 30px;
    margin: 0;
  }
`;

export const StyledSaveButtonRow = styled.div`
  position: fixed;
  background: #fff;
  width: 100%;
  padding: 15px;
  bottom: 6%;
  left: 0;
  box-shadow: 0px -4px 3px #00000082;
  display: flex;
  align-items: center;
`;
