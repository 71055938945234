import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { Card } from "reactstrap";
import { keyBy, isEmpty } from "lodash";

import CustomImageDisplay from "components/CustomImage/CustomImageDisplay";
import CustomSelect from "components/CustomSelect";
import { ActionFormButton, TextSection } from "theme/StyledComponents";
import {
  ACCEPT_USER_CONNECTION_REQUEST,
  UPDATE_USER_ATTRIBUTE_CONNECTION,
  DECLINE_USER_CONNECTION_REQUEST
} from "api/attributes";
import { useSubmit } from "hooks/form.hooks";
import DefaultAvatar from "../../assets/user-icon.png";

function ConnectionCard({
  title,
  options = [],
  primaryImageUrl,
  secondaryImageUrl,
  targetUserSide,
  connectionId,
  initialValue = "NONE",
  sourceUserId,
  incomingRequest = null,
  outgoingRequest = null,
  handleSuccess = () => ({})
}) {
  const [updateConnection, { loading: updating }] = useMutation(UPDATE_USER_ATTRIBUTE_CONNECTION);
  const [acceptRequest, { loading: accepting }] = useMutation(ACCEPT_USER_CONNECTION_REQUEST);
  const [declineRequest, { loading: declining }] = useMutation(DECLINE_USER_CONNECTION_REQUEST);

  const primaryIconSize = 85;
  const secondaryIconSize = 75;

  const connectionDict = keyBy(options, "id");

  const form = useForm({ defaultValues: { targetUserId: initialValue } });
  const targetUserId = form.watch("targetUserId");

  const optionsStringified = JSON.stringify(options); // this is setting initial value of ConnectionCard on mount
  useEffect(() => {
    form.setValue("targetUserId", initialValue);
  }, [initialValue, optionsStringified]); // eslint-disable-line

  const submit = useSubmit({
    mutation: updateConnection,
    useVariables: true,
    dataPath: UPDATE_USER_ATTRIBUTE_CONNECTION.definitions[0].name.value,
    variables: {
      sourceUserId,
      targetUserId,
      connectionId,
      targetUserSide
    },
    onSuccess: () => {
      form.reset(undefined, { keepValues: true });
      handleSuccess();
    }
  });

  const accept = useSubmit({
    mutation: acceptRequest,
    useVariables: true,
    dataPath: ACCEPT_USER_CONNECTION_REQUEST.definitions[0].name.value,
    variables: {
      requestId: incomingRequest?.id
    },
    onSuccess: () => {
      handleSuccess();
      form.setValue("targetUserId", incomingRequest?.sourceUser?.id);
    }
  });

  const decline = useSubmit({
    mutation: declineRequest,
    useVariables: true,
    dataPath: DECLINE_USER_CONNECTION_REQUEST.definitions[0].name.value,
    variables: {
      requestId: incomingRequest?.id
    },
    onSuccess: () => {
      handleSuccess();
      setTimeout(handleSuccess, 1000);
    }
  });

  return (
    <Card
      className={`shadow-sm p-3 ${(!isEmpty(incomingRequest) || !isEmpty(outgoingRequest)) && "border-danger"}`}
      style={{ minHeight: 97 }}
      data-testid={`CONNECTION-${title}`}
    >
      <div className="card-body flex-row d-flex py-0">
        <div className="col-md-3 d-flex justify-content-center align-items-center">
          {!!primaryImageUrl && (
            <CustomImageDisplay height={primaryIconSize} url={primaryImageUrl} key={primaryImageUrl} />
          )}
        </div>

        <TextSection className="offset-1">
          <h5 className="card-title mb-1">{title}</h5>
          <div className="d-flex justify-content-between align-items-center">
            <CustomSelect
              displayKey="fullName"
              height="2rem"
              options={options}
              width="9rem"
              formRegister={form.register}
              name="targetUserId"
              control={form.control}
              defaultValue={options?.find((option) => option.id === targetUserId)}
            />

            {form.formState.isDirty && initialValue !== targetUserId && (
              <ActionFormButton
                data-testid="MAKE_CONNECTION"
                type="button"
                className="btn btn-outline-info shadow border-0"
                onClick={form.handleSubmit(submit)}
                style={{ transform: "scale(0.75, 0.75)" }}
                disabled={updating}
              >
                <i className="fa fa-check" />
              </ActionFormButton>
            )}
          </div>

          {!isEmpty(incomingRequest) && (
            <p className="my-2 text-danger" data-testid="INCOMING_REQUEST">
              Set {title} to {incomingRequest?.sourceUser?.firstName} {incomingRequest?.sourceUser?.lastName}?
            </p>
          )}

          {!isEmpty(outgoingRequest) && (
            <div className="my-2 text-muted fst-italic" data-testid="OUTGOING_REQUEST">
              <p>
                {" "}
                Awaiting response from{" "}
                {outgoingRequest?.targetUser?.firstName
                  ? `${outgoingRequest?.targetUser?.firstName} ${outgoingRequest?.targetUser?.lastName}`
                  : outgoingRequest.targetUser.email}
              </p>
            </div>
          )}
        </TextSection>
        <div className="col-md-2 d-flex flex-column justify-content-center align-items-center">
          <span className="mb-1">
            <CustomImageDisplay
              height={secondaryIconSize}
              url={connectionDict[targetUserId]?.avatar || DefaultAvatar}
              key={secondaryImageUrl}
              style={{ objectFit: "cover" }}
            />
          </span>
          {!isEmpty(incomingRequest) && (
            <div className="d-flex justify-content-between">
              <ActionFormButton
                data-testid="CONFIRM_CONNECTION"
                type="button"
                className="btn btn-outline-success shadow border-0"
                onClick={accept}
                style={{ transform: "scale(0.75, 0.75)" }}
                disabled={accepting | declining}
              >
                <i className="fa fa-check" />
              </ActionFormButton>

              <ActionFormButton
                data-testid="DECLINE_CONNECTION"
                type="button"
                className="btn border-0 shadow btn-outline-danger"
                onClick={decline}
                style={{ transform: "scale(0.75, 0.75)" }}
                disabled={updating | declining}
              >
                <i className="fa fa-times" />
              </ActionFormButton>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default React.memo(ConnectionCard);
