import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// * Components
import BasicModal from "components/BasicModal";
import { FormGroup, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import HeaderLogo from "components/Header/HeaderLogo";
import KatilystLink from "hocs/KatilystLink";
import PassphraseModal from "components/PassphraseModal";
import SideMenu from "components/SideMenu/SideMenu";

// * Helpers
import config from "../../../config";
import { map, intersection } from "lodash";
import { v4 as uuid } from "uuid";

// * Hooks/Utils
import { baseLogout } from "app/rootReducer";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import { getIsAuthorized } from "utils/auth.utils";
import { getLinkStyles } from "./utils";
import { useSubmenu, useAccounts } from "../../../hooks/application.hooks";
import { useAccountFromParams, useProjectFromParams } from "hooks/application";
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";
import {
  selectUserAccountAccess,
  selectSSOEnabled,
  selectAccountData,
  selectProjectData
} from "features/userData/userDataSlice";

// * Images/CSS
import Logo from "assets/header-logo.png";
import DefaultAvatar from "assets/user-icon.png";
import "./header.css"; // TODO(MB) rmv external css in favor of styled components

// * Routes
import { insightsActionsRoute, topLevelNav, insightsUsersRoute } from "routes";

// * Selectors
import AccountSelector from "components/AccountSelector";
import ProjectSelector from "components/ProjectSelector";
import UpdateProjectHeaderLogoForm from "../../../components/Forms/UpdateProjectHeaderLogoForm";

const Header = (props) => {
  const allowUserRegister = useFeatureFlag(FEATURE_FLAGS.USER_REGISTRATION);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [passphraseModalVisible, setShowPassphraseModal] = useState(false);
  const [edit, setEdit] = useState(false);

  const ssoEnabled = useSelector(selectSSOEnabled);
  const { loading: accountsLoading } = useAccounts();
  const { permissions = ["user"] } = useSelector(selectUserAccountAccess) || {};

  const dispatch = useDispatch();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem(config.KEEP_ME_LOGGED_IN_KEY);
    dispatch(baseLogout());
  };
  const toggleUserMenu = () => setShowUserMenu(!showUserMenu);
  const togglePassphraseModal = () => setShowPassphraseModal(!passphraseModalVisible);

  useProjectFromParams();
  useAccountFromParams();

  const { subMenuLinks, topLevelRoute } = useSubmenu();

  const { userImage } = useSelector((state) => state.userData);
  const account = useSelector(selectAccountData);
  const project = useSelector(selectProjectData);

  const refetchHeader = useCallback(() => {
    window.location.reload();
  }, []);

  if (accountsLoading) {
    return null;
  }

  const userAccountLogo = project?.headerLogo ?? Logo;
  return (
    <>
      {passphraseModalVisible && <PassphraseModal toggle={togglePassphraseModal} />}
      {edit && (
        <BasicModal>
          <UpdateProjectHeaderLogoForm
            accountId={account?.id}
            projectId={project?.id}
            accountLogoUrl={project?.headerLogo}
            setEdit={setEdit}
            refetchDashboard={refetchHeader}
          />
        </BasicModal>
      )}
      <div className="container-fluid p-0 hide-mobile">
        <header className="toolbar">
          <nav className="toolbar_navigator">
            <div />
            <div className="toggle-btn">
              <SideMenu click={props.drawerToggleClickHandler} />
            </div>
            <HeaderLogo logo={project?.headerLogo} permissions={permissions} defaultLogo={Logo} setEdit={setEdit} />
            <div className="toolbar_navigation-items m-auto mb-1">
              <ul>
                {map(topLevelNav, (route) => {
                  const prefix = route.path.split("/")[1];
                  const isActive = topLevelRoute === prefix;
                  const linkStyles = getLinkStyles(isActive);
                  if (intersection(permissions, route.requiredRoles).length) {
                    let path = route.path;
                    if (path === insightsActionsRoute.path) {
                      if (!permissions.includes("admin")) {
                        // right now, actions are not being shown to users w/team_observer role
                        path = insightsUsersRoute.path;
                      }
                    }

                    return (
                      <KatilystLink to={path} key={uuid()}>
                        <li style={linkStyles}>
                          {route.text}
                          {isActive && (
                            <img style={{ height: "30px", marginLeft: ".5rem" }} alt="dashboard" src={route.icon} />
                          )}
                        </li>
                      </KatilystLink>
                    );
                  }
                })}

                <li className="dropdown mx-3" key={uuid()}>
                  <Dropdown isOpen={showUserMenu} toggle={toggleUserMenu} data-testid="Menu">
                    <DropdownToggle
                      tag="div"
                      onClick={toggleUserMenu}
                      data-toggle="dropdown"
                      aria-expanded={showUserMenu}
                    >
                      <img
                        src={userImage || DefaultAvatar}
                        style={{
                          borderRadius: "50%",
                          border: "2px solid #5da943",
                          objectFit: "cover",
                          height: 40,
                          width: 40
                        }}
                        alt="user_icon"
                      />
                    </DropdownToggle>
                    <DropdownMenu right className="px-3">
                      {allowUserRegister && !ssoEnabled && (
                        <DropdownItem key={uuid()} onClick={togglePassphraseModal}>
                          Change Passphrase
                        </DropdownItem>
                      )}
                      <DropdownItem key={uuid()} onClick={handleLogout}>
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <div className="subHeader">
          <nav className="py-2">
            <div className="toolbar_navigation-items subMenuBar">
              <ul>
                <>
                  <li key={uuid()}>
                    <AccountSelector />
                  </li>
                  <li key={uuid()}>
                    <ProjectSelector />
                  </li>
                </>
                {map(subMenuLinks, (link) => {
                  const authorized = getIsAuthorized(link.requiredRoles, permissions);
                  if (!authorized) {
                    return null;
                  }
                  const isCurrentRoute = location.pathname === link.path;
                  const linkStyle = isCurrentRoute
                    ? {
                        color: "#0d73d3",
                        transform: "scale(1.2)",
                        fontSize: "0.9rem"
                      }
                    : {
                        color: "black",
                        fontSize: "0.9rem"
                      };

                  return (
                    <KatilystLink to={link.path} key={uuid()}>
                      <li className="me-1">
                        <span style={linkStyle}>{link.text}</span>
                        {isCurrentRoute && (
                          <img
                            style={{
                              height: "30px",
                              paddingLeft: "1rem",
                              ...link.iconStyle
                            }}
                            alt={`${link.text}-icon`}
                            src={link.icon}
                          />
                        )}
                      </li>
                    </KatilystLink>
                  );
                })}
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div className="container-fluid p-0 show-mobile">
        <header>
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <KatilystLink className="navbar-brand" to={config.HOME_ROUTE}>
                <img src={userAccountLogo} width={150} alt="header-logo" />
              </KatilystLink>

              <img
                src={userImage ?? DefaultAvatar}
                style={{
                  borderRadius: "50%",
                  border: "2px solid #5da943",
                  objectFit: "cover",
                  height: 40,
                  width: 40
                }}
                alt="user-icon"
              />
            </nav>
          </div>
        </header>
        <div className="second-header d-flex">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <svg viewBox="0 0 100 80" width="40" height="40">
              <rect width="100" height="2"></rect>
              <rect y="30" width="100" height="2"></rect>
              <rect y="60" width="100" height="2"></rect>
            </svg>
          </button>

          <FormGroup>
            <span className="mb-2">
              <AccountSelector />
            </span>

            <ProjectSelector />
          </FormGroup>
        </div>
      </div>
      <div className="container-fluid navbar-collapse d-lg-none d-xl-none d-md-none collapse" id="navbar">
        <div className="analytika">
          <ul className="mainMenuMobile">
            <li key={uuid()}>
              {map(topLevelNav, (route) => {
                const prefix = route.path.split("/")[1];
                const isActive = topLevelRoute === prefix;
                if (intersection(permissions, route.requiredRoles).length) {
                  return (
                    <span key={uuid()}>
                      <KatilystLink style={{ color: isActive ? "#0d73d3" : "#000" }} to={route.path}>
                        <span>{route.text}</span>
                      </KatilystLink>
                      <ul className="mt-0 ps-5">
                        {map(route.subRoutes, (link) => {
                          const isCurrentRoute = location.pathname === link.path;
                          const linkStyle = isCurrentRoute
                            ? {
                                color: "#0d73d3",
                                textShadow: "0px 4px 4px #0000003b"
                              }
                            : { color: "black", textShadow: "none" };

                          return (
                            <KatilystLink to={link.path} key={uuid()} className="m-0 p-0">
                              <span style={{ ...linkStyle, display: "block" }} className="m-0 p-0">
                                {link.text}
                              </span>
                            </KatilystLink>
                          );
                        })}
                      </ul>
                    </span>
                  );
                }
              })}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default React.memo(Header);
