import styled from "styled-components";

export const Field = styled("div")`
  width: ${(props) => props.width || "7rem"};
  max-width: ${(props) => props.maxWidth || "13rem"};
  position: relative;
  input,
  #radio-target,
  select {
    border: 1px solid #c6cacc;
    height: ${(props) => props.height || "3.125rem"};
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    &:focus {
      box-shadow: unset;
      border-color: unset;
      color: #000;
    }
  }
  #radio-target {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
    position: relative;
    #value {
      display: inline-block;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
    #icon {
      position: absolute;
      right: 0.6rem;
      top: 0.4rem;
      i {
        color: #78909c;
      }
    }
  }

  input[type="date"] {
    padding-left: 0.4rem;
  }
  input[type="radio"] {
    visibility: collapse !important;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    width: 1rem;
    margin-left: 0.25rem;
    padding-right: 0.5rem;
  }
  .calendar {
    position: absolute;
    top: 12px;
    right: 5px;
  }
  .label {
    position: absolute;
    top: -0.55rem;
    left: 0.3rem;
    font-size: 10px !important;
    background-color: #fff;
    padding: 0 0.1rem;
    color: #78909c;
  }
`;
