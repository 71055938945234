import { useCallback, memo } from "react";
import HighlightWithinTextarea from "react-highlight-within-textarea";

import { highlight } from "./helper";
import { Label } from "reactstrap";
import { StyledHighlightTextArea } from "theme/StyledComponents";

const MultipleEmailInput = ({ emailsInUse = [], form, account, checkUserExists = false }) => {
  const { emailInput, setValue } = form;
  const handleEmailInputChange = useCallback(
    (value) => {
      setValue("emailInput", value);

      setTimeout(() => {
        // need one tick to be up to date
        setValue("emailInputErrors", document.getElementsByClassName("red").length);
        setValue("emailDomainErrors", document.getElementsByClassName("emailDomain").length);
      });
    },
    [setValue]
  );

  return (
    <StyledHighlightTextArea data-testid={checkUserExists ? "BULK_LOG_MULTIPLE_EMAIL_INPUT" : "MULTIPLE_EMAIL_INPUT"}>
      <Label className="label">EMAIL ADDRESSES</Label>
      <HighlightWithinTextarea
        data-testid="HIGHLIGHT_WITHIN_TEXT_AREA"
        value={emailInput}
        onChange={handleEmailInputChange}
        highlight={highlight(emailsInUse, account?.emailDomain, checkUserExists)}
        placeholder=""
      />
    </StyledHighlightTextArea>
  );
};

export default memo(MultipleEmailInput);
