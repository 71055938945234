import { useEffect } from "react";

import { useQueryParams } from "./useQueryParams";
import { useUpdateQueryParams } from "./useUpdateQueryParams";
import { usePreviousValue } from "hooks/api.hooks";

export const useHandleListChange = (queryParamName, watchedVariable) => {
  const queryParams = useQueryParams();
  const updateQueryParams = useUpdateQueryParams();
  const previousValue = usePreviousValue(watchedVariable);

  useEffect(() => {
    if (watchedVariable !== previousValue && !!previousValue) {
      queryParams.set(queryParamName, watchedVariable);
      updateQueryParams(queryParams);
    }
  }, [watchedVariable, previousValue, queryParamName, queryParams, updateQueryParams]);
};
