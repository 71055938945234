import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { useHandleListChange, useQueryParams } from "hooks/application";
import { selectAccountList } from "features/userData/userDataSlice";
import CustomSelect from "./CustomSelect";

const AccountSelector = () => {
  const accounts = useSelector(selectAccountList);
  const queryParams = useQueryParams();
  const accountIdFromParams = queryParams.get("accountId");

  const { register, watch, control } = useForm({
    defaultValues: { accountId: accountIdFromParams }
  });
  const accountId = watch("accountId");

  const tmpAccounts = [...accounts];

  const sortedAccounts = tmpAccounts.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });

  useHandleListChange("accountId", accountId);

  if (sortedAccounts && sortedAccounts.length > 1) {
    return (
      <CustomSelect
        formRegister={register}
        name="accountId"
        options={sortedAccounts}
        label="ACCOUNT"
        width="12rem"
        displayKey="name"
        valueKey="id"
        height="2.3rem"
        defaultValue={sortedAccounts.find((acct) => acct.id === accountId)}
        control={control}
      />
    );
  } else {
    return null;
  }
};

export default memo(AccountSelector);
