import { gql } from "@apollo/client";

export const CHECK_CONFIG_NAME_UNIQUE = gql`
  mutation checkConfigNameUnique($name: String!, $projectId: String!) {
    checkConfigNameUnique(name: $name, projectId: $projectId) {
      success
      message
    }
  }
`;

export const COMPARE_ACTION_UPLOAD_CONFIGS = gql`
  mutation compareActionUploadConfigs($configId: String!, $updatedConfig: CSVUploadSubmitInput!, $projectId: String!) {
    compareUploadActionMappingConfigs(configId: $configId, updatedConfig: $updatedConfig, projectId: $projectId) {
      success
      message
      changedFields
    }
  }
`;

export const CREATE_ACTION_MAPPING_CONFIG = gql`
  mutation createActionMappingConfig($config: UpdateActionUploadMappingConfig!, $projectId: String!) {
    createActionMappingConfig(config: $config, projectId: $projectId) {
      success
      message
      data
    }
  }
`;

export const DELETE_UPLOAD_ACTION_MAPPING_CONFIG = gql`
  mutation deleteActionMappingConfig($configId: String!, $projectId: String!) {
    deleteActionMappingConfig(configId: $configId, projectId: $projectId) {
      success
      message
    }
  }
`;

export const GET_CUSTOM_ACTION_UPLOAD_MAPPINGS = gql`
  mutation getCustomActionUploadMapsByConfigId($configId: String!, $projectId: String!) {
    getCustomActionUploadMapsByConfigId(configId: $configId, projectId: $projectId) {
      message
      success
      customActionUploadMappings {
        id
        customActionId
        fileAction
      }
    }
  }
`;

export const GET_HEADER_ROW = gql`
  mutation getHeaderRow($data: FileUploadInput!, $projectId: String!) {
    getHeaderRow(data: $data, projectId: $projectId) {
      data
      success
      message
    }
  }
`;

export const GET_UPLOAD_CONFIGS = gql`
  query getUploadConfigs($projectId: String!) {
    getActionMappingConfigsByProjectId(projectId: $projectId) {
      configs {
        id
        actionDateMappedColumn
        actionMappedColumn
        emailMappedColumn
        configName
        addNewUsers
        role
        sendRuleTriggerNotifications
        customActionAttributeId
        customActionModifierId
        customActionName
        customActionOperatorId
        customActionValue
      }
      success
      message
    }
  }
`;

export const GET_PREDEFINED_ACTION_MAPS = gql`
  mutation getPredefinedActionsForSelectedActionColumn($column: String!, $data: FileUploadInput!, $projectId: String!) {
    getPredefinedActionsForSelectedActionColumn(column: $column, data: $data, projectId: $projectId) {
      predefinedActionMaps {
        name
        rowFirstFound
      }
      success
      message
    }
  }
`;

export const SUBMIT_CSV_UPLOAD_ACTION = gql`
  mutation submitUploadAction(
    $projectId: String!
    $configId: String!
    $config: CSVUploadSubmitInput!
    $data: FileUploadInput!
  ) {
    submitUploadAction(configId: $configId, projectId: $projectId, config: $config, data: $data) {
      success
      message
    }
  }
`;

export const UPDATE_UPLOAD_ACTION_MAPPING_CONFIG = gql`
  mutation updateActionMappingConfig(
    $configId: String!
    $config: UpdateActionUploadMappingConfig!
    $projectId: String!
  ) {
    updateUploadActionMappingConfig(configId: $configId, config: $config, projectId: $projectId) {
      success
      message
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($data: FileUploadInput!, $projectId: String!) {
    uploadFile(data: $data, projectId: $projectId) {
      data
      configId
      configName
      logId
      success
      message
    }
  }
`;

export const VALIDATE_ACTION_COLUMN = gql`
  mutation validateActionColumn(
    $column: String!
    $data: FileUploadInput!
    $projectId: String!
    $logId: String!
    $checkWholeNumbers: Boolean!
  ) {
    validateActionColumn(
      column: $column
      data: $data
      projectId: $projectId
      logId: $logId
      checkWholeNumbers: $checkWholeNumbers
    ) {
      success
      message
      displayValue
    }
  }
`;

export const VALIDATE_DATE_COLUMN = gql`
  mutation formatSelectedColumnDate($column: String!, $data: FileUploadInput!, $projectId: String!, $logId: String!) {
    validateDateColumnFormat(column: $column, data: $data, projectId: $projectId, logId: $logId) {
      format
      success
      message
      displayValue
    }
  }
`;

export const VALIDATE_EMAIL_COLUMN = gql`
  mutation validateEmailColumn($column: String!, $data: FileUploadInput!, $projectId: String!, $logId: String!) {
    validateEmailColumn(column: $column, data: $data, projectId: $projectId, logId: $logId) {
      success
      message
      displayValue
    }
  }
`;
