import styled from "styled-components";

export const MagicLinkButton = styled.button.attrs({ className: "btn rounded-pill btn-outline-success border-1 ms-2" })`
  outline: #6fc45c !important;
  color: #6fc45c !important;
  border-color: #6fc45c !important;
  font-size: 0.65rem;
  &:hover {
    color: white !important;
    background: #6fc45c !important;
  }
`;
