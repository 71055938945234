import { setSelectedUser } from "features/userData/userDataSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// sets selectedUser to null on mount & unumount
// re: we always want to reset to the first user in the users list
// when user list component mounts
export const useResetSelectedUser = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSelectedUser(null));
    return () => {
      dispatch(setSelectedUser(null));
    };
  }, []); // eslint-disable-line
};
