import { createSlice } from "@reduxjs/toolkit";

export const RulesSlice = createSlice({
  name: "rulesData",
  initialState: {},
  reducers: {
    saveRule: (state, action) => {
      state.rulesList = action.payload;
    },
  },
});

export const { saveRule } = RulesSlice.actions;

export default RulesSlice.reducer;
