import { roleReceivesEmail } from "../utils/roles.utils";

const messages = {
  ADD_USERS_MODAL_CONFIRM: (selectedRole) =>
    `This will add the selected and entered users to the project${roleReceivesEmail(selectedRole) ? " and send an email invite." : "."}`,
  ADD_USERS_MODAL_WARNING:
    "You have made user selections. Are you sure you want to close this without adding them to the project?",
  ATTRIBUTE_DELETE_CONFIRM:
    "Are you sure you want to delete this attribute? It will remove this attribute from all users and rules!",
  ATTRIBUTE_DELETE_ERROR: "There was an issue deleting that attribute from the database",
  ATTRIBUTE_DELETE_SUCCESS: "Attribute deleted.",
  ATTRIBUTE_SAVE_ERROR: "Error saving attribute, please try again",
  ATTRIBUTE_SAVE_SUCCESS: "Attribute saved.",
  COLUMN_SELECTION_REQUIRED: "( * column selection required )",
  CONFIRM_DELETE_PROJECT:
    "Deleting this project will remove all attributes, rules, and actions associated with it. Are you sure you want to proceed?",
  CONFIRM_REMOVE_USER_FROM_ACCOUNT:
    "This action will remove this user from the this account. Are you sure you want to proceed?",
  CONFIRM_REMOVE_USER_FROM_PROJECT: "Are you sure you want to remove this user?",
  CSV_CHANGED_FIELDS_WARNING_MODAL: (x) => `You have ${x ?? "some"} unsaved changes to the selected config. 
Do you want to save a new configuration and log the chosen actions for uploaded users?`,
  CSV_CONFIRMATION_MODAL: "Are you ready to log the chosen actions for the uploaded users?",
  CSV_WARNING_MODAL:
    "You have made CSV Upload Action selections. Are you sure you want to close this without logging the action for the users and saving the config?",
  CUSTOM_ACTION_DELETE_SUCCESS: "Custom action deleted.",
  CUSTOM_ACTION_DELETE_ERROR: "Unable to delete custom action. Action in use on CSV Upload configuration.",
  CUSTOM_ACTION_SAVE_ERROR: "There was an error saving that action.",
  CUSTOM_ACTION_SAVE_SUCCESS: "Custom action saved.",
  EXPIRED_ACTION_WARNING:
    "This action's date is older than the expiration date and therefore will not have any effect. Are you sure you want to submit it this way?",
  SLACK_ACTION_DELETE_SUCCESS: "Slack action deleted.",
  SLACK_ACTION_SAVE_ERROR: "There was an error saving that action.",
  SLACK_ACTION_SAVE_SUCCESS: "Slack action saved.",
  RULE_DELETE_CONFIRM: "Are you sure you want to delete this rule?",
  RULE_DELETE_ERROR: "There was an issue deleting that rule from the database",
  RULE_DELETE_SUCCESS: "Rule deleted.",
  RULE_SAVE_ERROR: "Error saving rule, please try again",
  RULE_SAVE_SUCCESS: "Rule saved.",
  UNSAVED_CHANGES: "You have unsaved changes, do you still want to proceed?",
  USER_CARD_UNSAVED_CHANGES: "Are you sure you want to close this edit without saving?"
};

export default messages;
