import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { Container, Card } from "reactstrap";
import styled from "styled-components";

const GLOBAL_STYLE = {
  fontFamily: "'Quicksand', sans-serif",
  darkGreen: "#4D793D",
  lightGreen: "#C5F0D0",
  primaryGreen: "#6FC45C"
};

export const StyledButton = styled("button")`
  text-align: center;
  background: #0557a2;
  border-radius: 6px;
  color: #fff;
  box-shadow: none;
  border: none;
  ${({ externalCss }) => externalCss}
`;

export const StyledCircleButton = styled("button")`
  text-align: center;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background: ${GLOBAL_STYLE.primaryGreen};
  color: #fff;
  box-shadow: none;
  border: none;
  ${({ externalCss }) => externalCss}
`;

export const StyledAddButton = styled(StyledButton)`
  margin: 0 auto;
  padding: 15px;
  max-width: 200px;
  font-size: 25px;
  text-decoration: none;
  background: ${GLOBAL_STYLE.primaryGreen};
  border-radius: 40px;

  &:hover,
  &:active {
    color: #fff;
  }
`;

const customScrollBarStyling = `


  /* Track */
  ::-webkit-scrollbar {
    width: 1.25rem;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #e3f8e9;
  }

  /* Thumb */
  ::-webkit-scrollbar-thumb {
    background: ${GLOBAL_STYLE.primaryGreen};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${GLOBAL_STYLE.primaryGreen};
  }

  ::-webkit-scrollbar-thumb:active {
    background: ${GLOBAL_STYLE.primaryGreen};
  }

  /* Buttons */
  ::-webkit-scrollbar-button:single-button {
    background-color: #c5c5c5;
    display: block;
    height: 30px;
    width: 30px;
    padding: 10px 0;
  }
  /* Up */
  ::-webkit-scrollbar-button:single-button:vertical:increment {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${GLOBAL_STYLE.primaryGreen};
  }

  ::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${GLOBAL_STYLE.primaryGreen};
  }
  /* Down */
  ::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${GLOBAL_STYLE.primaryGreen};
  }

  ::-webkit-scrollbar-button:vertical:single-button:decrement:hover {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid ${GLOBAL_STYLE.primaryGreen};
  }

  @media (max-width: 767px) {
    ::-webkit-scrollbar {
      width: 1rem;
      height: 4px;
    }

  }

  scrollbar-color: ${GLOBAL_STYLE.primaryGreen} #E3F8E9;
  scrollbar-width: auto;
`;

const hideScrollBar = `
  /* Track */
  ::-webkit-scrollbar {
    visibility: hidden;
  }

  ::-webkit-scrollbar-track {
    visibility: hidden;
  }

  /* Thumb */
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  ::-webkit-scrollbar-thumb:hover {
    visibility: hidden;
  }

  ::-webkit-scrollbar-thumb:active {
    visibility: hidden;
  }



  @media (max-width: 767px) {
    ::-webkit-scrollbar {
      visibility: hidden;
    }

  }
  scrollbar-width: none;
  scrollbar-color: transparent;
`;

export const StyledCardShadow = styled.div`
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 2px rgb(0 0 0 / 8%);
  padding: 1rem;
  overflow-y: auto;
  list-style-type: none;
  height: 100%;

  ${customScrollBarStyling};
`;

export const ActionFormButton = styled("button")`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: white;
  &:disabled {
    background: #e8e6df;
    i {
      color: grey;
    }
    &.shadow {
      box-shadow: none !important;
    }
  }
`;

export const StyledPillButton = styled(StyledButton).attrs({
  className: "py-2 px-3 d-flex align-items-center justify-content-center"
})`
  max-height: 3rem;
  max-width: max-content;
  text-decoration: none;
  border-radius: 40px;
  background: #6fc45c;

  &:hover,
  &:active {
    color: #fff;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const FormErrorText = styled.p`
  color: red;
  font-size: 0.85rem;
  line-height: 1rem;
  min-height: 1rem;
`;

export const StyledHighlightTextArea = styled(StyledCardShadow)`
  position: relative;
  color: #669058;
  background: #e4f8e9;
  height: 100%;

  .label {
    position: absolute;
    top: 0.5rem;
    left: 1rem;
    font-size: 12px;
  }

  .DraftEditor-editorContainer {
    background: #e4f8e9;
    line-height: 1.85rem;
    text-align: left;

    .public-DraftEditor-content {
      height: 100%;
      padding: 2rem 0.5rem;
    }

    mark.blue {
      background-color: #a3daff;
    }

    mark.red {
      background-color: #edc3bc;
    }

    mark.emailDomain {
      background-color: #edc3bc;
    }

    mark.yellow {
      background-color: gold;
    }
  }
`;

export const TruncateText = styled("div")`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
`;

export const StyledDataGrid = styled(DataGrid).attrs({
  disableSelectionOnClick: true,
  className: "k-shadow"
})`
  padding: 0rem 0.75rem;
  margin-bottom: 1.5rem;
  min-height: 30rem !important;
  
  .MuiDataGrid-row {
    max-height: fit-content !important;
    height: fit-content !important;
    align-items: baseline;
  }
  
  .MuiDataGrid-iconSeparator {
    display: none !important;
  }

  .MuiDataGrid-cellContent, .MuiDataGrid-cell, .MuiDataGrid-cell--withRenderer{
    overflow: visible !important;
    white-space: normal;
    max-height: fit-content !important;
    height: fit-content !important;
    max-width: 15rem;
    width: 100%;
    word-break: break-all;
    
    & > * {
      word-break: break-all;
      overflow: visible !important;
      white-space: normal;
      width: 100%;
    }
  }
 
  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeaders {
    border: none !important;
    max-height: 1rem !important;
    
    &:focus {
      outline: none !important;
    }
  
  .MuiDataGrid-filterIcon {
    fill: ${GLOBAL_STYLE.primaryGreen};
  }

  .MuiDataGrid-detailPanel {
    padding: 1rem 2rem !important;
    max-height: 5rem !important;
    height: 5rem !important;
    background: yellow !important;
  }


  .MuiDataGrid-row--detailPanelExpanded {
    max-height: 5rem;
    margin-bottom: 1rem !important;
  }
`;

export const ResponsiveVerticalContainer = styled.div`
  overflow-y: auto;
  @media (min-width: 768px) {
    display: flex;
    padding: 0.75rem;
    flex-direction: column;
    max-height: calc(100vh - 175px - 1rem);
    height: calc(100vh - 175px - 1rem);
  }
  > * {
    flex: 1 1 auto;
  }
  @media (max-width: 767px) {
    height: 100%;
  }

  ${hideScrollBar}
`;

export const ContentContainer = styled(Container)`
  > * {
    flex: 1 1;
  }
  padding: 0.5rem 0.5rem 2rem;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 175px);
    height: calc(100vh - 175px);
    overflow: hidden;
  }

  @media (max-width: 767px) {
    height: 100%;
  }

  @media (max-width: 1792px) {
    max-width: calc(100% - 1.5rem);
  }
  @media (min-width: 1793px) {
    min-width: 1400px !important;
  }
`;

export const ScrollBarContainer = styled.span`
  ${customScrollBarStyling}
`;

export const AuthenticationCard = styled(Card).attrs({
  className: "shadow p-5"
})`
  width: 31.25rem;
`;

export const AuthenticationContainer = styled(Container).attrs({
  background: "white",
  className: "d-flex justify-content-center align-items-center h-100"
})``;

export const AuthFlowLink = styled(Link)`
  color: #c4c4c4;
  span {
    color: #141464;
  }
`;

export const TextSection = styled("div").attrs({
  className: "col-md-6"
})`
  text-align: left;
  margin: auto 1rem;
`;
