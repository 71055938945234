import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { map } from "lodash";

import KatilystLogo from "../../assets/header-logo.png";
import { selectAccountData, selectProjectList } from "features/userData/userDataSlice";
import { useQueryParams } from "hooks/application";

const NotFoundWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  padding: 9rem 5rem;
`;

const NotFoundImage = styled.img`
  max-width: 35%;
  height: auto;
`;

const NotFoundText = styled.h1`
  color: #333;
  font-size: 32px;
`;

const NotFoundSubText = styled.p`
  color: #666;
  font-size: 24px;
`;

const AdminList = styled.ul.attrs({ className: "mt-3 mb-5" })`
  list-style-type: none;
  text-align: left;
  color: #666;

  a {
    text-decoration: none;
  }
`;

const ProjectNotFound = () => {
  const history = useHistory();
  const currentAccount = useSelector(selectAccountData);
  const projectList = useSelector(selectProjectList);
  const queryParams = useQueryParams();

  const noProjects = projectList === undefined || (Array.isArray(projectList) && projectList.length === 0);

  useEffect(() => {
    if (!noProjects) {
      history.push({ pathname: "/dashboard/attributes", search: queryParams.toString(), replace: true });
    }
  }, [noProjects]); // eslint-disable-line

  return (
    <NotFoundWrapper>
      <NotFoundText>No projects found</NotFoundText>
      <NotFoundSubText>
        We cannot find any projects that you are currently connected to within the selected account. Please contact an
        account admin to be added to a project!
      </NotFoundSubText>
      <AdminList>
        {map(currentAccount?.admins, (a) => (
          <li key={a.email}>
            <a href={`mailto:${a.email}`}>{a.firstName ? `${a.firstName} ${a?.lastName}` : a.email}</a> |{" "}
            <span>{a.email}</span>
          </li>
        ))}
      </AdminList>
      <NotFoundImage src={KatilystLogo} alt="404" />
    </NotFoundWrapper>
  );
};

export default ProjectNotFound;
