import React from "react";

// * ColumnSelectionValidation: displays column validation success and warning messages
const ColumnSelectionValidation = ({ success = false, message = "", testId, displayValue, warning = false }) => {
  return (
    <div className="d-flex m-auto mt-2">
      <div data-testid={`COLUMN_VALIDATION-${testId}`}>
        {!success && !!message && !warning && (
          <div
            id="warning"
            style={{
              fontSize: "12px",
              color: "red",
              margin: "auto",
              width: "100%",
              wordWrap: "initial"
            }}
          >
            {message?.split("\n").map((str, index) => (
              <p key={index}>{str}</p>
            ))}
          </div>
        )}
        {success && !!message && !warning && (
          <div id="success" style={{ fontSize: "14px", color: "green", margin: "auto" }}>
            {message?.split("\n").map((msg, index) => (
              <div key={index} className="w-100">
                {index === 0 ? (
                  <>
                    {displayValue && <i className="fa fa-check"></i>}
                    {" " + msg}
                  </>
                ) : (
                  msg
                )}
              </div>
            ))}
          </div>
        )}
        {warning && (
          <div id="success" style={{ fontSize: "14px", color: "orange", margin: "auto" }}>
            {message?.split("\n").map((msg, index) => (
              <div key={index} className="w-100">
                {index === 0 ? <>{" " + msg}</> : msg}
              </div>
            ))}
          </div>
        )}
      </div>
      <div>
        <div
          className="m-auto ms-5"
          style={success ? { color: "green", fontSize: "13px" } : { color: "red", fontSize: "13px" }}
        >
          {displayValue}
        </div>
      </div>
    </div>
  );
};

export default ColumnSelectionValidation;
