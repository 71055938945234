import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, Col, Modal, ModalBody, Row, UncontrolledTooltip } from "reactstrap";
import { useSelector } from "react-redux";
import { ADD_USERS_TO_PROJECT, REMOVE_USER_FROM_ACCOUNT } from "../api/users";
import messages from "../api/messages";

// * Components
import { ActionFormButton, StyledCardShadow } from "theme/StyledComponents";
import ConfirmationModal from "./ConfirmationModal";
import CustomCheckbox from "./CustomCheckbox";
import DeleteRow from "./DeleteRow/DeleteRow";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import MultipleEmailInput from "./MultipleEmailInput/MultipleEmailInput";
import RoleSelectionForNewUsers from "./Forms/RoleSelectionForNewUsers";
import { UncontrolledInput } from "components/CustomInput/CustomInput";

// * Helpers/Hooks/Utils
import { EMAIL_STRING_SPLIT } from "./MultipleEmailInput/helper";
import { filter, map, reduce, some, toLower } from "lodash";
import { meetsUsersFilter } from "../utils/users.utils";
import { selectAccountData } from "features/userData/userDataSlice";
import { useMutation } from "@apollo/client";
import { useSubmit } from "hooks/form.hooks";

const AddUsersModal = ({ toggle, users = [], emailsInUse = [], projectId, queries }) => {
  const [addUsersToProject] = useMutation(ADD_USERS_TO_PROJECT);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const toggleConfirm = () => setShowConfirm(!showConfirm);
  const [showWarning, setShowWarning] = React.useState(false);
  const toggleWarningClose = () => setShowWarning(!showWarning);
  const currentAccount = useSelector(selectAccountData);

  const { reset, register, watch, getValues, setValue } = useForm({
    defaultValues: {
      searchTerm: "",
      users: map(users, (user) => ({ ...user, selected: false })),
      emailInput: "",
      emailInputErrors: 0,
      emailDomainErrors: 0,
      role: "user"
    }
  });
  const searchTerm = watch("searchTerm");
  const _users = watch("users");
  const emailInput = watch("emailInput");
  const emailErrors = watch("emailInputErrors");
  const emailDomainErrors = watch("emailDomainErrors");
  const selectedRole = watch("role");

  const handleReset = () => {
    reset();
  };

  const extractData = () => {
    const userIds = reduce(
      _users,
      (acc, curr) => {
        if (curr.selected) {
          acc.push(curr.id);
        }
        return acc;
      },
      []
    );

    const emails = map(
      filter(emailInput.split(EMAIL_STRING_SPLIT), (e) => !!e),
      toLower
    );

    return { userIds, emails };
  };

  const handleSubmit = useSubmit({
    mutation: addUsersToProject,
    variables: { ...extractData(), projectId: projectId, role: selectedRole },
    useVariables: true,
    dataPath: ADD_USERS_TO_PROJECT.definitions[0].name.value,
    onSuccess: async () => {
      await queries.refetchOrgUsers();
      await queries.refetchProjectUsers();
      toggle();
    }
  });

  const submittable =
    (some(_users, (u) => !!u.selected) && emailErrors === 0) || (emailInput.trim().length > 0 && emailErrors === 0);

  // const submittable = formState.isDirty && !emailErrors;
  const handleWarningClose = () => {
    toggleWarningClose();
    toggle();
  };

  const handleRemoveFromAccountSuccess = useCallback(
    (email) => {
      queries.refetchOrgUsers();
      const updatedUsers = filter(_users, (u) => u.email.toLowerCase() !== email.toLowerCase());
      setValue("users", updatedUsers);
    },
    [setValue, queries, _users]
  );

  const setRoleValue = useCallback(
    (role) => {
      setValue("role", role);
    },
    [setValue]
  );

  const closeModalWarningText = messages.ADD_USERS_MODAL_WARNING;
  const confirmationModalText = messages.ADD_USERS_MODAL_CONFIRM(selectedRole);

  return (
    <>
      {showConfirm && (
        <ConfirmationModal
          type="confirm"
          toggle={toggleConfirm}
          handleSubmit={handleSubmit}
          bodyText={confirmationModalText}
        />
      )}
      {showWarning && (
        <ConfirmationModal
          type="warning"
          toggle={toggleWarningClose}
          handleSubmit={handleWarningClose}
          bodyText={closeModalWarningText}
        />
      )}
      <Modal isOpen toggle={toggle} size="xl">
        <ModalBody className="text-center px-0 pb-3">
          <div
            className="px-5"
            style={{
              position: "relative",
              paddingTop: "1.5rem",
              paddingBottom: "1.5rem",
              borderBottom: "2px solid #d2d2d2"
            }}
          >
            <h2 style={{ display: "inline" }}>Add Users</h2>
            <div
              className="d-inline-flex justify-content-around"
              style={{
                width: "20%",
                position: "absolute",
                top: "1.5rem",
                right: 0
              }}
            >
              <UncontrolledTooltip hideArrow placement="top" target="submit">
                Add users to project
              </UncontrolledTooltip>
              <UncontrolledTooltip hideArrow placement="top" target="reset">
                Clear selections
              </UncontrolledTooltip>
              <UncontrolledTooltip hideArrow placement="top" target="cancel">
                Cancel
              </UncontrolledTooltip>
              <ActionFormButton
                id="submit"
                type="button"
                onClick={toggleConfirm}
                data-testid="add-users"
                className="btn btn-outline-success shadow border-0"
                disabled={!submittable}
              >
                <i className="fa fa-check" />
              </ActionFormButton>

              <ActionFormButton
                type="button"
                className="btn shadow border-0 btn-outline-primary"
                buttonType="undo"
                id="reset"
                onClick={handleReset}
                disabled={!submittable}
              >
                <i className="fa fa-undo" />
              </ActionFormButton>

              <ActionFormButton
                id="cancel"
                onClick={() => (submittable ? toggleWarningClose() : toggle())}
                className="btn shadow border-0 btn-outline-danger"
                data-testid="CLOSE_PARTICIPANT_MODAL"
              >
                <i className="fa fa-times" />
              </ActionFormButton>
            </div>
          </div>
          <div
            className="px-5 d-flex flex-row justify-content-center"
            style={{
              position: "relative",
              paddingTop: "1.5rem",
              paddingBottom: "1.5rem",
              borderBottom: "2px solid #d2d2d2"
            }}
          >
            <RoleSelectionForNewUsers setRole={setRoleValue} />
          </div>
          <div className="d-flex justify-items-between">
            <div
              className="w-50 pt-4"
              style={{
                marginRight: "1rem",
                paddingLeft: "2rem"
              }}
            >
              <div className="d-flex justify-content-around align-items-center mb-2">
                <div>
                  <h5>Select From Account</h5>
                </div>
                <div style={{ width: "45%" }}>
                  <UncontrolledInput
                    name="searchTerm"
                    labelText="FILTER BY KEYWORD"
                    value={searchTerm}
                    formRegister={register}
                  />
                </div>
              </div>
              <StyledCardShadow className="py-3" style={{ overflowY: "auto", height: "450px" }}>
                {map(_users, (participant, i) => {
                  if (meetsUsersFilter(participant, searchTerm))
                    return (
                      <Card key={participant.email} className="mb-2 shadow-sm">
                        <CardBody>
                          <Row>
                            <Col md={3} className="d-flex align-items-center justify-content-center">
                              <CustomCheckbox
                                checked={getValues(`users.${i}.selected`)}
                                name={`users.${i}.selected`}
                                formRegister={register}
                              />
                            </Col>
                            <Col className="text-start" md={9}>
                              <UserInfoCard
                                participant={participant}
                                accountId={currentAccount.id}
                                handleRemoveSuccess={() => handleRemoveFromAccountSuccess(participant.email)}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    );
                })}
              </StyledCardShadow>
            </div>
            <div
              className="w-50 pt-4"
              style={{
                paddingRight: "2rem",
                marginLeft: "1rem",
                paddingLeft: "2rem",
                borderLeft: "2px solid #d2d2d2"
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "fit-content"
                }}
              >
                <h5 className="mb-2 mx-auto" style={{ lineHeight: "50px" }}>
                  Invite New
                </h5>
                <span
                  style={{
                    position: "absolute",
                    bottom: -5,
                    left: 0,
                    display: "flex justify-content-start align-items-center"
                  }}
                >
                  {emailErrors > 0 && (
                    <span style={{ color: "red", fontSize: "0.75rem" }}>* Invalid email address</span>
                  )}
                  {emailDomainErrors > 0 && (
                    <span className="mx-2" style={{ color: "red", fontSize: "0.75rem" }}>
                      * Email domain must be {currentAccount?.emailDomain}
                    </span>
                  )}
                </span>
              </div>

              <div style={{ height: "450px" }}>
                <MultipleEmailInput
                  emailsInUse={emailsInUse}
                  form={{ setValue, emailInput }}
                  account={currentAccount}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

function UserInfoCard({ participant, accountId, handleRemoveSuccess }) {
  const [removeUserFromAccount] = useMutation(REMOVE_USER_FROM_ACCOUNT);

  const handleRemoveUserFromAccount = useSubmit({
    mutation: removeUserFromAccount,
    variables: { userId: participant.id, accountId },
    useVariables: true,
    dataPath: REMOVE_USER_FROM_ACCOUNT.definitions[0].name.value,
    onSuccess: handleRemoveSuccess
  });

  return (
    <>
      <FeatureFlag featureName={FEATURE_FLAGS.REMOVE_USER_FROM_ACCOUNT}>
        <span style={{ float: "right" }}>
          <DeleteRow
            confirmText={messages.CONFIRM_REMOVE_USER_FROM_ACCOUNT}
            handleDelete={handleRemoveUserFromAccount}
            shouldUseModal
            icon
          />
        </span>
      </FeatureFlag>
      <h4 className="mb-0">
        {participant.firstName} {participant.lastName}
      </h4>
      <p className="m-0">{participant.email}</p>
    </>
  );
}

export default React.memo(AddUsersModal);
