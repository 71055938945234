import React from "react";
import { formatDateForDisplay } from "../../utils/date.utils";

const RoleLastModified = ({ roleLastModified }) => {
  return (
    <>
      {roleLastModified && (
        <div className="d-flex flex-column" data-testid="ROLE_LAST_MODIFIED_DISPLAY">
          <div className="text-end small">Role Last Modified</div>
          <div className="text-end small">{formatDateForDisplay(roleLastModified)}</div>
        </div>
      )}
    </>
  );
};

export default RoleLastModified;
