import React from "react";
import FileArrowDownSolid from "../assets/file-arrow-down-solid.svg";
import { UncontrolledTooltip } from "reactstrap";

const FileExportButton = ({ loading = false, handleFileExport, tooltipText }) => {
  return (
    <div className="pe-3">
      {!loading ? (
        <>
          <UncontrolledTooltip hideArrow placement="top" target="csv-download">
            {tooltipText}
          </UncontrolledTooltip>
          <div style={{ cursor: "pointer" }} onClick={handleFileExport} id="csv-download">
            <img style={{ height: "35px" }} alt="download-table-data-file-icon" src={FileArrowDownSolid} />
          </div>
        </>
      ) : (
        <div className="spinner-grow text-success mx-auto my-auto" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  );
};

export default FileExportButton;
