import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import { ErrorBoundary } from "react-error-boundary";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { map, get } from "lodash";
import jwt from "jwt-decode";

import Layout from "hocs/Layout";
import ProtectedRoute from "hocs/ProtectedRoute/ProtectedRoute";
import config from "./config";
import { baseLogout } from "./app/rootReducer";
import ConditionIcon from "assets/condition_icon.png";
import UserSetupIcon from "assets/usersetup-icon.png";
import DashboardIcon from "assets/dashboard-icon.png";
import UsersIcon from "assets/users.png";
import ActionsIcon from "assets/actions-icon.png";
import ConfigureIcon from "assets/cogs-solid.png";
import InsightsIcon from "assets/insights-icon.png";
import IntegrationsIcon from "assets/integrations-icon.png";
import LogoutConfirmationModal from "components/logoutConfirmationModal";
import Insights from "features/Insights/Insights";
import Login from "containers/Login/Login";
import create from "containers/Create/Create";
import register from "containers/Register/Register";
import AttributesPage from "features/Attributes/Attributes";
import Actions from "features/Actions/Actions";
import Integrations from "features/Integrations/Integrations";
import { saveAuthToken, saveUserData } from "features/login/loginSlice";
import Rules from "features/Rules/Rules";
import MyActions from "features/UserDashboard/MyActions";
import MyAttributes from "features/UserDashboard/MyAttributes";
import Users from "features/Users/Users";
import { useClearAlert, useIsUserAuthorized } from "./hooks/application.hooks";
import { FEATURE_FLAGS } from "./hocs/FeatureFlag/utils";
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";
import RecoverPassphrase from "containers/ResetPassphrase/RecoverPassphrase";
import ResetPassphrase from "containers/ResetPassphrase/ResetPassphrase";
import CompleteRegistration from "./containers/Create/CompleteRegistration";
import { useCheckForRedirect, useQueryParams, useSetRedirectToPersistedState } from "hooks/application";
import ProjectNotFound from "components/NotFound/ProjectsNotFound";
import UsersInsightsView from "features/Insights/UserInsightsView";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="d-flex justify-content-center pt-5">
      <div role="alert">
        <p className="row">Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    </div>
  );
}

const dashboardPrefix = "/dashboard";
const configurePrefix = "/configure";
const insightsPrefix = "/insights";

export const topLevelRoutes = {
  dashboardPrefix,
  configurePrefix,
  insightsPrefix
};

export const tabLinks = [
  {
    path: `${configurePrefix}/attributes`,
    text: "Attributes",
    icon: UserSetupIcon,
    component: AttributesPage,
    requiredRoles: ["admin"]
  },
  {
    path: `${configurePrefix}/rules`,
    text: "Rules",
    icon: ConditionIcon,
    component: Rules,
    requiredRoles: ["admin"]
  },
  {
    path: `${configurePrefix}/users`,
    text: "Users",
    icon: UsersIcon,
    component: Users,
    requiredRoles: ["admin"]
  },
  {
    path: `${configurePrefix}/integrations`,
    text: "Integrations",
    icon: IntegrationsIcon,
    component: Integrations,
    requiredRoles: ["admin"],
    iconStyle: { marginLeft: "0.5rem" }
  },
  {
    path: `${configurePrefix}/actions`,
    text: "Actions",
    icon: ActionsIcon,
    component: Actions,
    requiredRoles: ["admin"]
  }
];

export const myActionsRoute = {
  path: `${dashboardPrefix}/actions`,
  text: "My Actions",
  icon: ActionsIcon,
  component: MyActions,
  requiredRoles: ["user"],
  iconStyle: { marginLeft: "0.5rem" }
};

export const insightsActionsRoute = {
  path: `${insightsPrefix}/actions`,
  text: "Actions",
  icon: ActionsIcon,
  component: Insights,
  requiredRoles: ["admin"]
};

export const insightsUsersRoute = {
  path: `${insightsPrefix}/users`,
  text: "Users",
  icon: UsersIcon,
  component: UsersInsightsView,
  requiredRoles: ["admin", "team_observer"]
};

export const myAttributesRoute = {
  path: config.HOME_ROUTE,
  text: "My Attributes",
  icon: UserSetupIcon,
  component: MyAttributes,
  requiredRoles: ["user"],
  iconStyle: { marginLeft: "0.5rem" }
};

export const createUserRoute = {
  path: "/create",
  component: create
};

export const passphraseRecoverRoute = {
  path: "/passphrase-recovery",
  component: RecoverPassphrase
};

export const passphraseResetRoute = {
  path: "/passphrase-reset",
  component: ResetPassphrase
};

export const loginRoute = {
  path: "/login",
  component: Login
};

export const registerUserRoute = {
  path: "/register",
  component: register
};

export const completeRegistration = {
  path: "/complete-registration",
  component: CompleteRegistration
};

export const projectNotFound = {
  path: "/dashboard/project-not-found",
  component: ProjectNotFound
};

export const topLevelNav = [
  {
    ...myAttributesRoute,
    icon: DashboardIcon,
    text: "My Dashboard",
    subRoutes: [myActionsRoute, myAttributesRoute]
  },
  {
    ...tabLinks[2],
    icon: ConfigureIcon,
    text: "Configure",
    subRoutes: tabLinks
  },
  {
    ...insightsActionsRoute,
    icon: InsightsIcon,
    text: "Insights",
    subRoutes: [insightsActionsRoute, insightsUsersRoute],
    requiredRoles: ["admin", "team_observer"]
  }
];

export function NotFound() {
  return <h1>404 Not Found</h1>;
}

const UnauthedRouter = () => {
  const { alert } = useSelector((state) => state.commonState);
  useClearAlert(alert?.message);
  useSetRedirectToPersistedState();

  /*
    The SAML callback redirects users back to the base url with a token query parameter
    This retrieves that, saves it, and then redirects you home again, which should recognized
    the token adn treat you as logged in.
  */
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const jwtToken = queryParams.get("token");
  if (jwtToken) {
    dispatch(saveAuthToken(jwtToken));
    dispatch(saveUserData(jwt(jwtToken)));
    history.push(config.HOME_ROUTE);
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => window.location.reload(true)}
      onError={(e) => console.log(e)}
    >
      <div
        className="position-absolute"
        style={{
          top: "2rem",
          right: "2.5rem",
          width: "15rem",
          maxHeight: "fit-content"
        }}
      >
        {alert?.message && (
          <Alert
            color={get(alert, "type", "success")}
            isOpen={true}
            fade={true}
            style={{ zIndex: 1600 }}
            className="text-center"
          >
            {typeof alert?.message === "string" ? alert?.message : "There was an error, please try again"}
          </Alert>
        )}
      </div>
      <Switch>
        <Route exact path={loginRoute.path} render={(props) => <Login {...props} />} />
        <Route exact path="/" render={(props) => <Login {...props} />} />
        <Route exact path={createUserRoute.path} component={createUserRoute.component} />
        <Route exact path={passphraseRecoverRoute.path} component={passphraseRecoverRoute.component} />
        <Route exact path={passphraseResetRoute.path} component={passphraseResetRoute.component} />
        <Route exact path={registerUserRoute.path} component={registerUserRoute.component} />
        <Route exact path={completeRegistration.path} component={completeRegistration.component} />

        <Redirect from="*" to={loginRoute.path} />
      </Switch>
    </ErrorBoundary>
  );
};

const AuthedRouter = () => {
  const dispatch = useDispatch();
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const allowInternalTeams = useFeatureFlag(FEATURE_FLAGS.TEAMS_INTERNAL);
  const queryParams = useQueryParams();
  useSetRedirectToPersistedState();
  useCheckForRedirect();

  const handleOnIdle = () => {
    const keepLoggedIn = localStorage.getItem("keepLoggedIn");
    // Logout user
    if (keepLoggedIn === "true") {
      setShowConfirmLogout(false);
      reset();
    } else {
      setShowConfirmLogout(true);
    }
  };

  const { reset } = useIdleTimer({
    timeout: config.MAX_SESSION_LENGTH,
    debounce: 500,
    onIdle: handleOnIdle
  });

  const handleLogout = (event) => {
    setShowConfirmLogout(false);
    if (!event) {
      localStorage.removeItem(config.KEEP_ME_LOGGED_IN_KEY);
      dispatch(baseLogout());
    } else {
      reset();
    }
  };

  return (
    <Layout>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload(true)}
        onError={(e) => console.log(e)}
      >
        <Switch>
          <ProtectedRoute
            exact
            path={myAttributesRoute.path}
            component={myAttributesRoute.component}
            requiredRoles={myAttributesRoute.requiredRoles}
          />
          <ProtectedRoute
            exact
            path={myActionsRoute.path}
            component={myActionsRoute.component}
            requiredRoles={myActionsRoute.requiredRoles}
          />
          <ProtectedRoute
            exact
            path={insightsActionsRoute.path}
            component={insightsActionsRoute.component}
            requiredRoles={insightsActionsRoute.requiredRoles}
          />
          {allowInternalTeams && (
            <ProtectedRoute
              exact
              path={insightsUsersRoute.path}
              component={insightsUsersRoute.component}
              requiredRoles={insightsUsersRoute.requiredRoles}
            />
          )}
          {map(tabLinks, (link) => (
            <ProtectedRoute
              key={link.path}
              exact
              path={link.path}
              component={link.component}
              requiredRoles={link.requiredRoles}
            />
          ))}
          <ProtectedRoute path={projectNotFound.path} component={projectNotFound.component} />
          <Redirect from="*" to={{ pathname: myAttributesRoute.path, search: queryParams.toString() }} />
        </Switch>
      </ErrorBoundary>
      <LogoutConfirmationModal shouldUseModal={showConfirmLogout} handleLogout={handleLogout} />
    </Layout>
  );
};

const AppRouter = () => {
  const { authenticated } = useIsUserAuthorized();
  return <Router>{!authenticated ? <UnauthedRouter /> : <AuthedRouter />}</Router>;
};

export default AppRouter;
