import { useQuery } from "@apollo/client";
import { GET_ATTRIBUTES } from "api/attributes";
import { saveAttributes, selectAttributes } from "features/Attributes/AttributesSlice";
import { selectProjectData } from "features/userData/userDataSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";

export const useAttributes = () => {
  const dispatch = useDispatch();
  const projectData = useSelector(selectProjectData);
  const attributeList = useSelector(selectAttributes);

  const { data, loading, refetch } = useQuery(GET_ATTRIBUTES, {
    variables: { projectId: projectData?.id },
    skip: !projectData?.id,
  });

  const stateAndApiEqual = isEqual(JSON.stringify(attributeList), JSON.stringify(data?.attributes));

  useEffect(() => {
    if (!stateAndApiEqual && Array.isArray(data?.attributes)) {
      console.count("setting attributes");
      dispatch(saveAttributes(data?.attributes));
    }
  }, [stateAndApiEqual, dispatch, data?.attributes]);

  return { loading, refetch };
};
