import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useSubmit } from "../../hooks/form.hooks";
import CustomImageInput from "components/CustomImage/CustomImageInput";
import { ActionFormButton, FormErrorText } from "theme/StyledComponents";
import { UPDATE_PROJECT_HEADER_LOGO } from "../../api/projects";

const UpdateProjectHeaderLogoForm = ({
  setEdit = () => ({}),
  refetchDashboard = () => ({}),
  accountLogoUrl,
  accountId,
  projectId
}) => {
  const {
    register,
    formState: { isDirty, errors },
    setValue,
    clearErrors,
    setError,
    getValues
  } = useForm({
    defaultValues: { image: accountLogoUrl }
  });

  const [updateLogo] = useMutation(UPDATE_PROJECT_HEADER_LOGO);

  // * Handle Submit - pass payload to updateLogo mutation
  const handleSubmit = useSubmit({
    mutation: updateLogo,
    variables: {
      data: { accountId, projectId, image: getValues().image }
    },
    useVariables: true,
    dataPath: UPDATE_PROJECT_HEADER_LOGO.definitions[0].name.value,
    useGlobalLoading: true,
    onSuccess: async () => {
      setEdit(false);
      refetchDashboard();
    }
  });

  const updateImage = (img) => {
    setValue("image", img, { shouldDirty: true, shouldTouch: true });
  };

  return (
    <div className="d-flex flex-column p-5" data-testid="UPDATE_PROJECT_HEADER_LOGO_MODAL">
      <h5 className="mb-3">Upload Project Header Image</h5>
      <span className="mb-1">
        <CustomImageInput
          name="image"
          url={accountLogoUrl}
          parentForm={{ register, setError, clearErrors }}
          height={125}
          emitImage={updateImage}
          changeText="CLICK ABOVE TO UPLOAD NEW IMAGE"
          chooseText="CLICK ABOVE TO UPLOAD NEW IMAGE"
          dimensions="SUGGESTED DIMENSIONS 300w x 90h"
        />
        <FormErrorText>{errors?.image?.message}</FormErrorText>
      </span>
      <div className="d-flex w-25 justify-content-between align-self-center">
        <ActionFormButton
          data-testid="SAVE_USER"
          type="button"
          onClick={() => handleSubmit()}
          className="btn btn-outline-success shadow border-0"
          disabled={!isDirty}
        >
          <i className="fa fa-check" />
        </ActionFormButton>
        <ActionFormButton
          type="button"
          className="btn border-0 shadow btn-outline-danger"
          onClick={() => setEdit(false)}
          buttonType="delete"
        >
          <i className="fa fa-times" />
        </ActionFormButton>
      </div>
    </div>
  );
};

export default UpdateProjectHeaderLogoForm;
