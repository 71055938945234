import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { map } from "lodash";
import editIcon from "../../assets/edit-icon.png";
import { useDispatch } from "react-redux";
import { setUserImage } from "features/userData/userDataSlice";
import AttributeCard from "../Attributes/AttributeCard";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import BasicModal from "components/BasicModal";
import UpdateUserProfileForm from "../../components/Forms/UpdateUserProfileForm";

function FeaturedUser({
  avatar,
  firstName,
  lastName,
  title,
  email,
  hasSSOAccount = false,
  featuredAttributes = [],
  refetchDashboard = () => ({})
}) {
  const dispatch = useDispatch();
  const { userImage } = useSelector((state) => state.userData);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    dispatch(setUserImage(avatar));
  }, [avatar]);

  const hasFeaturedAttributes = featuredAttributes.length > 0;

  return (
    <>
      {edit && (
        <BasicModal>
          <UpdateUserProfileForm
            user={{ firstName, lastName, title, avatar, hasSSOAccount }}
            setEdit={setEdit}
            refetchDashboard={refetchDashboard}
          />
        </BasicModal>
      )}
      <div className="pt-1 rounded k-shadow d-flex flex-column" style={{ minWidth: "375px" }}>
        <div className="card-body d-flex pe-3">
          <div style={{ flex: 1 }}>
            <div
              className="mb-2"
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative"
              }}
            >
              <span
                style={{
                  position: "relative"
                }}
              >
                <img
                  src={userImage}
                  alt="user-avatar"
                  style={{
                    borderRadius: "50%",
                    border: "2px solid #5da943",
                    objectFit: "cover",
                    height: "180px",
                    width: "180px",
                    marginLeft: hasFeaturedAttributes ? "3rem" : 0
                  }}
                />
                <button
                  onClick={() => setEdit(true)}
                  style={{
                    padding: 0,
                    border: 0,
                    background: 0,
                    position: "absolute",
                    bottom: 0,
                    right: 0
                  }}
                >
                  <img alt="edit-icon" src={editIcon} style={{ height: 25, width: 25 }} />
                </button>
              </span>
            </div>
          </div>
          {hasFeaturedAttributes && (
            <div style={{ width: "90px" }}>
              {map(featuredAttributes, (upav, i) => {
                return (
                  <div className="mb-1" style={{ height: "90px" }} key={`${i}-${upav?.displayValue}`}>
                    <AttributeCard
                      className="h-100"
                      primaryImageUrl={
                        upav?.attribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER
                          ? upav?.primaryIconUrl
                          : upav?.secondaryIconUrl
                      }
                      title={upav?.displayValue}
                      type="SMALL"
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className={`d-flex flex-column justify-content-center ${hasFeaturedAttributes && "me-4"}`}>
          <h5 className="card-title mb-0 mx-auto">{!!firstName ? `${firstName} ${lastName}` : email}</h5>
          <p className="p-0 text-muted mx-auto mb-1">{email}</p>
          <p className="p-0 text-muted mx-auto">
            <em className="card-text">{title}</em>
          </p>
        </div>
      </div>
    </>
  );
}

export default FeaturedUser;
