import { SplitFactory, SplitClient, SplitSdk } from "@splitsoftware/splitio-react";
import { useSelector } from "react-redux";
import { selectUserEmail } from "../../features/userData/userDataSlice";

// Split.io requires a `key` value to be passed that will help identify a given user.
// We are enabling a few different options. The preferred option, if a user is
// authenticated and has a token, we will pass the user's email as the key.
// if the user is not authenticated, we will assign the user as anonymous
export const FeatureFlagProvider = ({ children }) => {
  const userEmail = useSelector(selectUserEmail);
  const factory = SplitSdk({
    core: {
      authorizationKey: process.env.REACT_APP_SPLIT_API_KEY,
      key: "anonymous",
    },
  });

  return (
    <SplitFactory factory={factory}>
      <SplitClient splitKey={userEmail || "anonymous"}>{children}</SplitClient>
    </SplitFactory>
  );
};
