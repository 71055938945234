import React from "react";
import { useQuery } from "@apollo/client";
import { map, filter, orderBy } from "lodash";
import { useSelector } from "react-redux";

import {
  StyledPillButton,
  StyledDataGrid,
  ResponsiveVerticalContainer,
  ScrollBarContainer
} from "theme/StyledComponents";
import Loading from "components/Loading";
import { GET_AVAILABLE_ACTIONS } from "api/actions";
import { selectProjectData } from "../userData/userDataSlice";
import ActionSubmitModal from "components/ActionSubmitModal";
import { GET_ACTION_LOG } from "../../api/actions";
import { ACTION_TYPES } from "../Actions/utils";
import { actionLogColumns, availableActionsColumns, getOrderedActions } from "./helper";
import { selectUserId } from "app/selectors";
import { useMagicLink } from "hooks/application";

function UserDashboard() {
  const [showForm, setShowForm] = React.useState(false);
  const projectData = useSelector(selectProjectData);
  const userId = useSelector(selectUserId);

  const {
    data: availableActions,
    loading: availableActionsLoading,
    refetch: refetchAvailableActions
  } = useQuery(GET_AVAILABLE_ACTIONS, {
    variables: { projectId: projectData?.id, userId },
    fetchPolicy: "network-only",
    skip: !projectData?.id || !userId
  });

  const {
    data: actions,
    loading: actionsLoading,
    refetch: refetchActionLog
  } = useQuery(GET_ACTION_LOG, {
    variables: { projectId: projectData?.id },
    fetchPolicy: "no-cache",
    skip: !projectData?.id
  });

  const refetchDashboard = React.useCallback(async () => {
    await Promise.all([refetchActionLog(), refetchAvailableActions()]);
  }, []);

  useMagicLink(refetchDashboard);

  if (actionsLoading || !actions) {
    return <Loading />;
  }

  const mapCustomActionToRow = (action) => ({
    ...action,
    display: `${action.name}, ${action.result}`
  });

  const submittableActions = filter(
    map(availableActions?.availableActions, mapCustomActionToRow),
    (a) => a.type === ACTION_TYPES.MANUAL_ENTRY_PARTICIPANT
  );

  const toggleForm = () => setShowForm(!showForm);

  if (availableActionsLoading || !availableActions) {
    return <Loading />;
  }

  return (
    <>
      {showForm && (
        <ActionSubmitModal
          submittableActions={filter(submittableActions, { applyStrategy: "INSTANCE" })}
          toggle={toggleForm}
          refetchDashboard={refetchDashboard}
        />
      )}
      <ScrollBarContainer className="d-flex row">
        <div className="w-100 d-inline-flex align-items-center justify-content-center">
          <StyledPillButton
            onClick={toggleForm}
            style={{ width: "10rem" }}
            data-testid="SUBMIT_ACTION"
            disabled={submittableActions?.length === 0}
          >
            + Submit Action
          </StyledPillButton>
        </div>
        <ResponsiveVerticalContainer className="col-md-5 col-sm-12" data-testid="AVAILABLE_ACTIONS">
          <h5 className="mx-auto mb-0" style={{ maxHeight: "2rem" }}>
            Available Actions
          </h5>
          <StyledDataGrid
            className="mb-5"
            sx={{
              minHeight: "25rem"
            }}
            rows={orderBy(availableActions?.availableActions, ["timesDone"], ["desc"])}
            columns={availableActionsColumns(refetchDashboard)}
            pageSize={10}
            rowsPerPage={[10]}
            rowsPerPageOptions={[10]}
          />
        </ResponsiveVerticalContainer>
        <ResponsiveVerticalContainer className="col-md-7 col-sm-12 pb-3" data-testid="MY_ACTIONS">
          <h5 className="mx-auto mb-0" style={{ maxHeight: "2rem" }}>
            Action & Auto Update Log
          </h5>
          <StyledDataGrid
            className="mb-5"
            style={{ minHeight: "25rem" }}
            rows={getOrderedActions(actions?.actionLog)}
            columns={actionLogColumns}
            pageSize={10}
            rowsPerPage={[10]}
            rowsPerPageOptions={[10]}
          />
        </ResponsiveVerticalContainer>
      </ScrollBarContainer>
    </>
  );
}

export default UserDashboard;
