import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";
import Logo from "assets/header-logo.png";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import {
  StyledPillButton,
  FormErrorText,
  AuthenticationCard,
  AuthenticationContainer,
  AuthFlowLink
} from "theme/StyledComponents";
import authenticateUser from "api/auth";
import { passphraseValidation } from "utils/form.utils";
import { useMutation } from "@apollo/client";
import { RESET_PASSPHRASE } from "api/users";
import { useSubmit } from "hooks/form.hooks";
import { passphraseRecoverRoute, loginRoute } from "../../routes";

const ResetPassphrase = () => {
  const { alert } = useSelector((state) => state.commonState);

  const [resetPassphrase] = useMutation(RESET_PASSPHRASE, {
    headers: {
      accessControlAllowOrigin: "*"
    }
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const emailAddress = urlParams.get("email")?.replace(/\s/g, "+"); // TODO(MB) this is to handle not encoding/decoding url params correctly yet

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: { passphrase: "", confirmPassphrase: "" }
  });

  const passphrase = watch("passphrase");
  const confirmPassphraseValidation = {
    ...passphraseValidation,
    required: "Please confirm passphrase",
    validate: (value) => value?.trim() === passphrase?.trim() || "Passphrases do not match"
  };
  const loginUser = React.useCallback(() => {
    dispatch(authenticateUser.authenticatePassphrase({ email: emailAddress, passphrase }, history));
  }, [dispatch, emailAddress, passphrase, history]);

  const handleResetPassphrase = useSubmit({
    mutation: resetPassphrase,
    variables: {
      data: {
        passphrase: passphrase.trim(),
        linkSignature: urlParams.get("code"),
        email: emailAddress
      }
    },
    useVariables: true,
    dataPath: RESET_PASSPHRASE.definitions[0].name.value
  });

  useEffect(() => {
    if (alert?.message?.toLowerCase().includes("link expired")) {
      setTimeout(() => {
        history.push(passphraseRecoverRoute.path);
      }, 3000);
    }
  }, [alert?.message, history]);

  useEffect(() => {
    let id1;
    let id2;
    if (alert?.message?.toLowerCase().includes("success")) {
      id1 = setTimeout(() => {
        loginUser();
      }, 2000);
      id2 = setTimeout(() => {
        // go to login if automatic login fails
        history.push(`${loginRoute.path}?p=true&email=${emailAddress}`);
      }, 10000);
      return () => {
        clearTimeout(id1);
        clearTimeout(id2);
      };
    }
  }, [alert?.message, emailAddress, history, loginUser]);

  return (
    <AuthenticationContainer>
      <AuthenticationCard>
        <img alt="Katilyst logo" src={Logo} width="100%" />
        <p className="mt-3 text-center" style={{ fontSize: "1.125rem" }}>
          {emailAddress}
        </p>
        <FormGroup className="my-3 d-flex flex-column align-items-center w-100">
          <span className="mb-1 w-100">
            <UncontrolledInput
              formRegister={register}
              name="passphrase"
              labelText="PASSPHRASE"
              validation={passphraseValidation}
              height="3.5rem"
              passwordInput
              className={errors?.passphrase?.message && "error"}
              style={{ width: "100%" }}
            />
            <FormErrorText className="m-0">{errors?.passphrase?.message}</FormErrorText>
          </span>
          <span className="mb-1 w-100">
            <UncontrolledInput
              formRegister={register}
              name="confirmPassphrase"
              labelText="CONFIRM PASSPHRASE"
              validation={confirmPassphraseValidation}
              height="3.5rem"
              passwordInput
              className={errors?.confirmPassphrase?.message && "error"}
              style={{ width: "100%" }}
            />
            <FormErrorText className="m-0">{errors?.confirmPassphrase?.message}</FormErrorText>
          </span>
        </FormGroup>

        <StyledPillButton
          type="button"
          className="submitBtn mx-auto"
          onClick={handleSubmit(handleResetPassphrase)}
          style={{ width: "12rem" }}
        >
          Reset Passphrase
        </StyledPillButton>
        <AuthFlowLink to={loginRoute.path} className="text-center my-3">
          Already have an account? <span>Sign in</span>
        </AuthFlowLink>
      </AuthenticationCard>
    </AuthenticationContainer>
  );
};

export default React.memo(ResetPassphrase);
