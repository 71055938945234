import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform, // <-- Add this import
} from "redux-persist";
import storageSession from "redux-persist/lib/storage";

import rootReducer from "./rootReducer";

// SINCE WE ARE USING PERSISTED STORE, THERE MAY BE VALUES THAT WE DO NOT WANT TO RE-HYDRATE
// USE THIS TRANSFORM TO SPECIFY DEFAULT VALUES FOR STATE PROPERTIES THAT YOU DO NOT WANT PERSISTED
const selectedUserTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (outboundState, key) => {
    if (key === "userData") {
      return { ...outboundState, selectedUser: null }; // WE WANT selectedUser NOT to persist - it can cause some jump behavior
    }
    return outboundState;
  },
  // transform state being rehydrated
  (inboundState, key) => {
    if (key === "userData") {
      return { ...inboundState, selectedUser: null };
    }
    return inboundState;
  },
);

const persistConfig = {
  key: "root",
  storage: storageSession,
  transforms: [selectedUserTransform], // <-- Add the transform here
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
