import React from "react";
import { useQuery } from "@apollo/client";
import { map, groupBy } from "lodash";
import { useSelector } from "react-redux";

import { StyledCardShadow, ResponsiveVerticalContainer } from "theme/StyledComponents";
import { GET_USER_DASHBOARD } from "api/users";
import FeaturedUser from "./FeaturedUser";
import Loading from "components/Loading";
import DefaultAvatar from "../../assets/user-icon.png";
import { selectProjectData } from "../userData/userDataSlice";
import AttributeCard from "features/Attributes/AttributeCard";
import { selectUserId } from "app/selectors";
import { UserConnections } from "components/UserConnections/UserConnections";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import { selectUsers } from "app/project.slice";
import { allowConnections } from "../../utils/roles.utils";

function UserDashboard() {
  const projectData = useSelector(selectProjectData);
  const userId = useSelector(selectUserId);
  const users = useSelector(selectUsers);

  const {
    data: dashboard,
    loading: dashboardLoading,
    refetch
  } = useQuery(GET_USER_DASHBOARD, {
    variables: {
      projectId: projectData?.id
    },
    skip: !projectData?.id,
    fetchPolicy: "no-cache"
  });

  if (dashboardLoading || !dashboard) {
    return <Loading />;
  }

  const user = dashboard.userDashboard;

  const {
    PROFILE: onProfile,
    METRICS_AREA: onMetricsArea,
    ATTRIBUTE_LIST: onAttributeList
  } = groupBy(user.userAttributeValues, "attribute.displayLocation");

  return (
    <div className="d-flex row">
      <ResponsiveVerticalContainer className="col-md-4 col-sm-12">
        <div className="mb-4" style={{ maxHeight: 310 }}>
          <FeaturedUser
            avatar={user.avatar || DefaultAvatar}
            title={user.jobTitle}
            email={user.email}
            firstName={user?.firstName}
            lastName={user?.lastName}
            hasSSOAccount={user?.hasSSOAccount}
            featuredAttributes={onProfile}
            refetchDashboard={refetch}
          />
        </div>
        <StyledCardShadow className="pb-2 px-4 mt-1">
          <div className="d-flex row">
            <FeatureFlag featureName={FEATURE_FLAGS.CONNECTIONS}>
              {allowConnections(user?.roles) && !!users && (
                <UserConnections
                  sourceUserId={userId}
                  projectUsers={[{ id: "NONE", fullName: "None" }, ...users]}
                  sideAConnections={user.userSideAConnections}
                  sideBConnections={user.userSideBConnections}
                  incomingRequests={user.incomingUserConnectionRequests}
                  outgoingRequests={user.outgoingUserConnectionRequests}
                  handleSuccess={refetch}
                />
              )}
            </FeatureFlag>
            {map(onAttributeList, (upav) => {
              return (
                <div className="col-xs-12 mb-3" key={upav.attribute.id}>
                  <AttributeCard
                    primaryImageUrl={upav?.primaryIconUrl}
                    secondaryImageUrl={upav?.secondaryIconUrl}
                    title={upav.attribute.name}
                    subTitle={upav?.displayValue || upav?.value}
                  />
                </div>
              );
            })}
          </div>
        </StyledCardShadow>
      </ResponsiveVerticalContainer>
      <ResponsiveVerticalContainer className="d-flex flex-column col-md-8 col-sm-12">
        <StyledCardShadow>
          <div className="d-flex row px-3">
            {map(onMetricsArea, (upav) => {
              return (
                <div className="col-md-6 mb-3" key={upav.attribute.id}>
                  <AttributeCard
                    primaryImageUrl={upav?.primaryIconUrl}
                    secondaryImageUrl={upav?.secondaryIconUrl}
                    title={upav.attribute.name}
                    subTitle={upav?.displayValue || upav?.value}
                  />
                </div>
              );
            })}
          </div>
        </StyledCardShadow>
      </ResponsiveVerticalContainer>
    </div>
  );
}

export default UserDashboard;
