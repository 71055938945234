import { useCallback, useEffect, useRef } from "react";
import { showAlert } from "features/Common/CommonSlice";
import { useDispatch } from "react-redux";

export const useHandleError = (msg = "There was an error - Please retry ") => {
  const dispatch = useDispatch();

  const fn = useCallback(
    (err) => {
      dispatch(
        showAlert({
          message: err || msg,
          type: "danger"
        })
      );
    },
    [dispatch, msg]
  );

  return fn;
};

export const useHandleSuccess = (msg = "Success", onSuccess) => {
  const dispatch = useDispatch();
  const fn = useCallback(
    (apiMessage) => {
      dispatch(
        showAlert({
          message: apiMessage || msg
        })
      );
      if (!!onSuccess) {
        onSuccess();
      }
    },
    [dispatch, onSuccess, msg]
  );

  return fn;
};

export const usePreviousValue = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
