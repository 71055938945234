import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_PROFILE } from "api/users";
import { useSubmit } from "../../hooks/form.hooks";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import CustomImageInput from "components/CustomImage/CustomImageInput";
import { ActionFormButton, FormErrorText } from "theme/StyledComponents";

const UpdateUserProfileForm = ({ setEdit = () => ({}), user, refetchDashboard = () => ({}) }) => {
  const { firstName, lastName, title: jobTitle, avatar, hasSSOAccount = false } = user;
  const {
    register,
    formState: { isDirty, errors },
    setValue,
    setError,
    reset,
    handleSubmit,
    clearErrors
  } = useForm({
    defaultValues: { firstName, lastName, jobTitle, image: null }
  });

  const [updateProfile] = useMutation(UPDATE_USER_PROFILE);

  const updateUser = useSubmit({
    mutation: updateProfile,
    dataPath: UPDATE_USER_PROFILE.definitions[0].name.value,
    onSuccess: () => {
      setEdit(false);
      refetchDashboard();
    }
  });

  const updateImage = (img) => {
    setValue("image", img, { shouldDirty: true, shouldTouch: true });
  };

  const requiredValidation = { required: "Required" };

  return (
    <div className="d-flex flex-column p-5">
      <h5 className="mb-3">Edit User Profile</h5>
      <span className="mb-1">
        <UncontrolledInput
          name="firstName"
          labelText="FIRST NAME"
          formRegister={register}
          validation={requiredValidation}
          disabled={hasSSOAccount}
        />
        <FormErrorText>{errors?.firstName?.message}</FormErrorText>
      </span>
      <span className="mb-1">
        <UncontrolledInput
          name="lastName"
          labelText="LAST NAME"
          formRegister={register}
          validation={requiredValidation}
          disabled={hasSSOAccount}
        />
        <FormErrorText>{errors?.lastName?.message}</FormErrorText>
      </span>
      <span className="mb-1">
        <UncontrolledInput name="jobTitle" labelText="JOB TITLE" formRegister={register} />
        <FormErrorText>{errors?.jobTitle?.message}</FormErrorText>
      </span>
      <span className="mb-1">
        <CustomImageInput
          name="image"
          url={avatar}
          parentForm={{ register, setError, clearErrors }}
          emitImage={updateImage}
          height={125}
          changeText="Change profile image"
          chooseText="Choose profile image"
        />
        <FormErrorText>{errors?.image?.message}</FormErrorText>
      </span>
      <div className="d-flex w-50 justify-content-between align-self-center">
        <ActionFormButton
          data-testid="SAVE_USER"
          type="button"
          onClick={handleSubmit(updateUser)}
          className="btn btn-outline-success shadow border-0"
          disabled={!isDirty}
        >
          <i className="fa fa-check" />
        </ActionFormButton>

        <ActionFormButton
          disabled={!isDirty}
          data-testid="undo"
          type="button"
          className="btn shadow border-0 btn-outline-primary"
          buttonType="undo"
          onClick={() => reset()}
        >
          <i className="fa fa-undo" />
        </ActionFormButton>
        <ActionFormButton
          type="button"
          className="btn border-0 shadow btn-outline-danger"
          onClick={() => setEdit(false)}
          buttonType="delete"
        >
          <i className="fa fa-times" />
        </ActionFormButton>
      </div>
    </div>
  );
};

export default UpdateUserProfileForm;
