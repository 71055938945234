import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";

import { ActionFormButton, StyledCardShadow } from "../../theme/StyledComponents";
import ConfirmationModal from "../ConfirmationModal";

import { useMutation } from "@apollo/client";
import { DELETE_UPLOAD_ACTION_MAPPING_CONFIG } from "../../api/csv-upload";

// * LoadColumnAndActionMappingModal: handles the display, selection, and deletion of ActionUploadConfigs
const LoadColumnAndActionMappingModal = ({
  columnAndActionMaps = [],
  projectId,
  configId,
  emitSelectedMapping,
  emitRefetchActionMappingConfigs,
  toggleLoadColumnAndActionMappingModal,
  handleSuccess,
  handleError
}) => {
  const [deleteActionMappingConfig] = useMutation(DELETE_UPLOAD_ACTION_MAPPING_CONFIG);

  const [actionMaps, setColumnAndActionMaps] = useState(columnAndActionMaps);
  const [confirmationBodyText, setConfirmationBodyText] = useState(null);
  const [hoverId, setHoverId] = useState(null); // * hover css toggle on individual rows
  const [idForDelete, setIdForDelete] = useState(null);
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const toggleConfirm = () => setShowConfirm(!showConfirm);

  const handleSelectedMapping = () => {
    emitSelectedMapping(null, selectedMapping);
    toggleLoadColumnAndActionMappingModal();
  };

  const handleSelectConfig = (id) => {
    setSelectedMapping(id);
    setHoverId(null);
  };

  const handleSetIdForDelete = (id) => {
    setIdForDelete(id);
    setConfirmationBodyText(`Delete selected config?`);
    toggleConfirm();
  };

  const deleteSelected = async () => {
    await deleteActionMappingConfig({
      variables: { configId: idForDelete, config: { active: false }, projectId: projectId },
      onError: (e) => {
        handleError(e.message);
      },
      onCompleted: (res) => {
        if (res.deleteActionMappingConfig.success) {
          setConfirmationBodyText(null);
          emitRefetchActionMappingConfigs(true);
          setColumnAndActionMaps(actionMaps.filter((map) => map.id !== idForDelete));
          setIdForDelete(null);
          handleSuccess("Config deleted.");
          toggleConfirm();
        }
      }
    });
  };

  const addHover = (id) => {
    if (selectedMapping !== id) {
      setHoverId(id);
    }
  };

  const removeHover = () => {
    setHoverId(null);
  };

  return (
    <>
      {showConfirm && (
        <ConfirmationModal
          type="warning"
          toggle={toggleConfirm}
          handleSubmit={deleteSelected}
          bodyText={confirmationBodyText}
        />
      )}

      <Modal isOpen>
        <div className="d-flex flex-row justify-content-around mt-2">
          <h5 className="m-auto">Load Column and Action Mapping</h5>
          <div className="d-flex flex-row justify-content-end me-2">
            <ActionFormButton
              id="submit"
              type="button"
              onClick={handleSelectedMapping}
              className="btn btn-outline-success shadow border-0"
              disabled={!selectedMapping}
              data-testid="SUBMIT_LOAD_ACTION_MAPPING_MODAL"
            >
              <i className="fa fa-check" />
            </ActionFormButton>

            <ActionFormButton
              id="cancel"
              onClick={() => toggleLoadColumnAndActionMappingModal()}
              className="btn shadow border-0 btn-outline-danger ms-1"
              data-testid="CLOSE_ACTION_MAPPING_MODAL"
            >
              <i className="fa fa-times" />
            </ActionFormButton>
          </div>
        </div>
        <ModalBody>
          <StyledCardShadow className="text-center mt-2" style={{ height: "250px", overflowY: "scroll" }}>
            {actionMaps.map((cam) => {
              return (
                <div
                  key={cam.id}
                  className="mt-1 m-auto"
                  style={
                    hoverId === cam.id && selectedMapping !== cam.id
                      ? {
                          ...defaultStyles,
                          backgroundColor: "#F3F8FF",
                          cursor: "pointer"
                        }
                      : { ...defaultStyles, cursor: "pointer" }
                  }
                >
                  <div
                    className="d-flex flex-row justify-content-between m-auto w-100"
                    style={
                      selectedMapping === cam.id && hoverId !== cam.id
                        ? {
                            backgroundColor: "#e2edff",
                            color: "black",
                            cursor: "pointer",
                            padding: ".5rem",
                            borderRadius: "5px"
                          }
                        : { color: "black", cursor: "pointer", padding: ".5rem" }
                    }
                  >
                    <div
                      onMouseEnter={() => addHover(cam.id)}
                      onMouseLeave={() => removeHover()}
                      onClick={() => handleSelectConfig(cam.id)}
                      data-testid={`select-${cam.configName}`}
                      className="w-100 text-start m-auto ms-3"
                    >
                      {cam.configName}
                    </div>
                    {configId !== cam.id ? (
                      <div className="d-flex flex-row justify-content-end">
                        <ActionFormButton
                          id="cancel"
                          onClick={() => handleSetIdForDelete(cam.id)}
                          className="btn border-0 btn-outline-danger justify-content-end m-auto"
                          data-testid={`delete-${cam.configName}`}
                        >
                          <i className="fa fa-times" />
                        </ActionFormButton>
                      </div>
                    ) : (
                      <div style={{ height: "35px" }} />
                    )}
                  </div>
                </div>
              );
            })}
          </StyledCardShadow>
        </ModalBody>
      </Modal>
    </>
  );
};

const defaultStyles = {
  borderWidth: "1px",
  borderColor: "#EFEFEF",
  borderStyle: "solid",
  borderRadius: "5px",
  width: "100%",
  height: "auto"
};

export default LoadColumnAndActionMappingModal;
