import React from "react";

function LogicalOperator({ handleClick, type, active, testId = "" }) {
  const styles = {
    background: active ? "#e3f8e9" : "#fff",
    borderRadius: "100px",
    color: "black",
    width: "8rem",
  };

  return (
    <button className="btn shadow-sm" onClick={handleClick} style={styles} data-testid={testId}>
      {type}
    </button>
  );
}

export default LogicalOperator;
