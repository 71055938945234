import { createSelector } from "reselect";
import jwt from "jwt-decode";
import { get } from "lodash";

const getBaseApplicationState = (state) => state;

export const userAuthToken = createSelector(getBaseApplicationState, (state) => get(state, "login.authToken", null));

export const selectUserId = createSelector(userAuthToken, (token) => {
  try {
    return get(jwt(token), "sub", "");
  } catch (err) {
    console.info("invalid token", err);
    return "";
  }
});
