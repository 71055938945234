import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { selectAccountDict, selectAccountList, setAccountData } from "features/userData/userDataSlice";
import { useUpdateQueryParams } from "./useUpdateQueryParams";
import { useQueryParams } from "./useQueryParams";

export const useAccountFromParams = () => {
  const dispatch = useDispatch();
  const queryParams = useQueryParams();
  const accountIdFromParams = queryParams.get("accountId");
  const updateSearchParams = useUpdateQueryParams();
  const accounts = useSelector(selectAccountList);
  const accountDict = useSelector(selectAccountDict);
  const [firstAccount] = accounts;
  const targetAccount = accountDict[accountIdFromParams];

  useEffect(() => {
    if (!isEmpty(accounts)) {
      if (targetAccount) {
        // set to application state
        dispatch(setAccountData(targetAccount));
      } else {
        // update query params to first account which will be available in next loop as targetAccount
        queryParams.set("accountId", firstAccount.id);
        updateSearchParams(queryParams, true);
      }
    }
  }, [accounts?.length, dispatch, queryParams, accountIdFromParams, targetAccount]); // eslint-disable-line
};
