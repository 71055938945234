import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { isEmpty, keyBy } from "lodash";

import TeamsLogo from "../assets/microsoft-teams-logo.png";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import { useIntegrations } from "hooks/application.hooks";
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";

const style = {
  alignItems: "center",
  color: "#000",
  backgroundColor: "#fff",
  border: "1px solid #ddd",
  borderRadius: "4px",
  display: "inline-flex",
  fontFamily: "Lato, sans-serif",
  fontSize: "14px",
  fontWeight: 600,
  height: "48px",
  width: "48px",
  justifyContent: "center",
  textDecoration: "none",
  boxShadow: "1px 1px 2px 1px rgba(0,0,0,0.38)"
};
const imgStyle = {
  height: "36px",
  width: "36px",
  marginRight: 0
};

function MSTeamsButton() {
  const { integrations } = useIntegrations();
  const integrationsDict = keyBy(integrations, "type");
  const hasTeamsIntegration = !isEmpty(integrationsDict.TEAMS);

  const integrationsEnabled = useFeatureFlag(FEATURE_FLAGS.INTEGRATION_ACCESS);
  return integrationsEnabled && hasTeamsIntegration ? (
    <>
      <button
        style={{
          ...style,
          backgroundColor: "white",
          pointerEvents: "pointer-enter",
          cursor: "pointer"
        }}
        id="ms-teams-button"
      >
        <img src={TeamsLogo} style={imgStyle} alt="ms-teams-logo" />
      </button>
      <UncontrolledTooltip placement="right" target="ms-teams-button">
        Teams Integration enabled - Use the form to add notification channels
      </UncontrolledTooltip>
    </>
  ) : (
    <>
      <button
        style={{
          ...style,
          backgroundColor: "rgb(211,211,211, 0.7)",
          pointerEvents: "pointer-enter",
          cursor: "pointer"
        }}
        disabled
        id="ms-teams-button"
      >
        <img src={TeamsLogo} style={imgStyle} alt="ms-teams-logo" />
      </button>
      <UncontrolledTooltip hideArrow placement="top" target="ms-teams-button">
        Please contact us if you're interested in integrations! <a href="mailto:info@katilyst.com">info@katilyst.com</a>
      </UncontrolledTooltip>
    </>
  );
}

export default MSTeamsButton;
