import React from "react";
import "./SideDrawer.css";
import KatilystLink from "hocs/KatilystLink";
import Logo from "assets/header-logo.png";

const sideDrawer = (props) => {
  let drawerClasses = ["side-drawer"];

  if (props.show) {
    drawerClasses = ["side-drawer", "open"];
  }

  const closeDrawer = () => {
    props.closeSideDrawer();
  };
  return (
    <>
      <nav className={drawerClasses.join(" ")}>
        <button className="toggle-button mt-2 ml-3" onClick={() => closeDrawer()}>
          <div className="toggle-button-line cross button-line-1" />
          <div className="toggle-button-line cross button-line-2" />
          <div className="toggle-button-line cross button-line-3" />
        </button>
        <ul>
          <li>
            <KatilystLink to="/">
              <img alt="Katilyst Logo" src={Logo} />
            </KatilystLink>
          </li>
          <li>
            <a href="/">Attributes</a>
          </li>
          <li>
            <a href="/">Events</a>
          </li>
          <li>
            <a href="/">Dashboard Design</a>
          </li>
        </ul>
      </nav>
    </>
  );
};
export default sideDrawer;
