import { gql } from "@apollo/client";

export const CREATE_TEAM = gql`
  mutation createTeam($data: TeamCreateInput!) {
    createTeam(data: $data) {
      message
      success
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: String!) {
    deleteTeam(id: $id) {
      message
      success
    }
  }
`;

export const EDIT_TEAM = gql`
  mutation editTeam($data: TeamEditInput!) {
    editTeam(data: $data) {
      message
      success
    }
  }
`;
export const GET_ACCOUNT_TEAMS = gql`
  query accountTeams($accountId: String!) {
    accountTeams(accountId: $accountId) {
      name
      id
      type
    }
  }
`;
export const UPDATE_USER_TEAM = gql`
  mutation updateUserTeam(
    $teamId: String!
    $accountId: String!
    $selectedUserId: String!
  ) {
    updateUserTeam(
      teamId: $teamId
      accountId: $accountId
      selectedUserId: $selectedUserId
    ) {
      success
      message
    }
  }
`;
