import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectProjectDict, selectProjectList, setProjectData } from "features/userData/userDataSlice";
import { useUpdateQueryParams } from "./useUpdateQueryParams";
import { useQueryParams } from "./useQueryParams";

export const useProjectFromParams = () => {
  const dispatch = useDispatch();
  const updateSearchParams = useUpdateQueryParams();
  const queryParams = useQueryParams();
  const projects = useSelector(selectProjectList);
  const projectDict = useSelector(selectProjectDict);
  const [firstProject] = projects;
  const projectIdFromParams = queryParams.get("projectId");
  const targetProject = projectDict[projectIdFromParams];

  useEffect(() => {
    if (projects?.length) {
      if (targetProject) {
        // set to application state
        dispatch(setProjectData(targetProject));
      } else {
        // update query params to first project which will be available in next loop a target project
        queryParams.set("projectId", firstProject.id);
        updateSearchParams(queryParams, true);
      }
    }
  }, [projects?.length, queryParams, projectIdFromParams, dispatch, targetProject]); // eslint-disable-line
};
