import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import ConditionIcon from "assets/condition_icon.png";
import { Prompt } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useForm, useFieldArray } from "react-hook-form";

import { StyledAttributeHeading } from "features/Attributes/Attributes.style";
import { StyledPillButton, StyledCardShadow, ResponsiveVerticalContainer } from "theme/StyledComponents";
import { selectNonConnectionAttributes } from "features/Attributes/AttributesSlice";
import { GET_RULES, UPDATE_RULE_ORDER } from "api/rules";
import { saveRule } from "./RulesSlice";
import Loading from "components/Loading";
import { newRuleBoilerPlate, shouldShowRule } from "./utils";
import RuleForm from "components/Forms/RuleForm";
import messages from "../../api/messages";
import { selectSpecificOperators } from "app/operators.slice";
import { useHandleScrollToTop } from "hooks/dom.hooks";
import { reduceIds } from "utils/list.utils";
import KatilystLink from "hocs/KatilystLink";
import { selectProjectData } from "features/userData/userDataSlice";
import { useAttributes } from "hooks/application";

const Rules = () => {
  const dispatch = useDispatch();
  const [dirtyForms, setDirtyForms] = useState(new Set());
  const { loading: attributesLoading } = useAttributes();

  const [equalsOperator, greaterThanOperator] = useSelector(selectSpecificOperators(["=", ">"]));
  const attributesList = useSelector(selectNonConnectionAttributes);
  const projectData = useSelector(selectProjectData);

  const [updateRuleOrder] = useMutation(UPDATE_RULE_ORDER);

  const { data: rulesData, loading: rulesLoading } = useQuery(GET_RULES, {
    variables: { projectId: projectData?.id },
    skip: !projectData?.id,
    fetchPolicy: "no-cache" // Used for first execution,
  });

  const { control, getValues, reset } = useForm({
    defaultValues: {
      rules: rulesData?.getRules
    }
  });
  const { fields, prepend, remove, move } = useFieldArray({
    control,
    name: "rules"
  });

  const saveRuleOrder = async () => {
    const list = reduceIds(getValues().rules);
    await updateRuleOrder({
      variables: { data: { list, projectId: projectData?.id } },
      skip: !projectData?.id,
      fetchPolicy: "no-cache"
    });

    dispatch(saveRule(getValues().rules));
    setDirtyForms((prev) => {
      const newVals = [...prev].filter((id) => !!id);
      return new Set(newVals);
    });
  };

  const handleOnDragEnd = async ({ destination, source }) => {
    move(source.index, destination.index);
    await saveRuleOrder();
  };

  const onRuleSaveSuccess = async () => {
    await saveRuleOrder();
  };

  useEffect(() => {
    reset({ rules: rulesData?.getRules });
  }, [rulesData?.getRules, reset]);

  const handleScrollToTop = useHandleScrollToTop("rulesContainer");

  const addNewRule = () => {
    const newRule = newRuleBoilerPlate(projectData, attributesList, [greaterThanOperator, equalsOperator]);
    handleScrollToTop();
    prepend(newRule);
  };

  const handleDelete = (rule, i, cb) => {
    if (!Object.hasOwn(rule, "createdAt")) {
      // UI delete if not saved
      remove(i);
      return;
    }
    cb();
  };

  const loading = rulesLoading || !rulesData || attributesLoading;

  if (loading) {
    return <Loading />;
  }

  if (attributesList.length < 2) {
    return (
      <h5 style={{ margin: "5rem auto", width: "100%", textAlign: "center" }}>
        Uh oh! You need to define at least two Attributes before creating a system rule. Add them{" "}
        <KatilystLink to="/configure/attributes">here</KatilystLink> and then come back to this page.
      </h5>
    );
  }

  // * NOTE(AF 6/17/24) - Droppable console warning comes from 'overflow-y: auto' being set on StyledCardShadow component
  // * the component needs to be able to scroll but it also needs to be Droppable....
  // * beautiful-dnd is no longer maintained they suggest moving to: https://github.com/atlassian/pragmatic-drag-and-drop

  return (
    <>
      <Prompt message={messages.UNSAVED_CHANGES} when={dirtyForms.size > 0} />
      <StyledAttributeHeading className="pt-5 d-lg-none d-xl-none d-md-none">
        <h3>Rules</h3>
        <img style={{ height: "50px", marginLeft: "10px" }} alt="" src={ConditionIcon} />
      </StyledAttributeHeading>
      <div className="my-0 mx-auto d-flex justify-content-center" style={{ maxHeight: "2.5rem" }}>
        <StyledPillButton onClick={addNewRule} data-testid="ADD_RULE">
          <i className="fa fa-plus" />
          &nbsp; Add Rule
        </StyledPillButton>
      </div>

      <ResponsiveVerticalContainer>
        <StyledCardShadow>
          <Row className="p-0">
            <Col md={12} className="px-4 py-0" id="rulesContainer">
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="ruleList">
                  {(provided) => (
                    <span ref={provided.innerRef} {...provided.droppableProps}>
                      {provided.placeholder}
                      {fields.map((rule, index) => {
                        const ruleId = getValues().rules[index].id;
                        return (
                          shouldShowRule(rule) && (
                            <Draggable draggableId={ruleId} key={ruleId} index={index}>
                              {(prv) => (
                                <span {...prv.draggableProps} {...prv.dragHandleProps} ref={prv.innerRef}>
                                  <RuleForm
                                    key={ruleId}
                                    rule={rule}
                                    index={index}
                                    attributes={attributesList}
                                    handleDelete={handleDelete}
                                    parentForm={{ getValues, remove }}
                                    onRuleSaveSuccess={onRuleSaveSuccess}
                                    ruleId={ruleId}
                                    setDirtyForms={setDirtyForms}
                                  />
                                </span>
                              )}
                            </Draggable>
                          )
                        );
                      })}
                    </span>
                  )}
                </Droppable>
              </DragDropContext>
            </Col>
          </Row>
        </StyledCardShadow>
      </ResponsiveVerticalContainer>
    </>
  );
};

export default Rules;
