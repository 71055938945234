import {
  selectAccountData,
  selectProjectData,
  selectProjectList,
  selectUserAccountAccess,
  setAccounts,
  setBaseUserData,
  setUserAccountAccess
} from "features/userData/userDataSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, flattenDeep, isEmpty, map } from "lodash";
import { showAlert } from "features/Common/CommonSlice";
import { useLocation } from "react-router-dom";

import { userAuthToken } from "app/selectors";
import { mapProjectOptions } from "../hocs/Layout/Header/utils";
import { myActionsRoute, myAttributesRoute, tabLinks } from "routes";
import { insightsActionsRoute, insightsUsersRoute } from "../routes";
import { GET_OPERATORS } from "../api/operators";
import { useLazyQuery, useQuery } from "@apollo/client";
import { saveOperators } from "app/operators.slice";
import { GET_ME } from "../api/users";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";

import { GET_INTEGRATIONS, GET_MS_TEAMS_CHANNELS, GET_SLACK_CHANNELS } from "../api/integrations";
import { CHECK_ACTION_WITHIN_TIME_LIMIT } from "api/actions";
import { saveIntegrations, saveMSTeamsChannels, saveSlackChannels } from "app/integrations.slice";
import { getIsAuthorized } from "utils/auth.utils";
import { GET_ACCOUNTS } from "api/accounts";
import { baseLogout } from "app/rootReducer";

export const useUserAccountAccess = () => {
  const dispatch = useDispatch();
  const { accountRoles, accountData: currentAccount } = useSelector((state) => state.userData);

  useEffect(() => {
    if (!isEmpty(accountRoles) && currentAccount?.id) {
      const allRoles = map(accountRoles, (val) => [...val.roles]);
      const isSuper = flattenDeep(allRoles).includes("superadmin");
      const accountAccess = accountRoles?.[currentAccount.id];
      const permissions = new Set(accountAccess?.roles);

      if (isSuper) {
        permissions.add("user"); // fallback for all users
        permissions.add("superadmin");
        permissions.add("admin");
      }

      const tier = currentAccount?.accountTier
        ? currentAccount?.accountTier
        : accountAccess?.account?.accountTier ?? "FREEMIUM";

      dispatch(
        setUserAccountAccess({
          tier,
          permissions: [...permissions]
        })
      );
    }
  }, [accountRoles, currentAccount?.id]);
};

export const useIsUserAuthorized = (requiredRoles = []) => {
  const [authorized, setAuthorized] = useState(true);
  const { permissions = ["user"] } = useSelector(selectUserAccountAccess) || {};
  const token = useSelector(userAuthToken);

  useEffect(() => {
    setAuthorized(getIsAuthorized(requiredRoles, permissions));
  }, [requiredRoles, permissions, token]);

  return { authenticated: !!token, authorized };
};

export const useAccounts = () => {
  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(GET_ACCOUNTS, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (data?.getAccounts.length) {
      dispatch(setAccounts(data?.getAccounts));
    }
  }, [data?.getAccounts, dispatch]);

  return { loading, refetch };
};

export const useClearAlert = (message) => {
  const dispatch = useDispatch();
  return useEffect(() => {
    if (message) {
      const id = window.setTimeout(
        () => {
          dispatch(showAlert({ message: null }));
        },
        !!window?.Cypress ? 500 : 4500 // hide alerts quickly during testing
      );

      return () => window.clearTimeout(id);
    }
  }, [message, dispatch]);
};

export const useSubmenu = () => {
  const allowInternalTeams = useFeatureFlag(FEATURE_FLAGS.TEAMS_INTERNAL);
  const { permissions = ["user"] } = useSelector(selectUserAccountAccess) || {};

  const subMenus = useMemo(
    () => ({
      dashboard: [myActionsRoute, myAttributesRoute],
      configure: tabLinks,
      insights: [
        insightsActionsRoute,
        allowInternalTeams && permissions.includes("team_observer") ? insightsUsersRoute : undefined
      ]
    }),
    [allowInternalTeams]
  );
  const [subMenuLinks, setMenuLinks] = useState([]);
  const location = useLocation();
  const isAdmin = permissions?.includes("admin");

  const topLevelRoute = location.pathname.split("/")[1];

  useEffect(() => {
    setMenuLinks(filter(subMenus[topLevelRoute], (link) => !isEmpty(link)));
  }, [topLevelRoute, subMenus]);

  return { isAdmin, subMenuLinks, topLevelRoute };
};

export const useProjectListOptions = () => {
  const projects = useSelector(selectProjectList);

  // memoize project list options
  const projectListOptions = useMemo(() => mapProjectOptions(projects), [projects]);

  return projectListOptions;
};

export const useSlackChannels = () => {
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectAccountData);
  const { data } = useQuery(GET_SLACK_CHANNELS, {
    variables: { accountId: currentAccount?.id },
    skip: !currentAccount?.id,
    fetchPolicy: "no-cache"
  });
  const slackChannels = data?.slackChannels;
  useEffect(() => {
    dispatch(saveSlackChannels(slackChannels ?? []));
  }, [dispatch, slackChannels]);

  return slackChannels;
};

export const useMSTeamsChannels = () => {
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectAccountData);
  const { data } = useQuery(GET_MS_TEAMS_CHANNELS, {
    variables: { accountId: currentAccount?.id },
    skip: !currentAccount?.id,
    fetchPolicy: "no-cache"
  });
  const msTeamsChannels = data?.msTeamsChannels;
  useEffect(() => {
    dispatch(saveMSTeamsChannels(msTeamsChannels ?? []));
  }, [dispatch, msTeamsChannels]);

  return msTeamsChannels;
};

export const useIntegrations = () => {
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectAccountData);
  const currentProject = useSelector(selectProjectData);
  const { data, refetch, loading } = useQuery(GET_INTEGRATIONS, {
    variables: { accountId: currentAccount?.id, projectId: currentProject?.id },
    skip: !currentAccount?.id || !currentAccount?.id,
    fetchPolicy: "no-cache"
  });

  const integrations = data?.integrations;
  useEffect(() => {
    dispatch(saveIntegrations(integrations || []));
  }, [dispatch, integrations]);

  return { integrations, refetch, loading };
};

export const useOperators = () => {
  const dispatch = useDispatch();
  const { data } = useQuery(GET_OPERATORS, {
    fetchPolicy: "no-cache"
  });
  const operators = data?.getOperators;
  useEffect(() => {
    dispatch(saveOperators(operators || []));
  }, [dispatch, operators]);

  return operators;
};

export const useLoggedInUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { data, loading, refetch } = useQuery(GET_ME, {
    fetchPolicy: "no-cache"
  });
  useEffect(() => {
    refetch();
  }, [location.pathname, refetch]);

  useEffect(() => {
    if (!data && !loading) {
      dispatch(baseLogout());
      return;
    }
    if (data?.me) {
      dispatch(setBaseUserData(data?.me));
    }
  }, [dispatch, data, loading]);
  return data?.me;
};

export const useCheckActionLimit = () => {
  const [checkTimeFrame] = useLazyQuery(CHECK_ACTION_WITHIN_TIME_LIMIT, { fetchPolicy: "no-cache" });

  const cb = useCallback(async (customActionId, userId) => {
    const withinTimeFrame = await checkTimeFrame({ variables: { customActionId, userId } });

    return withinTimeFrame?.data?.checkIsActionWithinTimeLimit ?? false;
  }, []);

  return cb;
};
