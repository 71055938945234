import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation createProject($data: ProjectCreateInput!) {
    createProject(data: $data) {
      message
      success
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($id: String!) {
    deleteProject(id: $id) {
      message
      success
    }
  }
`;

export const EDIT_PROJECT = gql`
  mutation editProject($data: ProjectEditInput!) {
    editProject(data: $data) {
      message
      success
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjects($accountId: String!) {
    getProjects(accountId: $accountId) {
      id
      name
      accountId
      headerLogo
    }
  }
`;

export const UPDATE_PROJECT_HEADER_LOGO = gql`
  mutation updateProjectHeaderLogo($data: UpdateProjectLogoInput!) {
    updateProjectHeaderLogo(data: $data) {
      success
      message
    }
  }
`;
