import { useEffect } from "react";
import { v4 } from "uuid";
import { partition, forEach, isNil } from "lodash";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";

export const newRuleBoilerPlate = (projectData, [attr1, attr2], [, equalsOperator]) => ({
  id: v4(),
  active: true,
  projectId: projectData?.id,
  name: "",
  ruleConditions: [
    {
      id: v4(),
      attributeId: attr1.id,
      logicalOperator: "",
      value: "",
      isLeftBracketActive: true,
      isRightBracketActive: true,
      type: "WHEN",
      operatorId: "",
      index: 0
    },
    {
      id: v4(),
      attributeId: attr2.id,
      logicalOperator: "",
      value: "",
      isLeftBracketActive: false,
      isRightBracketActive: false,
      type: "THEN",
      operatorId: equalsOperator.id,
      index: 1
    }
  ]
});

export const ruleConditionBoilerPlate = (attributes) => {
  const [attr] = attributes;

  return {
    id: v4(),
    attributeId: attributes[0].id,
    type: "WHEN",
    isLeftBracketActive: false,
    isRightBracketActive: false,
    logicalOperator: null,
    operatorId: "",
    value: attr.type === "WHOLE_NUMBER" ? "0" : attr.attributeValues[0]?.id,
    valueType: "VALUE",
    actor: "SELF"
  };
};

export const shouldShowRule = (rule) => {
  const [whenConditions, thenConditions] = partition(rule.ruleConditions, (condition) => condition.type === "WHEN");

  return whenConditions.length > 0 && thenConditions.length === 1;
};

export const RULE_CONDITION_TYPES = {
  WHEN: "WHEN",
  THEN: "THEN"
};

export const RULE_CONDITION_VALUE_TYPES = {
  VALUE: "VALUE",
  VARIABLE: "VARIABLE",
  ACTOR_ATTRIBUTE: "ACTOR_ATTRIBUTE"
};

export const RULE_CONDITION_FIELDS = {
  VALUE: ["value"],
  VARIABLE: ["modifierId", "modifyAmount", "variable"],
  ACTOR_ATTRIBUTE: ["secondaryActor", "secondaryAttributeId"],
  BASE: ["actor", "attributeId", "operatorId", "valueType"]
};

export const THEN_CONDITION_VALUE_TYPE_OPTIONS = [
  { id: RULE_CONDITION_VALUE_TYPES.VALUE, name: "Value" },
  { id: RULE_CONDITION_VALUE_TYPES.VARIABLE, name: "Variable" }
];

export const WHEN_CONDITION_VALUE_TYPE_OPTIONS = [
  { id: RULE_CONDITION_VALUE_TYPES.VALUE, name: "Value" },
  { id: RULE_CONDITION_VALUE_TYPES.ACTOR_ATTRIBUTE, name: "Actor Attribute" }
];

// Because certain fields only apply when valueType is VALUE/VARIABLE/ACTOR_ATTRIBUTE, we need to unregister fields if they do not apply so that form validation is not trying to validate erroneous fields
export const useClearRuleConditionErrors = ({ valueType, index, unregister, selectedAttributeType }) => {
  useEffect(() => {
    const rcAccessor = `ruleConditions.${index}`;
    switch (valueType) {
      case RULE_CONDITION_VALUE_TYPES.VALUE:
        forEach([...RULE_CONDITION_FIELDS.VARIABLE, ...RULE_CONDITION_FIELDS.ACTOR_ATTRIBUTE], (field) => {
          unregister(`${rcAccessor}.${field}`);
        });
        break;
      case RULE_CONDITION_VALUE_TYPES.ACTOR_ATTRIBUTE:
        // should not exist in THEN block but just in case
        forEach([...RULE_CONDITION_FIELDS.VALUE, ...RULE_CONDITION_FIELDS.VARIABLE], (field) => {
          unregister(`${rcAccessor}.${field}`);
        });
        break;
      case RULE_CONDITION_VALUE_TYPES.VARIABLE:
        // should not exist in WHEN block but just in case
        forEach([...RULE_CONDITION_FIELDS.VALUE, ...RULE_CONDITION_FIELDS.ACTOR_ATTRIBUTE], (field) => {
          unregister(`${rcAccessor}.${field}`);
        });
        break;
      default:
        break;
    }
  }, [valueType, selectedAttributeType, index, unregister]);
};

export const validateThenConditionValue = (value, selectedAttribute) => {
  if (selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER) {
    // ERROR WAS BEING SET ON THE PROPERTY BASED ON VALUE_LIST AND NOT ALLOWED TO RESET
    const isPositiveInteger = Number.isInteger(Number.parseFloat(value)) && value >= 0;
    if (!isPositiveInteger || isNil(value)) {
      return "Must be an integer >= 0";
    }
  } else if (selectedAttribute?.type === ATTRIBUTES_TYPES.VALUE_LIST) {
    return !!value || "Required";
  } else {
    return null;
  }
};
