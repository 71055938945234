import React from "react";

function Bracket({ type, formRegister, index, active }) {
  const lookup = {
    LEFT: {
      display: "(",
      name: `ruleConditions.${index}.isLeftBracketActive`,
    },
    RIGHT: {
      display: ")",
      name: `ruleConditions.${index}.isRightBracketActive`,
    },
  };
  const [isChecked, setIsChecked] = React.useState(active);
  const toggle = () => {
    setIsChecked(!isChecked);
  };
  const styles = {
    backgroundColor: isChecked ? "#e3f8e9" : "white",
    color: "black",
    borderRadius: "50%",
    width: "2rem",
    height: "2rem",
    cursor: "pointer",
    fontWeight: 900,
  };

  const checkBoxStyles = {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: "1px",
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: "1px",
  };

  return (
    <label>
      <input
        type="checkbox"
        onClick={toggle}
        name={lookup[type].name}
        style={checkBoxStyles}
        {...formRegister(lookup[type].name)}
        checked={isChecked}
      />
      <span
        style={styles}
        className="btn shadow d-flex align-items-center justify-content-center"
        // This element is purely decorative so
        // we hide it for screen readers
        aria-hidden="true"
      >
        {lookup[type].display}
      </span>
    </label>
  );
}

export default Bracket;
