import CustomImageDisplay from "components/CustomImage/CustomImageDisplay";
import React from "react";
import { Card } from "reactstrap";
import { TextSection } from "theme/StyledComponents";

function AttributeCard({ title, subTitle, primaryImageUrl, secondaryImageUrl, type = "LARGE" }) {
  const primaryIconSize = 75;
  const secondaryIconSize = 65;

  return (
    <>
      {type === "LARGE" ? (
        <Card className="shadow-sm" style={{ minHeight: 97 }}>
          <div className="card-body flex-row d-flex py-0">
            <div className="col-md-3 d-flex justify-content-center align-items-center">
              {!!primaryImageUrl && (
                <CustomImageDisplay height={primaryIconSize} url={primaryImageUrl} key={primaryImageUrl} />
              )}
            </div>

            <TextSection className="offset-1">
              <h5 className="card-title mb-0">{title}</h5>
              <p className="card-text">{subTitle}</p>
            </TextSection>
            <div className="col-md-2 d-flex justify-content-center align-items-center">
              {!!secondaryImageUrl && (
                <CustomImageDisplay height={secondaryIconSize} url={secondaryImageUrl} key={secondaryImageUrl} />
              )}
            </div>
          </div>
        </Card>
      ) : (
        <Card className="shadow-sm h-100">
          <div className="card-body p-1 pt-0 mx-auto">
            <CustomImageDisplay
              height={50}
              url={primaryImageUrl}
              key={primaryImageUrl}
              className="d-flex justify-content-center"
              name={title}
            />
            <p className="text-center mb-0 mt-1 h6" style={{ fontSize: "0.75rem" }}>
              {title}
            </p>
          </div>
        </Card>
      )}
    </>
  );
}

export default React.memo(AttributeCard);
