export const FEATURE_FLAGS = {
  TEAMS_INTERNAL: "TEAMS_INTERNAL",
  EMAIL_SENDING: "EMAIL_SENDING",
  SLACK_SENDING: "SLACK_SENDING",
  SSO_MUTIL_ACCOUNT_AUTHENTICATION: "SSO_MUTIL_ACCOUNT_AUTHENTICATION",
  USER_REGISTRATION: "USER_REGISTRATION",
  INTEGRATION_ACCESS: "INTEGRATION_ACCESS",
  REMOVE_USER_FROM_ACCOUNT: "REMOVE_USER_FROM_ACCOUNT",
  CONNECTIONS: "CONNECTIONS",
  BULK_LOG_ACTION: "BULK_LOG_ACTION",
  ENHANCED_RULE_CONDITIONS: "ENHANCED_RULE_CONDITIONS",
  EXPIRING_ACTIONS: "EXPIRING_ACTIONS",
  CSV_ACTION_UPLOAD: "CSV_ACTION_UPLOAD",
  DEEP_EVALUATION: "DEEP_EVALUATION",
  DETECT_CONNECTION: "DETECT_CONNECTION",
  CSV_EXPORT: "CSV_EXPORT",
  RULES_FIRE_ACTIONS: "RULES_FIRE_ACTIONS",
};

export const ACCOUNT_TIER = {
  FREEMIUM: "FREEMIUM",
  BASE: "BASE",
  ENHANCED_FEATURES: "ENHANCED_FEATURES",
  EHANCED_GAMIFICATION: "EHANCED_GAMIFICATION",
  INTEGRATIONS: "INTEGRATIONS",
};
