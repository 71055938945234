import { map } from "lodash";

import ManageProject from "components/ManageProject";
import ManageTeam from "components/ManageTeam";

export const mapProjectOptions = (projects, accountId) => {
  const newProjectTemplate = {
    accountId,
    name: "",
    new: true
  };
  return [
    ...map(projects, (p) => ({
      ...p,
      new: false,
      display: (props) => <ManageProject project={p} {...props} />
    })),
    {
      ...newProjectTemplate,
      name: "New Project...",
      id: "0",
      display: (props) => <ManageProject project={newProjectTemplate} newProject {...props} />
    }
  ];
};

export const mapTeamOptions = (teams, accountId) => {
  const newTeamTemplate = {
    accountId,
    name: ""
  };
  return [
    {
      ...{ id: "NONE", name: "No Team" },
      display: () => <span>No Team</span>
    },
    ...map(teams, (team) => ({
      ...team,
      display: (props) => <ManageTeam team={team} {...props} />
    })),
    {
      ...newTeamTemplate,
      id: "NEW",
      display: (props) => <ManageTeam team={newTeamTemplate} newTeam={true} {...props} />
    }
  ];
};

export const mapTeamOptionsSelectOnly = (teams) => {
  return [{ id: "NONE", name: "No Team" }, ...teams, { id: "ALL", name: "All" }];
};

export const getLinkStyles = (active) => {
  const paddingTop = !active && "0.5rem";
  const color = active ? "#0d73d3" : "#000";
  const transform = active ? "scale(1.2)" : "none";
  const marginRight = "2rem";

  return { paddingTop, color, transform, marginRight, fontSize: "0.9rem" };
};
