import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";

// * Components
import FileExportButton from "../../components/FileExportButton";
import Loading from "components/Loading";
import { ResponsiveVerticalContainer, StyledDataGrid, ScrollBarContainer } from "theme/StyledComponents";

// * Helpers/Hooks/Selectors
import { allUsersActionsLogColumns, getOrderedActions } from "./helper";
import { downloadCSVFile } from "../../utils/csv-export.utils";
import { useHandleError, useHandleSuccess } from "../../hooks/api.hooks";
import { selectProjectData } from "../userData/userDataSlice";

// * Queries/Mutations
import { CSV_EXPORT_INSIGHTS_TABLE } from "../../api/files";
import { GET_PROJECT_ACTION_LOG } from "api/actions";
import { FeatureFlag } from "../../hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "../../hocs/FeatureFlag/utils";

function Insights() {
  const handleError = useHandleError();
  const handleSuccess = useHandleSuccess();
  const projectData = useSelector(selectProjectData);

  let [exportInsightsTableCSV, { loading: exportInsightsTableCSVLoading }] = useMutation(CSV_EXPORT_INSIGHTS_TABLE);
  const { data: actionsLog, loading: actionsLoading } = useQuery(GET_PROJECT_ACTION_LOG, {
    variables: { projectId: projectData?.id },
    fetchPolicy: "no-cache",
    skip: !projectData?.id
  });

  // * DataGrid filter, sort, hide values initialized
  const [filter, setFilter] = useState({ columnField: null, operatorValue: null, value: null });
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [sort, setSort] = useState({ columnField: null, sortOperator: null });

  if (actionsLoading || !actionsLog) {
    return <Loading />;
  }

  const handleFileExport = async () => {
    const filterInput = {
      columnField: filter?.columnField ?? null,
      operatorValue: filter?.operatorValue ?? null,
      value: filter?.value ? (typeof filter.value === "string" ? [filter.value] : filter.value) : null
    };

    const sortInput = {
      columnField: sort?.columnField ?? null,
      sortOperator: sort?.sortOperator ?? null
    };

    const tableStateInput = { filter: filterInput, sort: sortInput, hiddenColumns: hiddenColumns };

    const res = await exportInsightsTableCSV({
      variables: {
        projectId: projectData.id,
        exportStateInput: tableStateInput
      }
    });
    if (!res?.data?.exportInsightsTableCSV?.success) {
      handleError(res.data.exportInsightsTableCSV.message);
    } else {
      const success = downloadCSVFile({
        url: res.data.exportInsightsTableCSV.url,
        fileName: res.data.exportInsightsTableCSV.fileName
      });
      if (success) {
        handleSuccess("File download successful.");
      } else {
        handleError(res.data.exportInsightsTableCSV.message);
      }
    }
  };

  const onFilterChange = (data) => {
    setFilter({
      columnField: data?.items[0]?.columnField ?? null,
      operatorValue: data?.items[0]?.operatorValue ?? null,
      value: data?.items[0]?.value ?? null
    });
  };

  const onSortChange = (data) => {
    setSort({
      columnField: data[0]?.field,
      sortOperator: data[0]?.sort
    });
  };

  const onColumnVisibilityChange = (data) => {
    const hidden = [];
    for (const col in data) {
      if (!data[col]) {
        hidden.push(col);
      }
    }
    setHiddenColumns(hidden);
  };

  return (
    <>
      <h5 className="text-center mb-0 d-flex w-100" style={{ maxHeight: "2rem" }}>
        <div className="m-auto ps-3">Action & Auto Update Log</div>
        <FeatureFlag featureName={FEATURE_FLAGS.CSV_EXPORT}>
          <FileExportButton
            loading={exportInsightsTableCSVLoading}
            handleFileExport={handleFileExport}
            tooltipText="Export Table Data CSV"
          />
        </FeatureFlag>
      </h5>
      <ScrollBarContainer>
        <ResponsiveVerticalContainer style={{ minHeight: "32rem" }}>
          <StyledDataGrid
            className="mb-5"
            rows={getOrderedActions(actionsLog.projectActionLog || [])}
            columns={allUsersActionsLogColumns}
            pageSize={10}
            rowsPerPage={[10]}
            rowsPerPageOptions={[10]}
            onFilterModelChange={onFilterChange}
            onSortModelChange={onSortChange}
            onColumnVisibilityModelChange={onColumnVisibilityChange}
          />
        </ResponsiveVerticalContainer>
      </ScrollBarContainer>
    </>
  );
}

export default Insights;
