import styled from "styled-components";
import { Controller } from "react-hook-form";

export const StyledController = styled(Controller).attrs({
  className: "select form-select form-select-sm",
  ariaLabel: ".form-select-sm example"
})`
  background-color: transparent;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  flex-direction: column;
`;
