// * downloads a CSV file by attaching an "a" tag and targeting the file
export const downloadCSVFile = ({ url, fileName }) => {
  try {
    const anchorElement = document.createElement("a");
    anchorElement.download = fileName; // * does not redirect when clicked (<a href=url download>fileName</a>)
    anchorElement.href = url;
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    anchorElement.dispatchEvent(clickEvent);
    anchorElement.remove();
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
