import React from "react";
import { useMutation } from "@apollo/client";

import { DELETE_PROJECT } from "api/projects";
import DeleteRow from "./DeleteRow/DeleteRow";
import BasicModal from "./BasicModal";
import ProjectForm from "./Forms/ProjectForm";
import { useSubmit } from "hooks/form.hooks";
import messages from "api/messages";

const NewProjectLabel = ({ handleClick }) => {
  return (
    <div data-testid="NEW_PROJECT" onClick={handleClick} className="d-flex w-100 justify-content-between">
      <span>New Project...</span>
    </div>
  );
};

const ManageProject = ({ project, newProject = false, handleSuccess, isAdmin = false }) => {
  const [showModal, setShowModal] = React.useState(false);
  const toggle = React.useCallback(() => {
    setShowModal(!showModal);
  }, [setShowModal, showModal]);
  // TODO: ensure we're passing the correct projectId
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    variables: {
      id: project?.id
    },
    skip: !project?.id
  });

  const handleDelete = useSubmit({
    mutation: deleteProject,
    dataPath: DELETE_PROJECT.definitions[0].name.value,
    useVariables: true,
    onSuccess: handleSuccess
  });

  return (
    <>
      {showModal && (
        <BasicModal testId="Project">
          <ProjectForm project={project} edit={!newProject} handleClearForm={toggle} handleSuccess={handleSuccess} />
        </BasicModal>
      )}
      {newProject && isAdmin ? (
        <NewProjectLabel handleClick={toggle} />
      ) : (
        <div className="d-flex w-100 justify-content-between">
          <span
            style={{
              whiteSpace: "pre",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "12rem"
            }}
          >
            {project?.name}
          </span>
          {isAdmin && (
            <span
              style={{ width: "2.5rem", cursor: "pointer", marginLeft: "1rem" }}
              className="edit-delete text-end d-flex align-content-center justify-content-between"
            >
              <span onClick={toggle}>
                <i
                  className="fa fa-edit"
                  style={{
                    color: "green",
                    fontSize: "16px"
                  }}
                />
              </span>
              <span data-testid={`DELETE_PROJECT-${project?.name}`}>
                <DeleteRow
                  confirmText={messages.CONFIRM_DELETE_PROJECT}
                  handleDelete={handleDelete}
                  shouldUseModal
                  icon
                />
              </span>
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(ManageProject);
