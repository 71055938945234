import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: { authToken: null, enablePassphrase: false, userData: null, redirectLoading: null },
  reducers: {
    saveAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    saveUserData: (state, action) => {
      state.userData = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setEnablePassphrase: (state, action) => {
      state.enablePassphrase = action.payload;
    },
    setRedirectLoading: (state, action) => {
      state.redirectLoading = action.payload;
    },
  },
});

export const { saveAuthToken, setEnablePassphrase, saveUserData, setRedirectLoading } = loginSlice.actions;

export default loginSlice.reducer;
