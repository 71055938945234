import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";

import CustomCheckbox from "components/CustomCheckbox";
import { SUBMIT_ACTION } from "api/actions";
import { useSubmit } from "hooks/form.hooks";

export function StatefulActionCheckBox({ customAction, checked, userId, disabled, onSuccess }) {
  // Initialize form
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm({
    defaultValues: { statefulAction: checked },
    mode: "onChange"
  });

  const [submitActionMutation] = useMutation(SUBMIT_ACTION);

  // Watch the value of 'statefulAction' field
  const statefulActionEnabled = watch("statefulAction");

  // Reset the form when 'checked' prop changes
  useEffect(() => {
    reset({ statefulAction: checked });
  }, [checked, reset]);

  const submitAction = useSubmit({
    mutation: submitActionMutation,
    variables: { data: { actionDate: new Date(), customActionId: customAction.id, approvalDetails: "", userId } },
    useVariables: true,
    dataPath: SUBMIT_ACTION.definitions[0].name.value,
    useGlobalLoading: true,
    onSuccess: () => {
      reset({ statefulAction: statefulActionEnabled });
      onSuccess();
    }
  });

  // Submit the form when 'statefulAction' field changes
  const submitForm = useCallback(() => {
    if (isDirty && !disabled) {
      handleSubmit(submitAction)();
    }
  }, [handleSubmit, isDirty, submitAction, disabled]);

  useEffect(submitForm, [statefulActionEnabled]);

  return (
    <CustomCheckbox
      formRegister={register}
      checked={checked}
      name="statefulAction"
      disabled={disabled}
      testId={`statefulActionToggle-${customAction.name}`}
    />
  );
}

StatefulActionCheckBox.propTypes = {
  customAction: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  checked: PropTypes.bool,
  userId: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func
};

StatefulActionCheckBox.defaultProps = {
  customAction: {},
  checked: false,
  userId: "",
  disabled: false,
  onSuccess: () => {}
};
