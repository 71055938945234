import axios from "axios";
import endpoint from "./endpoint";

async function createRequest({ reqHeaders, params, authToken }) {
  return axios.create({
    baseURL: endpoint.SERVER_LOCAL,
    responseType: "json",
    crossdomain: true,
    withCredentials: true,
    credentials: "same-origin",
    headers: {
      "Content-Type": reqHeaders?.["Content-Type"] || "application/json",
      Accept: "application/json",
      Authorization: authToken,
      ...reqHeaders,
    },
    params,
  });
}

export const handleCatchBlock = (error) => {
  console.log("Something went wrong fetching api: ", error);
};

export default async function apiHandler({
  url,
  method,
  headers: reqHeaders,
  data: jsonData,
  params,
  authToken,
  responseType = "json",
}) {
  try {
    const request = await createRequest({ reqHeaders, params, authToken });
    let result = [];
    switch (method) {
      case "POST":
        result = await request.post(url, jsonData);
        break;

      case "DELETE":
        result = await request.delete(url);
        break;

      default:
        result = await request.get(url, {
          responseType,
        });
    }
    const { data, headers, status } = result;
    return { data, headers, status };
  } catch (error) {
    handleCatchBlock(error);
    return error?.response;
  }
}
