import React from "react";

function Loading({ divHeight = "70vh" }) {
  return (
    <div className="d-flex" style={{ height: divHeight }}>
      <div className="spinner-grow text-success mx-auto my-auto" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export const GlobalLoading = () => (
  <div style={{ position: "relative" }}>
    <div
      style={{
        position: "absolute",
        top: "100%",
        left: "50%",
        zIndex: 1500
      }}
    >
      <Loading />
    </div>
  </div>
);

export default Loading;
