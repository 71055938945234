// * Filters users on input search term (not case or space sensitive)
export const meetsUsersFilter = (user, searchTerm) => {
  const formattedSearchTerm = searchTerm?.replace(/\s/g, "").toLowerCase() ?? "";

  // * case: no fullName in user object but still needs to match all of searchTerm (e.g. first:"m2" last:"b" -> "m2b")
  const combinedUserNames = `${user.firstName ?? ""}${user.lastName ?? ""}`;

  // * removes spacing from user object data as well (e.g. last name: Van Gogh -> vangogh)
  const filter =
    user?.firstName.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
    user?.lastName.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
    combinedUserNames.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
    user?.email.toLowerCase().includes(formattedSearchTerm) ||
    user?.fullName?.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
    user?.selected;

  return filter;
};
