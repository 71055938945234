import { combineReducers } from "redux";
import loginReducer from "features/login/loginSlice";
import ActionReducer from "./actions.slice";
import AttributesDataReducer from "features/Attributes/AttributesSlice";
import userDataReducer from "features/userData/userDataSlice";
import RulesReducer from "../features/Rules/RulesSlice";
import CommonReducer from "features/Common/CommonSlice";
import OperatorsReducer from "./operators.slice";
import IntegrationsReducer from "./integrations.slice";
import ProjectReducer from "./project.slice";
import { createAction } from "@reduxjs/toolkit";

const combinedReducer = combineReducers({
  /**Add more reducers here*/
  login: loginReducer,
  actions: ActionReducer,
  attributes: AttributesDataReducer,
  userData: userDataReducer,
  rulesData: RulesReducer,
  operatorsData: OperatorsReducer,
  commonState: CommonReducer,
  integrations: IntegrationsReducer,
  projectData: ProjectReducer,
});

export const baseLogout = createAction("LOGOUT");

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    localStorage.removeItem("persist:root");
    state = {};
    window.location.assign("/login");
  }
  return combinedReducer(state, action);
};

export default rootReducer;
