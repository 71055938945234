import { gql } from "@apollo/client";

export const GET_OPERATORS = gql`
  query GetOperators {
    getOperators {
      id
      displayValue
      value
      type
      active
    }
  }
`;
