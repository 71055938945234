import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { toLower, map } from "lodash";
import Joi from "joi";

export const EMAIL_STRING_SPLIT = /[\s;,]/;

const emailValidator = (text, callback) => {
  let tmp = text.split(EMAIL_STRING_SPLIT);

  tmp.forEach((item, i) => {
    const word = item.trim();
    const schema = Joi.string().email({ tlds: { allow: false } });
    if (schema.validate(word).error) {
      const leadingSpaces = item.search(/\S/);
      if (i === 0) {
        callback(leadingSpaces, leadingSpaces + word.length);
      } else {
        const index = tmp.slice(0, i).join(",").length + leadingSpaces + 1;
        callback(index, index + word.length);
      }
    }
  });
};

const userExistsValidator = (organizationEmails) => (text, callback) => {
  let tmp = text.split(EMAIL_STRING_SPLIT);
  const schema = Joi.string().email({ tlds: { allow: false } });
  for (let item of tmp) {
    const word = item.trim();
    if (!schema.validate(word).error && map(organizationEmails, toLower).includes(toLower(word))) {
      let index = text.indexOf(word);
      callback(index, index + word.length);
    }
  }
};

const userDoesNotExistValidator = (organizationEmails) => (text, callback) => {
  let tmp = text.split(EMAIL_STRING_SPLIT);
  const schema = Joi.string().email({ tlds: { allow: false } });
  for (let item of tmp) {
    const word = item.trim();
    if (!schema.validate(word).error && !map(organizationEmails, toLower).includes(toLower(word))) {
      let index = text.indexOf(word);
      callback(index, index + word.length);
    }
  }
};
const lengthValidator =
  (min = 10, max = 72) =>
  (text, callback) => {
    let tmp = text.trim();

    if (tmp.length < min || tmp.length > max) {
      callback(0, text.length);
    }
  };

const emailDomainValidator = (emailDomain) => (text, callback) => {
  if (!emailDomain) {
    return;
  }

  let tmp = text.split(EMAIL_STRING_SPLIT);
  const schema = Joi.string().email({ tlds: { allow: false } });
  for (let item of tmp) {
    const word = item.trim();
    if (!schema.validate(word).error) {
      const domain = toLower(word.split("@")[1].trim());
      if (domain !== toLower(emailDomain)) {
        let index = text.indexOf(word);
        callback(index, index + word.length);
      }
    }
  }
};

const ToolTip = (props) => {
  return (
    <Tippy content="Invalid email address.">
      <mark className={props.className}>{props.children}</mark>
    </Tippy>
  );
};
const ToolTipEmailDomain = (props) => {
  const [className, emailDomain] = props.className.split(" ");
  return (
    <Tippy content={`Email domain should be ${emailDomain}`}>
      <mark className={className}>{props.children}</mark>
    </Tippy>
  );
};

const ToolTipUserExists = (props) => {
  return (
    <Tippy content="Participant already exists in this project.">
      <mark className={props.className}>{props.children}</mark>
    </Tippy>
  );
};

const ToolTipUserDoesNotExist = (props) => {
  return (
    <Tippy content="Participant is not part of this project yet">
      <mark className={props.className}>{props.children}</mark>
    </Tippy>
  );
};
const ToolTipLength = (props) => {
  const [className, min, max] = props.className.split(" ");

  return (
    <Tippy content={`Details must be between ${min} and ${max} characters`}>
      <mark className={className}>{props.children}</mark>
    </Tippy>
  );
};

export const highlight = (emailsInUse, emailDomain, checkUserExists = false) => [
  {
    component: ToolTip,
    highlight: emailValidator,
    className: "red"
  },
  {
    component: ToolTipEmailDomain,
    highlight: emailDomainValidator(emailDomain),
    className: `emailDomain ${emailDomain}`,
    emailDomain
  },
  {
    component: ToolTipUserExists,
    highlight: !checkUserExists ? userExistsValidator(emailsInUse) : null,
    className: "blue"
  },
  {
    component: ToolTipUserDoesNotExist,
    highlight: checkUserExists ? userDoesNotExistValidator(emailsInUse) : null,
    className: "blue"
  }
];

export const approvalDetailsHighlight = (min = 10, max = 72) => [
  {
    component: ToolTipLength,
    highlight: lengthValidator(min, max),
    className: `red ${min} ${max}`
  }
];
