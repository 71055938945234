import React, { useEffect, useState } from "react";
import { forEach, isEmpty, isNil, map } from "lodash";
import { useSelector } from "react-redux";

// *  Components
import CustomSelect from "../../../components/CustomSelect";
import { FeatureFlag } from "../../../hocs/FeatureFlag/FeatureFlag";
import { FormErrorText } from "theme/StyledComponents";
import { UncontrolledInput } from "components/CustomInput/CustomInput";

// * Hooks & Slices
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";
import { useInitialOperatorForRuleCondition, useSetConditionValue, useThenOperators } from "hooks/form.hooks";
import { useSelectedAttribute } from "../../../hooks/form.hooks";
import { selectAttributeDict, selectRuleActorOptions } from "features/Attributes/AttributesSlice";
import { selectCustomActionsDict, selectCustomActionsWithResult } from "../../../app/actions.slice";
import { selectOperatorDict } from "app/operators.slice";

// * Utils
import { ACTION_TYPES } from "../../Actions/utils";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import {
  RULE_CONDITION_TYPES,
  RULE_CONDITION_VALUE_TYPES,
  THEN_CONDITION_VALUE_TYPE_OPTIONS,
  useClearRuleConditionErrors,
  validateThenConditionValue
} from "../utils";

const ThenConditionBlock = ({ attributes, ruleCondition, index, formUtils, modifierOperators }) => {
  const [thenValueTypeOptions, setThenValueTypeOptions] = useState(THEN_CONDITION_VALUE_TYPE_OPTIONS.slice(0, 1));
  const allowableVariables = [];
  const stringifiedVariables = JSON.stringify(allowableVariables);

  const actorOptions = useSelector(selectRuleActorOptions);
  const attributeDict = useSelector(selectAttributeDict);
  const customActions = useSelector(selectCustomActionsWithResult);
  const customActionsDict = useSelector(selectCustomActionsDict);
  const operatorDict = useSelector(selectOperatorDict);

  const enhancedRuleConditionsEnabled = useFeatureFlag(FEATURE_FLAGS.ENHANCED_RULE_CONDITIONS);

  const { control, register: formRegister, setValue, getValues } = formUtils;

  const allRuleConditions = formUtils.watch("ruleConditions");
  const attributeId = formUtils.watch(`ruleConditions.${index}.attributeId`, ruleCondition?.attributeId);
  const customActionId = formUtils.watch(
    `ruleConditions.${index}.customActionId`,
    ruleCondition?.customActionId ?? ACTION_TYPES.MANUAL_ENTRY
  );
  const modifierId = formUtils.watch(
    `ruleConditions.${index}.modifierId`,
    ruleCondition?.modifierId ?? modifierOperators[0].id
  );
  const operatorId = formUtils.watch(
    `ruleConditions.${index}.operatorId`,
    ruleCondition?.operatorId ?? modifierOperators[0].id
  );
  const variable = formUtils.watch(`ruleConditions.${index}.variable`, allowableVariables[0]?.id);
  const valueType = formUtils.watch(`ruleConditions.${index}.valueType`, ruleCondition?.valueType);

  const customAction = customActionsDict[customActionId];
  const modifier = operatorDict[modifierId];

  const selectedAttribute = useSelectedAttribute(attributeId);

  // * ADD VARIABLE OPTION TO allowableVariables as they are created
  forEach(allRuleConditions, (rc) => {
    const op = operatorDict[rc?.operatorId];
    const attr = attributeDict[rc?.attributeId];

    if (op?.type === "historical") {
      allowableVariables.push({
        id: `${rc?.attributeId} ${op?.value}`,
        name: `Self ${attr?.name} ${op?.displayValue}`
      });
    }
  });

  const canUseModifier = allowableVariables?.length && enhancedRuleConditionsEnabled;

  useEffect(() => {
    setValue(`ruleConditions.${index}.customActionId`, ruleCondition?.customActionId ?? ACTION_TYPES.MANUAL_ENTRY);
  }, [ruleCondition?.customActionId]);

  // * Sets the variable of the then condition to the allowed variable for automatic form validation
  useEffect(() => {
    if (allowableVariables?.length === 1 && variable !== allowableVariables[0].id) {
      setValue(`ruleConditions.${index}.variable`, allowableVariables[0].id, {
        shouldTouch: false,
        shouldDirty: false
      });
    }
  }, [variable, allowableVariables]);

  useEffect(() => {
    if (customAction) {
      const accessor = `ruleConditions.${index}`;
      const rc = formUtils.getValues(accessor);
      setValue(accessor, {
        ...rc,
        valueType: RULE_CONDITION_VALUE_TYPES.VALUE,
        attributeId: customAction?.attribute?.id,
        operatorId: customAction?.operator?.id,
        value: customAction?.value,
        modifierId: modifier?.id,
        modifyAmount: 0,
        variable: rc?.variable,
        operator: operatorDict[rc?.operatorId]
      });
    }
  }, [customAction]);

  useEffect(() => {
    if (canUseModifier) {
      setThenValueTypeOptions(THEN_CONDITION_VALUE_TYPE_OPTIONS);
    } else {
      setThenValueTypeOptions(THEN_CONDITION_VALUE_TYPE_OPTIONS.slice(0, 1));
      const lastIndex = allRuleConditions?.length - 1;
      setValue(`ruleConditions.${lastIndex}.valueType`, RULE_CONDITION_VALUE_TYPES.VALUE);
    }
  }, [canUseModifier, allRuleConditions?.length, setValue]);

  useClearRuleConditionErrors({
    valueType,
    index,
    unregister: formUtils.unregister,
    selectedAttributeType: selectedAttribute?.type
  });

  useEffect(() => {
    if (selectedAttribute?.type === ATTRIBUTES_TYPES.VALUE_LIST) {
      setThenValueTypeOptions(THEN_CONDITION_VALUE_TYPE_OPTIONS.slice(0, 1));
      setValue(`ruleConditions.${index}.valueType`, RULE_CONDITION_VALUE_TYPES.VALUE);
    }
    if (selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER && canUseModifier) {
      setThenValueTypeOptions(THEN_CONDITION_VALUE_TYPE_OPTIONS);
    }
  }, [selectedAttribute?.type, setValue, index, canUseModifier]);

  const operators = useThenOperators(
    selectedAttribute?.type ?? ATTRIBUTES_TYPES.WHOLE_NUMBER,
    canUseModifier,
    allRuleConditions?.[index]?.actor
  );

  useEffect(() => {
    setValue(`ruleConditions.${index}.actor`, ruleCondition?.actor ?? "SELF");
  }, []); // eslint-disable-line

  useSetConditionValue(selectedAttribute, setValue, `ruleConditions.${index}.value`, ruleCondition?.value);

  useInitialOperatorForRuleCondition(
    ruleCondition?.operatorId,
    selectedAttribute?.type,
    RULE_CONDITION_TYPES.THEN,
    setValue,
    `ruleConditions.${index}.operatorId`,
    customAction
  );

  const operatorIds = map(operators, "id");
  // * WHEN SELECTED ATTRIBUTE CHANGES, SOMETIMES AN OPERATORS IS NOT RELEVANT
  // * IN THAT CASE, SET IT TO THE FIRST ON THE LIST
  useEffect(() => {
    setTimeout(() => {
      if (!operatorIds.includes(operatorId) && !isEmpty(operatorIds)) {
        setValue(`ruleConditions.${index}.operatorId`, operatorIds[0]);
      }
    }, 1);
  }, [selectedAttribute?.id, operatorId, operatorIds]); // eslint-disable-line

  // * WHEN SELECTED ATTRIBUTE CHANGES, SOMETIMES AN OPERATORS IS NOT RELEVANT
  // * IN THAT CASE, SET IT TO THE FIRST ON THE LIST
  useEffect(() => {
    if (modifier?.value === "NONE") {
      setValue(`ruleConditions.${index}.modifierId`, modifier?.id);
      setValue(`ruleConditions.${index}.modifyAmount`, 0);
    }
  }, [modifierId, setValue, index]); // eslint-disable-line

  // * Prevents getting an api error if a variable is removed and not set correctly by the form
  useEffect(() => {
    formUtils.unregister(`ruleConditions.${index}.variable`);
  }, [stringifiedVariables]); // eslint-disable-line

  if (selectedAttribute === undefined || !operators.length > 0) {
    return null;
  }

  return (
    <>
      <div className="d-inline-flex justify-content-center gap-2 w-100 flex-wrap py-0">
        <span
          className="me-1"
          style={{
            fontWeight: 700,
            fontSize: "20px"
          }}
        >
          {ruleCondition.type}
        </span>
        <FeatureFlag featureName={FEATURE_FLAGS.CONNECTIONS}>
          <span className="mb-1">
            {actorOptions.length > 0 && (
              <CustomSelect
                name={`ruleConditions.${index}.actor`}
                formRegister={formRegister}
                options={actorOptions}
                label="ACTOR"
                displayKey="name"
                valueKey="id"
                width="8rem"
                validation={{ required: true }}
                control={control}
                defaultValue={actorOptions?.find((val) => val.id === getValues(`ruleConditions.${index}.actor`))}
              />
            )}
          </span>
        </FeatureFlag>
        <FeatureFlag featureName={FEATURE_FLAGS.RULES_FIRE_ACTIONS}>
          <span className="mb-2">
            <CustomSelect
              name={`ruleConditions.${index}.customActionId`}
              formRegister={formRegister}
              options={[
                {
                  id: ACTION_TYPES.MANUAL_ENTRY,
                  name: "Manual Entry"
                }
              ].concat(customActions)}
              label="ACTION"
              displayKey="name"
              valueKey="id"
              style={
                getValues(`ruleConditions.${index}.customActionId`) === "MANUAL_ENTRY"
                  ? { minWidth: "10rem" }
                  : { minWidth: "20em" }
              }
              validation={{ required: true }}
              control={control}
              defaultValue={
                customActions?.find((val) => val.id === getValues(`ruleConditions.${index}.customActionId`)) ?? {
                  id: ACTION_TYPES.MANUAL_ENTRY,
                  name: "Manual Entry"
                }
              }
            />
          </span>
        </FeatureFlag>
        {customActionId !== ACTION_TYPES.MANUAL_ENTRY ? (
          <span className="d-flex ms-4 align-self-center">
            {customAction?.result} {customAction?.applyStrategy && `(${customAction.applyStrategy} based action)`}
          </span>
        ) : (
          <>
            <span className="mb-2">
              <CustomSelect
                name={`ruleConditions.${index}.attributeId`}
                formRegister={formRegister}
                options={attributes}
                label="ATTRIBUTE"
                displayKey="name"
                style={{ minWidth: "10rem" }}
                validation={{ required: true }}
                control={control}
                defaultValue={attributes?.find((val) => val.id === selectedAttribute?.id)}
              />
            </span>

            <span className="mb-1">
              <CustomSelect
                name={`ruleConditions.${index}.operatorId`}
                formRegister={formRegister}
                options={operators}
                label="OPERATOR"
                displayKey="displayValue"
                maxWidth="100%"
                width="4rem"
                style={{
                  margin: "auto"
                }}
                validation={{ required: true }}
                control={control}
                defaultValue={operators?.find((val) => val.id === getValues(`ruleConditions.${index}.operatorId`))}
              />
            </span>

            <span className="mb-1">
              <CustomSelect
                name={`ruleConditions.${index}.valueType`}
                formRegister={formRegister}
                options={thenValueTypeOptions}
                label="VALUE TYPE"
                displayKey="name"
                maxWidth="100%"
                style={{
                  width: "7rem",
                  margin: "auto"
                }}
                validation={{ required: true }}
                control={control}
                defaultValue={thenValueTypeOptions?.find(
                  (val) => val.id === getValues(`ruleConditions.${index}.valueType`)
                )}
              />
            </span>
            {formUtils?.formState?.errors?.ruleConditions?.[index]?.modifyAmount && (
              <FormErrorText>{formUtils.formState.errors.ruleConditions[index].modifyAmount?.message}</FormErrorText>
            )}

            {valueType === RULE_CONDITION_VALUE_TYPES.VARIABLE && (
              <>
                <span className="mb-2" style={{ minWidth: "5rem" }}>
                  <CustomSelect
                    name={`ruleConditions.${index}.variable`}
                    formRegister={formRegister}
                    options={allowableVariables}
                    label={RULE_CONDITION_VALUE_TYPES.VARIABLE}
                    displayKey="name"
                    maxWidth="100%"
                    style={{
                      width: "12rem",
                      margin: "auto"
                    }}
                    validation={{ required: "Required" }}
                    control={control}
                    defaultValue={allowableVariables?.find(
                      (val) => val.id === getValues(`ruleConditions.${index}.variable`)
                    )}
                  />
                  {formUtils?.formState?.errors?.ruleConditions?.[index]?.variable && (
                    <FormErrorText>{formUtils.formState.errors.ruleConditions[index].variable?.message}</FormErrorText>
                  )}
                  {allowableVariables?.length > 0 && !getValues(`ruleConditions.${index}.variable`) && (
                    <div style={{ fontSize: "11px", color: "darkgrey" }}>*Select Variable</div>
                  )}
                </span>
                <span className="mb-2" style={{ minWidth: "3rem" }}>
                  <CustomSelect
                    name={`ruleConditions.${index}.modifierId`}
                    formRegister={formRegister}
                    options={modifierOperators}
                    label="MODIFIER"
                    displayKey="name"
                    maxWidth="100%"
                    width="5rem"
                    validation={{ required: "Required" }}
                    control={control}
                    defaultValue={modifierOperators?.find(
                      (val) => val.id === getValues(`ruleConditions.${index}.modifierId`)
                    )}
                  />
                  {formUtils?.formState?.errors?.ruleConditions?.[index]?.modifierId && (
                    <FormErrorText>{formUtils.formState.errors.ruleConditions[index].modifierId.message}</FormErrorText>
                  )}
                </span>
                {modifier?.value !== "NONE" && (
                  <span className="mb-2">
                    <UncontrolledInput
                      name={`ruleConditions.${index}.modifyAmount`}
                      formRegister={formRegister}
                      labelText="AMOUNT"
                      defaultValue={0}
                      style={{ maxWidth: "4rem" }}
                      width="4rem"
                      className={formUtils?.formState?.errors?.ruleConditions?.[index]?.modifyAmount ? "error" : ""}
                      type="number"
                      validation={{
                        validate: (value) => {
                          if (valueType === RULE_CONDITION_VALUE_TYPES.VARIABLE) {
                            return (
                              (!isNil(value) && Number.isInteger(Number(value)) && value >= 0) ||
                              "Must be an integer >= 0"
                            );
                          } else {
                            return null;
                          }
                        }
                      }}
                    />
                    {formUtils?.formState?.errors?.ruleConditions?.[index]?.modifyAmount && (
                      <FormErrorText>
                        {formUtils.formState.errors.ruleConditions[index].modifyAmount?.message}
                      </FormErrorText>
                    )}
                  </span>
                )}
              </>
            )}

            {valueType === RULE_CONDITION_VALUE_TYPES.VALUE && (
              <>
                {attributes.find((att) => att.id === attributeId)?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER && (
                  <span className="mb-2">
                    <UncontrolledInput
                      name={`ruleConditions.${index}.value`}
                      formRegister={formRegister}
                      labelText={RULE_CONDITION_VALUE_TYPES.VALUE}
                      defaultValue={0}
                      style={{ maxWidth: "10rem" }}
                      validation={{
                        validate: (value) => {
                          if (selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER) {
                            validateThenConditionValue(
                              getValues(`ruleConditions.[${index}].value`),
                              attributes.find((att) => att.id === attributeId)
                            );
                          }
                        }
                      }}
                      className={formUtils?.formState?.errors?.ruleConditions?.[index]?.value ? "error" : ""}
                      type="number"
                    />
                    {formUtils?.formState?.errors?.ruleConditions?.[index]?.value && (
                      <FormErrorText>{formUtils.formState.errors.ruleConditions[index].value.message}</FormErrorText>
                    )}
                  </span>
                )}
                {attributes.find((att) => att.id === attributeId)?.type === ATTRIBUTES_TYPES.VALUE_LIST && (
                  <span>
                    <CustomSelect
                      name={`ruleConditions.${index}.value`}
                      control={control}
                      formRegister={formRegister}
                      options={selectedAttribute?.attributeValues ?? []}
                      label={RULE_CONDITION_VALUE_TYPES.VALUE}
                      displayKey="name"
                      style={{ minWidth: "10rem", maxWidth: "10rem" }}
                      defaultValue={selectedAttribute?.attributeValues?.find(
                        (action) => action.id === getValues(`ruleConditions.${index}.value`)
                      )}
                    />
                    {formUtils?.formState?.errors?.ruleConditions?.[index]?.value && (
                      <FormErrorText>{formUtils.formState.errors.ruleConditions?.[index].value.message}</FormErrorText>
                    )}
                  </span>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ThenConditionBlock;
