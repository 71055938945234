import React, { memo, useCallback, useRef, useState } from "react";
import { map, isEmpty, keyBy } from "lodash";
import { v4 as uuid } from "uuid";

import { useOutsideClick } from "hooks/dom.hooks";
import { Field } from "../theme/StyledField";
import { StyledRadio, StyledRadioList } from "../theme/StyledRadio";

function CustomSelectRadio({
  defaultValue,
  name,
  options = [],
  label,
  formRegister = () => ({}),
  displayKey,
  validation = {},
  handleSuccess = () => ({}),
  isAdmin = false,
  ...rest
}) {
  const optionsDict = keyBy(options, "id");
  const elementRef = useRef();
  const [showList, setShowList] = useState(false);

  const toggleList = useCallback(() => setShowList(!showList), [setShowList, showList]);
  const closeList = useCallback(() => setShowList(false), [setShowList]);

  const successPassthrough = () => {
    handleSuccess();
    closeList();
  };

  useOutsideClick(elementRef, closeList);
  const formRegisterProps = formRegister(name, { ...validation });
  const handleRadioChange = useCallback(
    (e) => {
      const newLabel = e.target.getAttribute("data-new");
      if (newLabel === "true" || newLabel === true) {
        console.info(newLabel);
      } else {
        formRegisterProps.onChange(e);
        closeList();
      }
    },
    [formRegisterProps, closeList]
  );
  return (
    <Field {...rest} width="12rem" height="2.5rem" ref={elementRef}>
      <div style={{ position: "relative" }}>
        <div
          data-testid={`radio-target-${rest.testId}`}
          id="radio-target"
          style={{
            minWidth: "10rem",
            display: "flex",
            overflow: "hidden"
          }}
          onClick={toggleList}
        >
          <span id="value">{optionsDict[defaultValue]?.name}</span>
          <span id="icon" className="bolder fs-6">
            <i
              className="fa fa-solid fa-angle-down mt-1"
              style={
                showList
                  ? { fontSize: "20px", fontWeight: "bold", color: "#2f2f2f" }
                  : { fontSize: "20px", fontWeight: "bold", color: "#cccccc" }
              }
            />
          </span>
        </div>
        <StyledRadioList style={{ visibility: showList ? "visible" : "hidden" }}>
          {!isEmpty(options) &&
            map(options, (option) => {
              if (!isAdmin && option?.new) {
                // hides "new item" interactive row
                return null;
              }
              return (
                <span key={uuid()} style={{ display: "contents" }}>
                  <StyledRadio
                    id={`${option.id}-${rest?.id}`}
                    value={option.id}
                    {...formRegisterProps}
                    onChange={handleRadioChange}
                    data-new={option?.new || false}
                  />
                  <label htmlFor={`${option.id}-${rest?.id}`}>
                    {option[displayKey]({
                      handleSuccess: successPassthrough,
                      isAdmin
                    })}
                  </label>
                </span>
              );
            })}
        </StyledRadioList>
      </div>
      <div className="label">{label}</div>
    </Field>
  );
}

export default memo(CustomSelectRadio);
