import { createSlice } from "@reduxjs/toolkit";
import { filter, findIndex, flatMap, forEach, get, keyBy, map } from "lodash";
import { createSelector } from "reselect";
import { ATTRIBUTES_TYPES } from "./utils";

export const AttributesSlice = createSlice({
  name: "attributesData",
  initialState: {
    attributesList: [],
  },
  reducers: {
    saveAttributes: (state, action) => {
      state.attributesList = action.payload;
    },
    uploadImages: (state, action) => {
      state.shouldUploadImages = action.payload;
    },
    addAttribute: (state, action) => {
      state.attributesList = [action.payload, ...state.attributesList];
    },
    updateAttribute: (state, action) => {
      const newAttribute = action.payload;

      const idx = findIndex(state.attributesList, (a) => a.id === newAttribute.id);
      state.attributesList.splice(idx, 1, newAttribute);
    },
  },
});

export const { saveAttributes, uploadImages, addAttribute, updateAttribute } = AttributesSlice.actions;

const selectAttributeState = (state) => get(state, "attributes");

export const selectAttributes = createSelector(selectAttributeState, (state) => {
  return get(state, "attributesList", []);
});

export const selectNonConnectionAttributes = createSelector(selectAttributes, (attributes) =>
  filter(attributes, (a) => a.type !== ATTRIBUTES_TYPES.CONNECTION),
);

export const selectWholeNumberAttributes = createSelector(selectAttributes, (attributes) =>
  filter(attributes, (a) => a.type === ATTRIBUTES_TYPES.WHOLE_NUMBER),
);

export const selectConnectionAttributes = createSelector(selectAttributes, (attributes) =>
  filter(attributes, { type: ATTRIBUTES_TYPES.CONNECTION }),
);

export const selectConnections = createSelector(selectConnectionAttributes, (attributes) =>
  filter(map(attributes, "connection"), (c) => !!c),
);
export const selectConnectionSides = createSelector(selectConnections, (connection) => {
  return flatMap(connection, (c) => c.sides);
});

export const selectRuleActorOptions = createSelector(selectConnectionSides, (sides) => {
  return [{ id: "SELF", name: "Self" }].concat(sides);
});

export const selectAttributeDict = createSelector(selectAttributes, (attributes) => keyBy(attributes, "id"));

export const selectAttributeDictWithConnectionSides = createSelector(
  [selectAttributeDict, selectConnectionSides],
  (attributeDict, connectionSides) => {
    if (connectionSides.length > 0) {
      // addConnectionSides to the attributeDict
      forEach(connectionSides, (connectionSide) => {
        attributeDict[connectionSide.id] = {
          ...connectionSide,
          type: "CONNECTION",
        };
      });
    }
    return attributeDict;
  },
);

export default AttributesSlice.reducer;
