import { findIndex, find, reduce } from "lodash";

export const removeItemFromList = (collection, item, identifier = "id") => {
  const idx = findIndex(collection, (c) => c[identifier] === item[identifier]);
  return [...collection.slice(0, idx), ...collection.slice(idx + 1)];
};

export const getItemFromList = (collection, id, identifier = "id") => {
  return find(collection, (c) => c[identifier] === id);
};

export const reduceIds = (list) =>
  reduce(
    list,
    (acc, item, i) => {
      if (Object.hasOwn(item, "createdAt")) {
        acc.push({
          id: item.id,
          index: i,
        });
      }
      return acc;
    },
    [],
  );
