import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { filter, findIndex, isEmpty, map } from "lodash";
import { useFieldArray, useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";

import { GET_ORG_USERS_NON_PROJECT } from "api/users";
import UserList from "./UserList";
import UserActionTable from "./UserActionTable";
import Loading from "components/Loading";
import { selectAccountData, setSelectedUser } from "features/userData/userDataSlice";
import { selectOperators } from "app/operators.slice";
import { ResponsiveVerticalContainer, StyledCardShadow } from "theme/StyledComponents";
import { GET_ACCOUNT_TEAMS } from "api/teams";
import AttributeCard from "features/Attributes/AttributeCard";
import StatefulActionCard from "./StatefulActionCard";
import { GET_AVAILABLE_ACTIONS } from "api/actions";
import { mapTeamOptionsRadio, mapTeamOptionsSelectOnly } from "../../utils/team.utils";
import { UserConnections } from "components/UserConnections/UserConnections";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import { selectUsers } from "app/project.slice";
import { selectNonConnectionAttributes } from "features/Attributes/AttributesSlice";
import { useAttributes, useGetUserAndActions, useProjectUsers, useResetSelectedUser } from "hooks/application";
import { allowConnections } from "../../utils/roles.utils";

function Users() {
  useResetSelectedUser();
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectAccountData);
  const operators = useSelector(selectOperators);
  const users = useSelector(selectUsers);
  const { loading: usersLoading, refetch: refetchUsers } = useProjectUsers();
  const { loading: attributesLoading } = useAttributes();
  const nonConnectionAttributes = useSelector(selectNonConnectionAttributes);
  const { selectedUser, projectData } = useSelector((state) => state.userData);
  const { refetch: refetchUser } = useGetUserAndActions();

  useEffect(() => {
    refetchUsers();
  }, []); //eslint-disable-line

  /***************************************/
  // FORM SETUP
  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues: { users: [] }
  });
  const { fields, remove } = useFieldArray({
    control,
    name: "users"
  });
  /***************************************/

  /***************************************/
  // API REQUESTS
  const { data: orgUsers, refetch: refetchOrgUsers } = useQuery(GET_ORG_USERS_NON_PROJECT, {
    fetchPolicy: "network-only",
    variables: { projectId: projectData?.id },
    skip: !projectData?.id
  });

  const { data: availableActionResponse, refetch: refetchAvailableActions } = useQuery(GET_AVAILABLE_ACTIONS, {
    variables: { projectId: projectData?.id, userId: selectedUser?.id },
    fetchPolicy: "no-cache",
    skip: !projectData?.id || !selectedUser?.id
  });
  /***************************************/

  const statefulActions = filter(availableActionResponse?.availableActions, { applyStrategy: "STATEFUL" });

  /***************************************/
  // ACCOUNT TEAM UPDATES
  const { data: teamData, refetch: refetchTeams } = useQuery(GET_ACCOUNT_TEAMS, {
    variables: {
      accountId: currentAccount?.id
    },
    skip: !currentAccount?.id
  });

  const teamOptions = useMemo(
    () => mapTeamOptionsRadio(teamData?.accountTeams, currentAccount?.id),
    [teamData?.accountTeams, currentAccount?.id]
  );

  const teamFilterOptions = useMemo(
    () => mapTeamOptionsSelectOnly(teamData?.accountTeams ?? []),
    [teamData?.accountTeams]
  );

  const teamContext = {
    refetchTeams,
    teams: teamOptions,
    teamFilterOptions
  };
  /***************************************/

  /***************************************/
  // SELECTED_USER UPDATES

  // set null when projectId changes so that it can be set to the first user by the next hook
  useEffect(() => {
    refetchUsers().then((_) => {
      dispatch(setSelectedUser(null));
    });
  }, [dispatch, projectData?.id, currentAccount?.id, refetchUsers]);

  // set to first in list when new users
  useEffect(() => {
    if (!isEmpty(users) && !selectedUser?.id) {
      dispatch(setSelectedUser(users[0]));
    }
  }, [users, dispatch, selectedUser?.id, currentAccount?.id]);
  /***************************************/

  useEffect(() => {
    // Todo: fix be resolver to only return teams for current org
    if (currentAccount?.id) {
      setValue(
        "users",
        map(users, (u) => ({
          ...u,
          teams: filter(u?.teams, (t) => t.accountId === currentAccount?.id)
        }))
      );
    }
  }, [users, setValue, currentAccount?.id]);

  const handleApproveOrRejectSuccess = useCallback(() => {
    refetchUser();
    refetchUsers();
    refetchAvailableActions();
  }, [refetchUser, refetchAvailableActions, refetchUsers]);

  if (attributesLoading || !operators.length || usersLoading) {
    return <Loading />;
  }

  const findAndSetSelectedUser = (u) => () => {
    // TODO(mb) update to use a dict
    const idx = findIndex(fields, (field) => field.email === u.email);
    if (idx !== -1) {
      const usr = getValues()?.users?.[idx];
      if (usr?.id !== selectedUser?.id || !selectedUser?.id) dispatch(setSelectedUser({ ...usr, actions: [] }));
    }
  };

  const userName = selectedUser?.firstName || selectedUser?.email || "";
  const userDisplay = userName ? `${userName}'s` : "";
  const hasStatefulActions = statefulActions?.length > 0;

  return (
    <div className="d-flex row pt-0">
      <ResponsiveVerticalContainer className="col-md-4 mb-sm-3 pt-0">
        <UserList
          users={fields}
          orgUsers={orgUsers?.organizationUsersNonProject}
          findAndSetSelectedUser={findAndSetSelectedUser}
          projectId={projectData?.id}
          queries={{ refetchOrgUsers, refetchProjectUsers: refetchUsers }}
          parentForm={{ getValues, remove, handleSubmit }}
          teamContext={teamContext}
        />
      </ResponsiveVerticalContainer>
      <ResponsiveVerticalContainer className="col-md-8 text-center pt-1">
        <Row className="row" style={{ maxHeight: 260, marginBottom: "2.5rem" }}>
          <Col md={hasStatefulActions ? 6 : 12} sm={12} className="h-100">
            <h5 className="mb-2">{userDisplay} Attribute Values</h5>
            <StyledCardShadow className="pb-2 px-4 mt-1">
              <Row className="d-flex gap-0">
                {selectedUser?.userAttributeValues.map((upav, index) => {
                  return (
                    <Col xs={hasStatefulActions ? 12 : 6} className="mb-2" key={upav.attribute.id ?? index}>
                      <AttributeCard
                        primaryImageUrl={upav?.primaryIconUrl}
                        secondaryImageUrl={upav?.secondaryIconUrl}
                        title={upav.attribute.name}
                        subTitle={upav?.displayValue || upav?.value}
                      />
                    </Col>
                  );
                })}
                <FeatureFlag featureName={FEATURE_FLAGS.CONNECTIONS}>
                  <Col xs={hasStatefulActions ? 12 : 6}>
                    {allowConnections(selectedUser?.roles) && (
                      <UserConnections
                        sourceUserId={selectedUser?.id}
                        projectUsers={[{ id: "NONE", fullName: "None" }, ...users]}
                        incomingRequests={selectedUser?.incomingUserConnectionRequests}
                        outgoingRequests={selectedUser?.outgoingUserConnectionRequests}
                        sideAConnections={selectedUser?.userSideAConnections}
                        sideBConnections={selectedUser?.userSideBConnections}
                        handleSuccess={refetchUser}
                      />
                    )}
                  </Col>
                </FeatureFlag>
              </Row>
            </StyledCardShadow>
          </Col>
          {hasStatefulActions && (
            <Col md={6} sm={12} className="h-100">
              <h5 className="mb-2">Stateful Actions</h5>
              <StyledCardShadow className="pb-2 px-4 mt-1">
                <Row className="d-flex">
                  {map(statefulActions, (action) => (
                    <span key={action.id}>
                      <StatefulActionCard
                        action={action}
                        approveRejectHandler={handleApproveOrRejectSuccess}
                        userId={selectedUser?.id}
                      />
                    </span>
                  ))}
                </Row>
              </StyledCardShadow>
            </Col>
          )}
        </Row>

        <UserActionTable
          user={selectedUser}
          attributes={nonConnectionAttributes}
          userDisplay={userDisplay}
          handleApproveOrRejectSuccess={handleApproveOrRejectSuccess}
        />
      </ResponsiveVerticalContainer>
    </div>
  );
}

export default Users;
