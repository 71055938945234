import { createSlice } from "@reduxjs/toolkit";
import { filter, get, keyBy, map } from "lodash";
import { createSelector } from "reselect";

export const ActionsSlice = createSlice({
  name: "actions",
  initialState: {
    customActions: [],
  },
  reducers: {
    setCustomActionList: (state, action) => {
      state.customActions = action.payload;
    },
  },
});

export const { setCustomActionList } = ActionsSlice.actions;

const selectActionState = (state) => state.actions;

export const selectCustomActions = createSelector(selectActionState, (state) => {
  return get(state, "customActions", []);
});

export const selectInstanceActions = createSelector(selectCustomActions, (customActions) =>
  filter(customActions, { applyStrategy: "INSTANCE" }),
);
export const selectStatefulActions = createSelector(selectCustomActions, (customActions) =>
  filter(customActions, { applyStrategy: "STATEFUL" }),
);

export const selectCustomActionsWithResult = createSelector(selectCustomActions, (customActions) => {
  return map(customActions, (ca) => ({
    ...ca,
    name: `${ca.name}: ${ca.result} ${ca.applyStrategy === "STATEFUL" ? `(${ca.applyStrategy})` : ""}`,
  }));
});
export const selectCustomActionsDict = createSelector(selectCustomActions, (customActions) => {
  return keyBy(customActions, "id");
});

export default ActionsSlice.reducer;
