import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useMutation } from "@apollo/client";
import { useHandleError, useHandleSuccess } from "hooks/api.hooks";
import { selectUserAccountAccess } from "../userData/userDataSlice";

import { map, isEmpty, get } from "lodash";
import messages from "api/messages";
import { NEVER_LOGGED_IN } from "./helper";

// * Components
import AddUsersModal from "../../components/AddUsersModal";
import CSVActionUploadModal from "components/CSVUpload/CSVActionUploadModal";
import CustomCheckbox from "components/CustomCheckbox";
import CustomSelect from "components/CustomSelect";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import FileExportButton from "../../components/FileExportButton";
import { StyledCardShadow, StyledPillButton } from "theme/StyledComponents";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import UserCard from "./UserCard";

// * Utils
import { ALL_TEAMS, NO_TEAM } from "utils/team.utils";
import { CSV_EXPORT_PROJECT_USERS } from "api/files";
import { downloadCSVFile } from "utils/csv-export.utils";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import BulkLogActionModal from "../../components/BulkLogActionModal";
import { formatDateForDisplay } from "../../utils/date.utils";

function UserList({
  users = [],
  findAndSetSelectedUser,
  orgUsers = [],
  projectId,
  queries,
  parentForm,
  teamContext,
  accountId
}) {
  const handleError = useHandleError();
  const handleSuccess = useHandleSuccess();
  const { permissions = ["user"] } = useSelector(selectUserAccountAccess) || {};
  const { selectedUser } = useSelector((state) => state.userData);
  const [csvModalOpen, setCSVModalOpen] = useState(false);
  const [addUsersModalOpen, setAddUsersModalOpen] = useState(false);
  const [bulkLogActionModalOpen, setBulkLogActionModalOpen] = useState(false);
  const [canExport, setCanExport] = useState(false);

  const [filterListOpen, setFilterListOpen] = useState(false);
  const [dirtyForms, setDirtyForms] = useState(new Set());

  let [exportProjectUsersData, { loading: exportProjectUsersDataLoading }] = useMutation(CSV_EXPORT_PROJECT_USERS);

  useEffect(() => {
    if (permissions?.includes("admin")) {
      setCanExport(true);
    } else {
      setCanExport(false);
    }
  }, [accountId, permissions, setCanExport]);

  const { control, getValues, register, watch } = useForm({
    defaultValues: {
      status: {
        SEEN: false,
        NOT_SEEN: false,
        NEEDS_APPROVALS: false
      },
      teamId: teamContext?.initialTeamId ?? ALL_TEAMS.id,
      searchTerm: "",
      roleId: rolesFilters[0].id
    }
  });
  const teamId = watch("teamId");

  const meetsTeamFilter = useCallback(
    (user) => {
      switch (teamId) {
        case ALL_TEAMS.id:
          return true;
        case NO_TEAM.id:
          return isEmpty(user.teams);
        default:
          return get(user, "teams[0].id") === teamId;
      }
    },
    [teamId]
  );

  const [userStatusFilters, setUserStatusFilters] = useState([
    {
      name: "NOT_SEEN",
      display: "Status: Never Logged In",
      checked: getValues().NOT_SEEN
    },
    {
      name: "SEEN",
      display: "Status: Last Seen",
      checked: getValues().SEEN
    },
    {
      name: "NEEDS_APPROVALS",
      display: "Needs Approvals",
      checked: getValues().NEEDS_APPROVALS
    }
  ]);

  const roleId = watch("roleId");

  const meetsRoleFilter = (user) => {
    const selectedRole = rolesFilters[+roleId]?.value;
    if (+roleId === 1 && user.roles.length === 1) {
      return true;
    } else if (selectedRole !== "user" && user.roles.includes(selectedRole)) {
      return true;
    } else if (+roleId === 6) {
      return true;
    }
  };

  const handleClick = () => {
    setTimeout(() => {
      setUserStatusFilters([
        {
          name: "NOT_SEEN",
          display: "Status: Never Logged In",
          checked: getValues().NOT_SEEN
        },
        {
          name: "SEEN",
          display: "Status: Last Seen",
          checked: getValues().SEEN
        },
        {
          name: "NEEDS_APPROVALS",
          display: "Needs Approvals",
          checked: getValues().NEEDS_APPROVALS
        }
      ]);
    });
  };

  const toggleCSVModal = () => setCSVModalOpen(!csvModalOpen);
  const toggleAddUsersModal = () => setAddUsersModalOpen(!addUsersModalOpen);
  const toggleBulkLogActionModal = () => setBulkLogActionModalOpen(!bulkLogActionModalOpen);
  const toggleFilterList = () => setFilterListOpen(!filterListOpen);
  const searchTerm = watch("searchTerm");

  // TODO::refactor this filter. Either move to API and refetch the filtered data or clean up here.
  const meetsTextFilter = (usr) => {
    const formattedSearchTerm = searchTerm?.replace(/\s/g, "").toLowerCase() || "";
    const tmpRole = usr.roles.includes("admin") ? "admin" : "participant";
    const hasLoggedIn = !!usr.lastLoggedIn;
    const status = hasLoggedIn ? formatDateForDisplay(usr?.lastLoggedIn) : NEVER_LOGGED_IN;
    const combinedUserNames = `${usr?.firstName ?? ""}${usr?.lastName ?? ""}`;
    const result =
      usr?.firstName?.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
      usr?.lastName?.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
      combinedUserNames?.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
      usr?.email?.toLowerCase().includes(formattedSearchTerm) ||
      usr?.jobTitle?.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
      usr?.fullName?.replace(/\s/g, "").toLowerCase().includes(formattedSearchTerm) ||
      usr?.teams?.[0]?.name?.toLowerCase().includes(formattedSearchTerm) ||
      tmpRole.includes(formattedSearchTerm) ||
      status?.toLowerCase().includes(formattedSearchTerm);

    return result;
  };

  const meetsSelectionFilter = (usr) => {
    const hasLoggedIn = !!usr.lastLoggedIn;

    const { SEEN, NOT_SEEN, NEEDS_APPROVALS } = getValues();
    const showSeen = SEEN ? hasLoggedIn : true;
    const showUnseen = NOT_SEEN ? !hasLoggedIn : true;
    const showApprovals = NEEDS_APPROVALS ? usr?.needsApprovals : true;

    return showSeen && showUnseen && showApprovals;
  };

  const emailsInUse = map(users, (user) => user.email.toLowerCase());
  const { SEEN, NOT_SEEN, NEEDS_APPROVALS } = getValues();
  const activeUserStatus =
    SEEN ||
    NOT_SEEN ||
    NEEDS_APPROVALS ||
    (teamId !== ALL_TEAMS.id && teamContext.teamFilterOptions.length > 1) ||
    (roleId !== 6 && rolesFilters.length > 1);

  const handleFileExport = async () => {
    const { SEEN, NOT_SEEN, NEEDS_APPROVALS } = getValues();

    let teamFilterName = "";

    if (teamId) {
      teamFilterName = teamContext?.teams?.find((team) => team.id === teamId)?.name;
    }

    let roleFilterName = "";

    if (rolesFilters[+roleId]) {
      roleFilterName = rolesFilters[+roleId].name;
    }

    // * initialize searchTerms[] and remove falsy values
    const searchTerms = [
      searchTerm,
      SEEN ? "Last Logged In" : "",
      NOT_SEEN ? "Never Logged In" : "",
      NEEDS_APPROVALS ? "Needs Approvals" : "",
      teamFilterName,
      roleFilterName
    ].filter(Boolean);

    const filterInput = searchTerms
      ? {
          columnField: "all",
          operatorValue: "contains",
          value: searchTerms
        }
      : {};

    const res = await exportProjectUsersData({
      variables: {
        projectId,
        exportStateInput: { filter: filterInput, sort: {}, hiddenColumns: [] }
      }
    });

    if (!res?.data?.exportProjectUsersData?.success) {
      handleError(res.data.exportProjectUsersData.message);
    } else {
      const success = downloadCSVFile({
        url: res.data.exportProjectUsersData.url,
        fileName: res.data.exportProjectUsersData.fileName
      });
      if (success) {
        handleSuccess("File download successful.");
      } else {
        handleError("Unable to download CSV file.");
      }
    }
  };

  return (
    <>
      {addUsersModalOpen && (
        <AddUsersModal
          toggle={toggleAddUsersModal}
          users={orgUsers}
          emailsInUse={emailsInUse}
          projectId={projectId}
          queries={queries}
        />
      )}
      <FeatureFlag featureName={FEATURE_FLAGS.CSV_ACTION_UPLOAD}>
        {csvModalOpen && <CSVActionUploadModal toggle={toggleCSVModal} projectId={projectId} queries={queries} />}
      </FeatureFlag>
      <FeatureFlag featureName={FEATURE_FLAGS.BULK_LOG_ACTION}>
        {bulkLogActionModalOpen && (
          <BulkLogActionModal
            toggle={toggleBulkLogActionModal}
            emailsInUse={emailsInUse}
            projectId={projectId}
            queries={queries}
          />
        )}
      </FeatureFlag>
      <Prompt when={dirtyForms?.size > 0} message={messages.UNSAVED_CHANGES} />
      <FeatureFlag featureName={FEATURE_FLAGS.TEAMS_INTERNAL}>
        {teamContext.teamFilterOptions.length === 1 && (
          <p className="mb-1">Team: {teamContext.teamFilterOptions[0].name}</p>
        )}
      </FeatureFlag>
      <div className="d-flex justify-content-between gap-2 align-items-center mb-2">
        <span className="w-50">
          <UncontrolledInput formRegister={register} name="searchTerm" labelText="FILTER BY KEYWORD" />
        </span>

        <Dropdown isOpen={filterListOpen} toggle={() => toggleFilterList}>
          <DropdownToggle
            tag="div"
            className="ms-auto"
            onClick={toggleFilterList}
            data-toggle="dropdown"
            aria-expanded={filterListOpen}
          >
            <StyledPillButton
              style={{
                background: !activeUserStatus && "white",
                color: !activeUserStatus && "black"
              }}
              className="shadow ms-auto"
              data-testid="EDIT_FILTERS_BUTTON"
            >
              <span className="me-2" style={{ transform: "scale(1.5)" }}>
                <i className="fa fa-filter" />
              </span>
              {activeUserStatus && !filterListOpen ? "Edit" : filterListOpen ? "Close" : "Add"} Filter
            </StyledPillButton>
          </DropdownToggle>
          <DropdownMenu className="p-0 mt-5" style={{ minWidth: "25rem", top: "none" }}>
            <StyledCardShadow className="px-2 py-3">
              {rolesFilters.length > 1 && (
                <div className="mb-3 w-100">
                  <CustomSelect
                    formRegister={register}
                    name="roleId"
                    label="ROLE"
                    options={rolesFilters}
                    displayKey="name"
                    maxWidth="100%"
                    width="100%"
                    valueKey="id"
                    control={control}
                    defaultValue={rolesFilters.find((action) => action.id === roleId) ?? rolesFilters?.[0]}
                  />
                </div>
              )}
              <FeatureFlag featureName={FEATURE_FLAGS.TEAMS_INTERNAL}>
                {teamContext.teamFilterOptions.length > 1 && (
                  <span className="mb-5 w-100">
                    <CustomSelect
                      formRegister={register}
                      name="teamId"
                      label="TEAM"
                      options={teamContext.teamFilterOptions}
                      displayKey="name"
                      maxWidth="100%"
                      width="100%"
                      valueKey="id"
                      defaultValue={teamContext.teamFilterOptions.find((team) => team.id === teamId)}
                      control={control}
                    />
                  </span>
                )}
              </FeatureFlag>
              {userStatusFilters.map((filter) => {
                return (
                  <Card key={filter.name} className="mt-2 shadow-sm px-1 w-100">
                    <CardBody>
                      <Row>
                        <Col md={3} className="d-flex align-items-center justify-content-center">
                          <span onClick={handleClick}>
                            <CustomCheckbox
                              checked={filter?.checked ?? false}
                              name={filter.name}
                              formRegister={register}
                              testId={filter.name}
                            />
                          </span>
                        </Col>
                        <Col className="text-start align-items-center d-flex" md={9}>
                          <p className="my-auto">{filter.display}</p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                );
              })}
            </StyledCardShadow>
          </DropdownMenu>
        </Dropdown>
        <FeatureFlag featureName={FEATURE_FLAGS.CSV_EXPORT}>
          {canExport && (
            <FileExportButton
              loading={exportProjectUsersDataLoading}
              handleFileExport={handleFileExport}
              tooltipText="Export Project Users Data to CSV"
            />
          )}
        </FeatureFlag>
      </div>
      <StyledCardShadow className="py-3 mb-3" style={{ minWidth: "100%" }}>
        {!isEmpty(users) &&
          map(users, (user, i) => {
            if (meetsTextFilter(user) && meetsSelectionFilter(user) && meetsTeamFilter(user) && meetsRoleFilter(user)) {
              return (
                <li
                  className="mb-2"
                  key={user.email}
                  onClick={findAndSetSelectedUser(user)}
                  style={{ minWidth: "267px" }}
                >
                  <UserCard
                    isSelected={selectedUser?.email === user.email}
                    user={user}
                    idx={i}
                    userId={parentForm.getValues()?.users?.[i]?.id}
                    parentForm={parentForm}
                    queries={queries}
                    setDirtyForms={setDirtyForms}
                    teamContext={teamContext}
                  />
                </li>
              );
            }
          })}
      </StyledCardShadow>
      {!!queries.refetchOrgUsers && (
        <div className="d-flex flex-column">
          <div style={{ display: "flex" }}>
            <StyledPillButton onClick={toggleAddUsersModal} className="mx-auto">
              <i className="fa fa-plus" />
              &nbsp; Add Users
            </StyledPillButton>
            <StyledPillButton onClick={toggleBulkLogActionModal} className="mx-auto">
              <i className="fa fa-plus" />
              &nbsp; Bulk Log Action
            </StyledPillButton>
          </div>
          <StyledPillButton onClick={toggleCSVModal} className="mx-auto mt-2">
            <i className="fa fa-plus" />
            &nbsp; CSV Action Upload
          </StyledPillButton>
        </div>
      )}
    </>
  );
}

export default UserList;

const rolesFilters = [
  {
    name: "All",
    id: 6
  },
  {
    name: "Participant",
    id: 1,
    value: "user"
  },
  {
    name: "Admin",
    id: 2,
    value: "admin"
  },
  {
    name: "Team Observer",
    id: 3,
    value: "team_observer"
  },
  {
    name: "Observed Only",
    id: 4,
    value: "observed_only"
  },
  {
    name: "External Participant",
    id: 5,
    value: "external_participant"
  }
];
