import { createSelector, createSlice } from "@reduxjs/toolkit";
import { map } from "lodash";

export const ProjectSlice = createSlice({
  name: "projectData",
  initialState: {
    users: []
  },
  reducers: {
    saveUsers: (state, action) => {
      state.users = action.payload;
    }
  }
});

export const { saveUsers } = ProjectSlice.actions;

const baseState = (state) => state.projectData;

export const selectUsers = createSelector(baseState, ({ users }) => users);

export default ProjectSlice.reducer;
