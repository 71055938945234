import React from "react";
import { isEmpty } from "lodash";
function CustomActionTextDisplay({ action }) {
  if (isEmpty(action)) {
    return null;
  }
  return (
    <div className="d-flex justify-content-around ms-2 me-4" style={{ maxWidth: "35%", width: "100%" }}>
      <span>{action.result}</span>
    </div>
  );
}

export default CustomActionTextDisplay;
