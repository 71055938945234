import { selectAccountData, selectProjectList, setProjects } from "features/userData/userDataSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { isEqual } from "lodash";
import { GET_PROJECTS } from "api/projects";

export const useProjects = () => {
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectAccountData);
  const projectList = useSelector(selectProjectList);

  const { data, loading, refetch } = useQuery(GET_PROJECTS, {
    variables: { accountId: currentAccount?.id },
    skip: !currentAccount?.id
  });

  const stateAndApiEqual = isEqual(JSON.stringify(projectList), JSON.stringify(data?.getProjects));
  useEffect(() => {
    if (!stateAndApiEqual && Array.isArray(data?.getProjects)) {
      console.count("setting projects");
      dispatch(setProjects(data?.getProjects));
    }
  }, [dispatch, data?.getProjects]); // eslint-disable-line

  return { loading, refetch };
};
