import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";

import { ApproveOrReject } from "./UserActionTable";
import { FormErrorText, TextSection } from "theme/StyledComponents";
import { StatefulActionCheckBox } from "components/Forms/CustomInputs/StatefulActionCheckbox";

const StatefulActionCard = ({ action, approveRejectHandler, userId }) => {
  return (
    <Col xs={12} className="mb-1" key={action.id}>
      <Card className="shadow-sm" style={{ minHeight: "fit-content" }}>
        <CardBody className="py-2 px-3">
          <div className="d-flex justify-content-start align-items-center disabled">
            <StatefulActionCheckBox
              customAction={{ id: action.id, name: action.name }}
              checked={action?.statefulActionEnabled}
              userId={userId}
              disabled={action?.lastInstancePending?.pending}
              onSuccess={approveRejectHandler}
            />
            <TextSection className="offset-1">
              <h5 className="card-title mb-0">{action.name}</h5>
              <p className="card-text">{action?.result}</p>
            </TextSection>
          </div>

          {action?.lastInstancePending?.pending && (
            <div
              className="d-flex justify-content-start align-items-center gap-3"
              data-testid="APPROVE-STATEFUL-ACTION"
            >
              <FormErrorText className="m-0">Enable (Awaiting approval)</FormErrorText>
              <ApproveOrReject
                actionId={action.lastInstancePending.actionId}
                limit={action.limit}
                onSuccess={approveRejectHandler}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

StatefulActionCard.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    result: PropTypes.string,
    statefulActionEnabled: PropTypes.bool,
    lastInstancePending: PropTypes.shape({
      pending: PropTypes.bool,
      actionId: PropTypes.string
    }),
    limit: PropTypes.string
  }).isRequired,
  approveRejectHandler: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired
};

export default StatefulActionCard;
