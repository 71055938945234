import React, { useState, memo } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { ActionFormButton } from "theme/StyledComponents";

const DeleteRow = ({
  handleDelete,
  confirmText,
  shouldUseModal = true,
  icon = false,
  iconSize = "18px",
  testId = "",
  roundButton = false
}) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const toggle = () => setShowConfirmDelete(!showConfirmDelete);

  const confirmDelete = (e) => {
    e.stopPropagation();
    if (shouldUseModal) {
      setShowConfirmDelete(true);
    } else {
      handleDelete();
    }
  };

  return (
    <>
      {icon ? (
        <span onClick={confirmDelete} data-testid={testId}>
          <i className={"fa fa-times"} style={{ color: "red", fontSize: iconSize, textAlign: "center" }} />
        </span>
      ) : (
        <ActionFormButton
          type="button"
          className={roundButton ? "btn shadow border-0 btn-outline-danger" : "btn border-0 shadow btn-outline-danger"}
          onClick={confirmDelete}
          buttonType="delete"
          data-testid={testId}
          id="delete"
        >
          <i className="fa fa-times" />
        </ActionFormButton>
      )}

      <Modal isOpen={showConfirmDelete} autoFocus={showConfirmDelete}>
        <ModalBody className="text-center">
          {!!confirmText ? confirmText : "Are you sure you want to delete this row?"}
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center", borderTop: "none" }}>
          <Button color="danger" onClick={toggle}>
            No
          </Button>
          <Button
            data-testid={testId}
            color="success"
            onClick={() => {
              handleDelete();
              toggle();
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default memo(DeleteRow);
