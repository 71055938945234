import { DateTime } from "luxon";

export const calculateExpirationDate = (period, instance, date) => {
  if (!period) {
    return null;
  }

  if (!instance) {
    return null;
  }
  const setPeriod = `${period?.toLowerCase()}s`;

  const calculatedDate = date.plus({ [setPeriod]: +instance });

  if (calculatedDate.isValid) {
    return calculatedDate.toJSDate();
  } else {
    return null;
  }
};

export const checkDateExpired = (expirationDate) => {
  return expirationDate ? DateTime.now().toJSDate() >= expirationDate : false;
};

export const formatDateForDisplay = (date) => {
  if (!date) {
    return "";
  }
  // * From some date picker components we do not capture the full timezone so if no tz on date
  // * send simpleFormat else format for local timezone offset
  const localDate = new Date(date);
  if (localDate.toISOString().split("T")[1] === "00:00:00.000Z") {
    return localDate.toISOString().split("T")[0];
  } else {
    const offset = localDate.getTimezoneOffset();
    const offsetDate = new Date(localDate.getTime() - offset * 60 * 1000);
    const formatDate = offsetDate.toISOString().split("T")[0];
    return formatDate;
  }
};
