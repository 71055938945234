import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { ActionFormButton, FormErrorText } from "theme/StyledComponents";
import { useSubmit } from "../../hooks/form.hooks";
import { CREATE_PROJECT, EDIT_PROJECT } from "../../api/projects";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { useSelector } from "react-redux";
import { selectAccountData } from "features/userData/userDataSlice";

function ProjectForm({ handleClearForm = () => ({}), handleSuccess = () => ({}), project = {}, edit = false }) {
  const currentAccount = useSelector(selectAccountData);
  const { register, reset, handleSubmit, formState, watch } = useForm({
    defaultValues: project,
    mode: "onChange"
  });

  const projectName = watch("name");

  const [editProject] = useMutation(EDIT_PROJECT, {
    variables: {
      data: { name: projectName, id: project?.id }
    }
  });

  const [createProject] = useMutation(CREATE_PROJECT, {
    variables: {
      data: {
        name: projectName,
        accountId: currentAccount?.id
      }
    }
  });

  const onSubmit = useSubmit({
    mutation: edit ? editProject : createProject,
    clearForm: handleClearForm,
    useVariables: true,
    dataPath: edit ? EDIT_PROJECT.definitions[0].name.value : CREATE_PROJECT.definitions[0].name.value,
    onSuccess: () => {
      handleClearForm();
      handleSuccess();
    }
  });

  const handleReset = React.useCallback(() => reset(), [reset]);

  const projectValidation = {
    required: true,
    minLength: {
      value: 5,
      message: "Project name must be at least 5 characters"
    },
    maxLength: {
      value: 36,
      message: "Project name must be no more than 36 characters"
    }
  };

  const canSubmit = formState.isValid && formState.isDirty;

  return (
    <form className="w-100 shadow px-4 py-5" onSubmit={handleSubmit(onSubmit)} data-testid="PROJECT_FORM">
      <div className="d-flex mb-4">
        <div className="d-flex flex-column w-100 justify-content-between align-items-center">
          <div className="w-75">
            <UncontrolledInput
              testId="PROJECT_NAME"
              formRegister={register}
              name="name"
              labelText="Project Name"
              validation={projectValidation}
            />
            <FormErrorText className="mt-0">{formState.errors?.name?.message}</FormErrorText>
          </div>
          <div className="d-flex justify-content-center">
            <ActionFormButton
              data-testid="SUBMIT_PROJECT"
              type="submit"
              className="btn btn-outline-success shadow border-0 mx-3"
              disabled={!canSubmit}
            >
              <i className="fa fa-check" />
            </ActionFormButton>
            <ActionFormButton
              data-testid="UNDO_PROJECT"
              type="button"
              className="btn shadow border-0 btn-outline-primary mx-3"
              buttonType="undo"
              onClick={handleReset}
              disabled={!canSubmit}
            >
              <i className="fa fa-undo" />
            </ActionFormButton>
            <ActionFormButton
              data-testid="CANCEL_PROJECT"
              type="button"
              className="btn border-0 shadow btn-outline-danger mx-3"
              onClick={handleClearForm}
              buttonType="delete"
            >
              <i className="fa fa-times" />
            </ActionFormButton>
          </div>
        </div>
      </div>
    </form>
  );
}

export default React.memo(ProjectForm);
