import { useMemo } from "react";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import { filter, keyBy, map } from "lodash";

export const OperatorsSlice = createSlice({
  name: "operatorData",
  initialState: {
    operators: [],
  },
  reducers: {
    saveOperators: (state, action) => {
      state.operators = action.payload;
    },
  },
});

export const { saveOperators } = OperatorsSlice.actions;

const useBaseState = (state) => state.operatorsData;

export const selectOperators = createSelector(useBaseState, ({ operators }) => operators);

export const selectOperatorDict = createSelector(selectOperators, (operators) => keyBy(operators, "id"));

export const selectOperatorsByType = (types) =>
  createSelector(selectOperators, (operators) =>
    useMemo(() => filter(operators, (op) => types.includes(op.type)), [operators]),
  );

export const selectSpecificOperators = (operatorValues) =>
  createSelector(selectOperators, (operators) => {
    const operatorDict = keyBy(operators, "value");
    const ops = useMemo(() => map(operatorValues, (op) => operatorDict[op]), [operatorDict]);
    return ops;
  });

export default OperatorsSlice.reducer;
