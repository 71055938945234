import React from "react";
import { useQuery } from "@apollo/client";
import { map, filter, find } from "lodash";
import { useSelector } from "react-redux";

import DefaultAvatar from "../../assets/user-icon.png";
import ConnectionCard from "./ConnectionCard";
import { GET_CONNECTION_ATTRIBUTES } from "api/attributes";
import { selectProjectData } from "features/userData/userDataSlice";
import { allowConnections } from "../../utils/roles.utils";

export function UserConnections({
  sourceUserId,
  incomingRequests = [],
  outgoingRequests = [],
  sideAConnections = [],
  sideBConnections = [],
  projectUsers = [],
  handleSuccess = () => ({})
}) {
  const projectData = useSelector(selectProjectData);

  const { data, loading } = useQuery(GET_CONNECTION_ATTRIBUTES, {
    variables: { projectId: projectData?.id },
    skip: !projectData?.id,
    fetchPolicy: "no-cache"
  });

  const connections = data?.connectionAttributes ?? [];

  if (loading || !projectUsers?.length) {
    return null;
  }

  return (
    <>
      {map(connections, (c) => {
        if (!c) {
          return null;
        }

        const incoming = filter(incomingRequests, { connectionId: c.id });
        const outgoing = filter(outgoingRequests, { connectionId: c.id });

        const foundA = find(sideBConnections, { connectionId: c.id });
        const foundB = find(sideAConnections, { connectionId: c.id });

        const sideA = {
          targetUserSide: "A",
          initialValue: foundA?.userA?.id,
          incomingRequest: find(incoming, { targetUserSide: "B" }),
          outgoingRequest: find(outgoing, { targetUserSide: "A" }),
          primaryImageUrl: c?.sideAImage,
          title: c?.sideAName,
          handleSuccess
        };
        const sideB = {
          targetUserSide: "B",
          initialValue: foundB?.userB?.id,
          incomingRequest: find(incoming, { targetUserSide: "A" }),
          outgoingRequest: find(outgoing, { targetUserSide: "B" }),
          primaryImageUrl: c?.sideBImage,
          title: c?.sideBName,
          handleSuccess
        };

        const mappedConnectionOptionsByRole = projectUsers
          .map((user) => {
            if (allowConnections(user?.roles)) {
              return user;
            }
          })
          .filter((x) => !!x);

        const connectionOptions = map(mappedConnectionOptionsByRole, (u) => ({
          ...u,
          avatar: u.avatar || DefaultAvatar
        }));

        const sideAOptions = filter(connectionOptions, (u) => u.id !== sourceUserId && u.id !== sideB?.initialValue);
        const sideBOptions = filter(connectionOptions, (u) => u.id !== sourceUserId && u.id !== sideA?.initialValue);

        return (
          <span key={c.id}>
            <div className="col-xs-12 mb-3">
              <ConnectionCard sourceUserId={sourceUserId} connectionId={c.id} options={sideAOptions} {...sideA} />
            </div>
            <div className="col-xs-12 mb-3">
              <ConnectionCard sourceUserId={sourceUserId} connectionId={c.id} options={sideBOptions} {...sideB} />
            </div>
          </span>
        );
      })}
    </>
  );
}
