import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// * Components
import { ActionFormButton } from "theme/StyledComponents";
import CustomActionTextDisplay from "../CustomActionTextDisplay";
import CustomDatePicker from "components/CustomDatePicker";
import CustomSelect from "components/CustomSelect";
import { UncontrolledInput } from "components/CustomInput/CustomInput";

// * Hooks, Helpers, Utils, etc
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { find, omit } from "lodash";
import { selectSpecificOperators } from "../../app/operators.slice";
import { useSelectedAttributeId } from "../../hooks/form.hooks";
import { FeatureFlag } from "../../hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "../../hocs/FeatureFlag/utils";
import ActionExpirationForm from "./ActionExpirationForm";
import { DateTime } from "luxon";
import { calculateExpirationDate, checkDateExpired } from "../../utils/date.utils";

// * BulkLogActionForm Component emits valid selected or manual entry action to parent BulkLogActionModal
function BulkLogActionForm({
  resetForm,
  attributes,
  actionOptions,
  emitSelectedAction,
  emitActionIsValid,
  emitFormReset,
  emitExpired,
  emitCalculatedExpirationDate
}) {
  // * initialize component values and form values
  const [isValid, setIsValid] = useState(false);
  const [plusOperator, assignmentOperator, minusOperator] = useSelector(selectSpecificOperators(["+", "=", "-"]));
  const wholeNumberOperators = [plusOperator, minusOperator, assignmentOperator];
  const [selectedAttribute, setSelectedAttribute] = useState(attributes[0]);
  const [defaultActionValue, setDefaultActionValue] = useState(actionOptions[0]);

  const MANUAL_ENTRY = "MANUAL_ENTRY";

  // * The Form
  const {
    control,
    register,
    watch,
    reset,
    getValues,
    formState: { isDirty },
    setValue
  } = useForm({
    defaultValues: {
      actionId: MANUAL_ENTRY,
      value: 0,
      name: "",
      attributeId: attributes[0]?.id,
      attributeName: attributes[0]?.name,
      expires: false,
      expirationPeriod: null,
      expirationPeriodAmount: null,
      operatorId: plusOperator.id,
      attributeValueId: null
    }
  });

  // * initialize "watch" values
  const actionId = watch("actionId");
  const actionDate = watch("actionDate");
  const attributeId = watch("attributeId");
  const expires = watch("expires");
  const expirationPeriod = watch("expirationPeriod");
  const expirationPeriodAmount = watch("expirationPeriodAmount");
  const name = watch("name");
  const operatorId = watch("operatorId");
  const value = watch("value");
  const attributeValueId = watch("attributeValueId");

  useEffect(() => {
    setDefaultActionValue(actionOptions.find((action) => action.id === actionId));
  }, [actionId]);

  // * Reset form values and emit null selection
  const handleReset = () => {
    reset({
      actionId: actionOptions[0]?.id,
      value: 0,
      name: "",
      attributeId: attributes[0]?.id,
      expires: false,
      expirationPeriod: null,
      expirationPeriodAmount: null
    });
    emitFormReset(true);
    setIsValid(false);
  };

  // * reads resetForm value from parent Modal component's reset button
  useEffect(() => {
    if (resetForm) {
      handleReset();
    }
  }, [resetForm, handleReset]);

  const getAttributeDisplayValue = useCallback(
    (formData) => {
      if (selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER) {
        return formData.value;
      }
      const foundAttributeValue = find(selectedAttribute?.attributeValues, (av) => av.id === attributeValueId);
      return foundAttributeValue?.name;
    },
    [selectedAttribute?.type, selectedAttribute?.attributeValues, attributeValueId]
  );

  // * setIsValid property for component and emit to Modal component for validation check
  useEffect(() => {
    const valueOrAttributeValue =
      !!value || !!getAttributeDisplayValue(attributes.find((att) => att.id === attributeId));

    const baseComparison = actionId === MANUAL_ENTRY ? name?.trim() && valueOrAttributeValue : true;

    if (!expires) {
      setIsValid(baseComparison);
      emitActionIsValid(baseComparison);
    } else {
      setIsValid(expires && expirationPeriodAmount && expirationPeriod && baseComparison);
      emitActionIsValid(expires && expirationPeriodAmount && expirationPeriod && baseComparison);
    }
  }, [
    actionId,
    name,
    value,
    attributeId,
    resetForm,
    isValid,
    emitActionIsValid,
    expires,
    expirationPeriodAmount,
    expirationPeriod
  ]);

  // * Custom Hooks For Action Selection and Form Reset
  useSelectedAttributeId(attributeId, attributes, setSelectedAttribute);

  useSelectedActionToSetExpiration(
    actionOptions.find((action) => action.id === actionId),
    actionDate,
    emitCalculatedExpirationDate,
    emitExpired,
    setValue
  );

  useManualEntryActionToSetExpiration(
    expires,
    expirationPeriodAmount,
    expirationPeriod,
    actionId,
    actionDate,
    emitCalculatedExpirationDate,
    emitExpired,
    emitSelectedAction
  );

  const extractFormValuesFromAction = (action) => {
    return {
      value: action?.value,
      operatorId: action?.operator.id,
      attributeId: action?.attribute.id,
      attributeName: action?.attribute.name,
      name: `${action?.name}`,
      type: action?.type,
      customActionId: action?.id
    };
  };

  const findCorrectAttributeValue = (attribute, avId) => {
    let foundAv = attribute.attributeValues.find((av) => av.id === avId);

    if (!foundAv) {
      foundAv = attribute.attributeValues[0];
    }

    return foundAv;
  };

  // * read getValues() as formData and emit selectedAction to Modal component
  useEffect(() => {
    const formData = getValues();
    const base =
      actionId === MANUAL_ENTRY
        ? formData
        : extractFormValuesFromAction(actionOptions.find((action) => action.id === actionId));
    const foundAttribute = attributes.find((att) => att.id === attributeId);

    const emitAction = {
      ...omit(base, ["actionId", "actionDate", "attributeValueId"]),
      actionDate: formData.actionDate,
      id: actionId,
      attributeName: foundAttribute?.name,
      attributeId,
      operatorId: foundAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? operatorId : assignmentOperator?.id,
      displayValue:
        foundAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER
          ? `${value}`
          : findCorrectAttributeValue(foundAttribute, base.attributeValueId)?.name,
      value:
        foundAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER
          ? +value
          : findCorrectAttributeValue(foundAttribute, base.attributeValueId)?.id,
      expires: formData.expires ?? false,
      type: MANUAL_ENTRY, // values from the form do not have a "type" field so this is correctly set
      customActionId: actionId
    };

    emitSelectedAction({
      action: emitAction
    });
  }, [
    actionId,
    selectedAttribute,
    isValid,
    resetForm,
    name,
    value,
    emitSelectedAction,
    getValues,
    getAttributeDisplayValue
  ]);

  const handleExpirationValues = (values) => {
    if (values.expires) {
      setValue("expirationPeriod", values.period);
      setValue("expirationPeriodAmount", +values.periodAmount);
      setValue("expires", values.expires);
    } else {
      setValue("expires", false);
      emitExpired(false);
      emitCalculatedExpirationDate(null);
    }
  };

  return (
    <div>
      <form data-testid="ACTION_FORM">
        <div
          className="d-flex flex-row align-items-center gap-2"
          style={{ justifyContent: "start", grid: "initial", flexWrap: "nowrap" }}
        >
          <CustomDatePicker formRegister={register} name="actionDate" width="9rem" data-testid="BULK_LOG_DATE_PICKER" />
          <CustomSelect
            label="ACTION"
            formRegister={register}
            name="actionId"
            options={actionOptions}
            displayKey="name"
            control={control}
            width={actionId !== MANUAL_ENTRY ? "15rem" : "10rem"}
            defaultValue={defaultActionValue}
            extraWidth={true}
          />
          <strong className={actionId !== MANUAL_ENTRY ? "ms-5 me-2" : ""}>=</strong>
          {actionId === "MANUAL_ENTRY" ? (
            <>
              <UncontrolledInput
                labelText="NAME"
                formRegister={register}
                name="name"
                maxWidth="8rem"
                testId="BULK_LOG_NAME_INPUT"
                className="uncontrolledName"
              />
              <CustomSelect
                label="ATTRIBUTE"
                formRegister={register}
                name="attributeId"
                options={attributes}
                displayKey="name"
                control={control}
                defaultValue={attributes?.find((att) => att.id === attributeId)}
              />
              {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
                <CustomSelect
                  label="OPERATOR"
                  formRegister={register}
                  name="operatorId"
                  width="4rem"
                  options={wholeNumberOperators}
                  control={control}
                  defaultValue={wholeNumberOperators.find((op) => op.id === operatorId)}
                />
              ) : (
                <strong className={actionId !== MANUAL_ENTRY ? "ms-5 me-2" : ""}>=</strong>
              )}
              {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
                <UncontrolledInput
                  labelText="VALUE"
                  formRegister={register}
                  name="value"
                  maxWidth="4rem"
                  type="number"
                  testId="BULK_LOG_VALUE_INPUT"
                  className="uncontrolledValue"
                />
              ) : (
                <CustomSelect
                  label="VALUE"
                  formRegister={register}
                  name="attributeValueId"
                  displayKey="name"
                  options={selectedAttribute?.attributeValues}
                  control={control}
                  defaultValue={
                    selectedAttribute?.attributeValues.find((val) => val.id === attributeValueId) ??
                    selectedAttribute?.attributeValues[0]
                  }
                />
              )}
            </>
          ) : (
            <CustomActionTextDisplay action={actionOptions.find((action) => action.id === actionId)} />
          )}
          <ActionFormButton
            type="button"
            className="btn shadow border-0 btn-outline-primary"
            onClick={handleReset}
            buttonType="undo"
            disabled={!isDirty}
            data-testid="BULK_LOG_ACTION_FORM_RESET"
          >
            <i className="fa fa-undo" />
          </ActionFormButton>
        </div>
        <div className="mt-3 d-flex justify-content-start w-100">
          <FeatureFlag featureName={FEATURE_FLAGS.EXPIRING_ACTIONS}>
            {actionId === MANUAL_ENTRY && <ActionExpirationForm emitExpiration={handleExpirationValues} />}
          </FeatureFlag>
        </div>
      </form>
    </div>
  );
}

const useSelectedActionToSetExpiration = (
  selectedAction,
  actionDate,
  emitCalculatedExpirationDate,
  emitExpired,
  setValue
) => {
  useEffect(() => {
    if (
      selectedAction &&
      selectedAction?.id !== "MANUAL_ENTRY" &&
      selectedAction?.expirationPeriod &&
      selectedAction?.expirationPeriodAmount &&
      actionDate
    ) {
      setValue("expires", true);
      setValue("expirationPeriod", selectedAction.expirationPeriod);
      setValue("expirationPeriodAmount", +selectedAction.expirationPeriodAmount);
      setExpirationValues(
        +selectedAction.expirationPeriodAmount,
        selectedAction.expirationPeriod,
        actionDate,
        emitCalculatedExpirationDate,
        emitExpired
      );
    } else if (selectedAction && selectedAction?.id !== "MANUAL_ENTRY") {
      setValue("expires", false);
      emitExpired(false);
    }
  }, [selectedAction, setValue, actionDate, emitExpired, emitCalculatedExpirationDate]);
};

const useManualEntryActionToSetExpiration = (
  expires,
  expirationPeriodAmount,
  expirationPeriod,
  selectedAction,
  actionDate,
  emitCalculatedExpirationDate,
  emitExpired
) => {
  useEffect(() => {
    if (expirationPeriodAmount && expirationPeriod) {
      setExpirationValues(
        expirationPeriodAmount,
        expirationPeriod,
        actionDate,
        emitCalculatedExpirationDate,
        emitExpired
      );
    }
  }, [
    expires,
    expirationPeriodAmount,
    expirationPeriod,
    actionDate,
    emitExpired,
    selectedAction,
    emitCalculatedExpirationDate
  ]);
};

const setExpirationValues = (
  expirationPeriodAmount,
  expirationPeriod,
  actionDate,
  emitCalculatedExpirationDate,
  emitExpired
) => {
  const calculatedExpirationDate = calculateExpirationDate(
    expirationPeriod,
    expirationPeriodAmount,
    actionDate ? DateTime.fromSQL(actionDate) : DateTime.now()
  );
  const alreadyExpired = checkDateExpired(calculatedExpirationDate);
  emitCalculatedExpirationDate(calculatedExpirationDate);
  emitExpired(alreadyExpired);
};

export default BulkLogActionForm;
