import { gql } from "@apollo/client";

export const GET_USER_DASHBOARD = gql`
  query userDashboard($projectId: String!) {
    userDashboard(projectId: $projectId) {
      firstName
      lastName
      email
      roles
      jobTitle
      avatar
      hasSSOAccount
      incomingUserConnectionRequests {
        id
        targetUserSide
        connectionId
        sourceUser {
          id
          firstName
          lastName
          email
        }
      }
      userSideAConnections {
        connectionId
        userB {
          id
          firstName
          lastName
          email
        }
      }
      userSideBConnections {
        connectionId
        userA {
          id
          firstName
          lastName
          email
        }
      }
      outgoingUserConnectionRequests {
        targetUserSide
        connectionId
        targetUser {
          id
          firstName
          lastName
          email
        }
      }
      userAttributeValues {
        primaryIconUrl
        secondaryIconUrl
        displayValue
        value
        attribute {
          name
          id
          type
          displayLocation
        }
      }
    }
  }
`;

export const GET_USER_AND_ACTIONS = gql`
  query userAndActions($userId: String!, $projectId: String!) {
    userAndActions(userId: $userId, projectId: $projectId) {
      id
      firstName
      lastName
      fullName
      email
      roles
      roleUpdatedAt
      jobTitle
      avatar
      lastLoggedIn
      needsApprovals(projectId: $projectId)
      userAttributeValues {
        attributeId
        value
        displayValue
        primaryIconUrl
        secondaryIconUrl
        attribute {
          name
        }
      }
      actions {
        id
        name
        value
        displayValue
        calculatedValue
        createdAt
        expirationDate
        expired
        actionDate
        type
        approvalStatus
        approvalDetails
        attributeName
        dateApplied
        customActionId
        result
        customAction {
          limit
          applyStrategy
        }
        creator
        operator {
          value
        }
        attribute {
          name
          type
          id
        }
      }
      incomingUserConnectionRequests {
        id
        targetUserSide
        connectionId
        sourceUser {
          id
          firstName
          lastName
          email
        }
      }
      userSideAConnections {
        connectionId
        userB {
          id
          firstName
          lastName
          email
        }
      }
      userSideBConnections {
        connectionId
        userA {
          id
          firstName
          lastName
          email
        }
      }
      outgoingUserConnectionRequests {
        targetUserSide
        connectionId
        targetUser {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const CHANGE_PASSPHRASE = gql`
  mutation changePassphrase($data: ChangePassphraseArgs!) {
    changePassphrase(data: $data) {
      success
      message
    }
  }
`;

export const RESET_PASSPHRASE = gql`
  mutation resetPassphrase($data: ResetPassphraseArgs!) {
    resetPassphrase(data: $data) {
      success
      message
    }
  }
`;

export const GET_PROJECT_USERS = gql`
  query projectUsers($projectId: String!) {
    projectUsers(projectId: $projectId) {
      id
      firstName
      lastName
      jobTitle
      fullName
      email
      avatar
      lastLoggedIn
      roles
      roleUpdatedAt
      fullName
      needsApprovals(projectId: $projectId)
      teams {
        id
        name
        accountId
      }
      userAttributeValues {
        value
        attributeId
        displayValue
        primaryIconUrl
        secondaryIconUrl
        attribute {
          name
        }
      }
    }
  }
`;

export const GET_PROJECT_USERS_FOR_CONNECTIONS = gql`
  query projectUsers($projectId: String!) {
    projectUsers(projectId: $projectId) {
      id
      fullName
      email
      avatar
    }
  }
`;

export const GET_ORG_USERS_NON_PROJECT = gql`
  query organizationUsersNonProject($projectId: String!) {
    organizationUsersNonProject(projectId: $projectId) {
      id
      firstName
      lastName
      jobTitle
      email
      roles
    }
  }
`;

export const ADD_USERS_TO_PROJECT = gql`
  mutation addUsersToProject($userIds: [String!]!, $emails: [String!]!, $projectId: String!, $role: RoleTypeEnum!) {
    addUsersToProject(userIds: $userIds, emails: $emails, projectId: $projectId, role: $role) {
      success
      message
    }
  }
`;

export const REMOVE_USER_FROM_PROJECT = gql`
  mutation removeUserFromProject($userId: String!, $projectId: String!) {
    removeUserFromProject(userId: $userId, projectId: $projectId) {
      success
      message
    }
  }
`;

export const REMOVE_USER_FROM_ACCOUNT = gql`
  mutation removeUserFromAccount($userId: String!, $accountId: String!) {
    removeUserFromAccount(userId: $userId, accountId: $accountId) {
      success
      message
    }
  }
`;

export const SEND_PROJECT_REMINDER_EMAIL = gql`
  mutation sendProjectReminderEmail($userId: String!, $projectId: String!) {
    sendProjectReminderEmail(userId: $userId, projectId: $projectId) {
      success
      message
    }
  }
`;
export const UPDATE_USER_ACCOUNT_ROLE = gql`
  mutation updateUserAccountRole($data: UpdateUserOrgRoleInput!) {
    updateUserAccountRole(data: $data) {
      success
      message
    }
  }
`;

export const GET_ME = gql`
  query me {
    me {
      source
      email
      avatar
      userAccounts {
        roles
        accountId
        account {
          accountTier
        }
      }
    }
  }
`;

export const TRIGGER_RESET_PASSPHRASE = gql`
  mutation triggerResetPassphrase($email: String!) {
    triggerResetPassphrase(email: $email) {
      success
      message
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($data: UserProfileUpdateInput!) {
    updateUserProfile(data: $data) {
      success
      message
      data
    }
  }
`;
