import { map } from "lodash";
import ManageTeam from "components/ManageTeam";

export const NO_TEAM = { id: "NONE", name: "None", new: false };
export const ALL_TEAMS = { id: "ALL", name: "All" };

export const mapTeamOptionsRadio = (teams, accountId) => {
  const newTeamTemplate = {
    accountId,
    name: "",
    new: true,
  };
  return [
    {
      ...NO_TEAM,
      display: () => <span>No Team</span>,
    },
    ...map(teams, (team) => ({
      ...team,
      new: false,
      display: (props) => <ManageTeam team={team} {...props} />,
    })),
    {
      ...newTeamTemplate,
      display: (props) => <ManageTeam team={newTeamTemplate} newTeam={true} {...props} />,
    },
  ];
};

export const mapTeamOptionsSelectOnly = (teams) => {
  return [ALL_TEAMS, ...teams, NO_TEAM];
};
