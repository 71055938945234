import React, { useCallback, useRef, useState } from "react";

import SelectImageIcon from "assets/select-image-icon.png";

const CustomImageInput = ({
  showLabel = true,
  style,
  className,
  url = "",
  height = 75,
  emitImage,
  parentForm = {
    register: () => ({}),
    setError: () => ({}),
    clearErrors: () => ({})
  },
  name = "image",
  changeText = "Change Icon",
  chooseText = "Choose Icon",
  dimensions = ""
}) => {
  const inputRef = useRef();
  const [imageUrl, setImageUrl] = useState(url);

  const formRegisterProps = parentForm.register(name);

  const handleChange = useCallback((event) => {
    parentForm.clearErrors(name);
    const file = event?.target?.files[0];
    if (!!file) {
      const tooBig = checkFileSize(file);
      if (!tooBig) {
        setImageUrl(URL.createObjectURL(file));
        emitImage(file);
      } else {
        parentForm.setError(name, {
          type: "maxFileSize",
          message: "File size exceeds 1 MB. Please choose a smaller file."
        });
      }
    }
  }, []);

  const triggerInput = useCallback(() => {
    inputRef.current?.click();
  }, []);

  function checkFileSize(file, maxSize = 1024 * 1024) {
    return file.size > maxSize;
  }

  return (
    <div style={style} className={className}>
      <input
        type="file"
        style={{ display: "none", cursor: "pointer" }}
        {...formRegisterProps}
        onChange={(e) => {
          formRegisterProps.onChange(e);
          handleChange(e);
        }}
        accept="image/png, image/jpeg, image/jpg"
        ref={inputRef}
      />
      <span className="d-flex justify-content-center" style={{ transform: !!imageUrl ? "none" : "scale(0.65)" }}>
        <img
          alt=""
          onClick={triggerInput}
          src={imageUrl || SelectImageIcon}
          style={{
            maxWidth: "100%",
            minWidth: "15%",
            maxHeight: height,
            minHeight: height / 4,
            cursor: "pointer"
          }}
        />
      </span>
      {showLabel && (
        <p
          style={{
            color: "#5a864b",
            textTransform: "uppercase",
            fontSize: 12,
            marginTop: 2,
            marginBottom: 0,
            textAlign: "center",
            width: "100%"
          }}
        >
          {!!imageUrl ? changeText : chooseText}
        </p>
      )}
      {showLabel && dimensions && (
        <p
          style={{
            color: "#5a864b",
            textTransform: "uppercase",
            fontSize: 12,
            marginTop: 2,
            marginBottom: 0,
            textAlign: "center",
            width: "100%"
          }}
        >
          {dimensions}
        </p>
      )}
    </div>
  );
};

export default CustomImageInput;
