import { gql } from "@apollo/client";

export const CSV_EXPORT_INSIGHTS_TABLE = gql`
  mutation exportInsightsTableCSV($projectId: String!, $exportStateInput: CSVExportStateInput!) {
    exportInsightsTableCSV(projectId: $projectId, exportStateInput: $exportStateInput) {
      fileName
      message
      success
      url
    }
  }
`;

export const CSV_EXPORT_PROJECT_USERS = gql`
  mutation exportProjectUsersData($projectId: String!, $exportStateInput: CSVExportStateInput!) {
    exportProjectUsersData(projectId: $projectId, exportStateInput: $exportStateInput) {
      fileName
      message
      success
      url
    }
  }
`;
