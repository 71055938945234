import React from "react";
import { useForm } from "react-hook-form";
import { Modal, ModalBody, Form } from "reactstrap";
import { useMutation } from "@apollo/client";

import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { FormErrorText, StyledPillButton } from "../theme/StyledComponents";
import { CHANGE_PASSPHRASE } from "api/users";
import { useSubmit } from "hooks/form.hooks";
import { passphraseValidation } from "utils/form.utils";

const PassphraseModal = ({ toggle }) => {
  const [changePasshrase] = useMutation(CHANGE_PASSPHRASE);
  const {
    formState: { errors, isDirty },
    register,
    handleSubmit
  } = useForm({ mode: "onSubmit", reValidateMode: "onChange" });

  // the quick brown cow jumped over the fence

  const onSubmit = useSubmit({
    mutation: changePasshrase,
    dataPath: CHANGE_PASSPHRASE.definitions[0].name.value,
    onSuccess: toggle
  });

  return (
    <Modal isOpen={true} style={{ transform: "translateY(100%)" }} toggle={toggle}>
      <ModalBody className="text-center">
        <Form onSubmit={handleSubmit(onSubmit)} className="py-4">
          <div className="mb-3">
            <UncontrolledInput
              labelText="OLD PASSPHRASE"
              name="old"
              formRegister={register}
              validation={{ required: "Please enter current password" }}
              className={errors.old ? "error" : undefined}
              style={{ width: "80%" }}
              passwordInput
            />
            <FormErrorText className="mt-0">{errors?.old?.message}</FormErrorText>
          </div>
          <div className="mb-3">
            <UncontrolledInput
              labelText="NEW PASSPHRASE"
              name="new"
              formRegister={register}
              validation={passphraseValidation}
              className={errors.new ? "error" : undefined}
              style={{ width: "80%" }}
              passwordInput
            />
            <FormErrorText className="mt-0">{errors?.new?.message}</FormErrorText>
          </div>

          <StyledPillButton
            disabled={!isDirty}
            className="mx-auto mt-3"
            type="submit"
            style={{ width: "80%", fontSize: "1.25rem" }}
          >
            Change Passphrase
          </StyledPillButton>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default React.memo(PassphraseModal);
