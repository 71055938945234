import { SplitTreatments } from "@splitsoftware/splitio-react";
import { useSelector } from "react-redux";

import { selectUserAccountAccess } from "../../features/userData/userDataSlice";
import { ACCOUNT_TIER } from "./utils";

export const FeatureFlag = ({ children, featureName, attributes = {} }) => {
  const { tier = ACCOUNT_TIER.FREEMIUM } = useSelector(selectUserAccountAccess) || {};

  return (
    <SplitTreatments names={[featureName]} attributes={{ ...attributes, ACCOUNT_TIER: tier }}>
      {({ treatments }) => {
        const treatment = treatments[featureName]?.treatment;

        return treatment === "on" ? children : null;
      }}
    </SplitTreatments>
  );
};
