import { intersection } from "lodash";

export const getIsAuthorized = (requiredRoles = [], permissions = []) => {
  if (!requiredRoles || !requiredRoles?.length) {
    return true;
  }
  const authorized =
    !!permissions.length > 0 &&
    intersection(permissions, requiredRoles).length > 0;

  return authorized;
};
