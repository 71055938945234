import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { setRedirect } from "features/userData/userDataSlice";
import { decrypt } from "utils/encryption.utils";

export const useCheckForRedirect = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { redirect } = useSelector((state) => state.userData);

  useEffect(() => {
    if (redirect) {
      try {
        const decoded = decrypt(decodeURIComponent(redirect));

        setTimeout(() => {
          console.info("Redirecting to " + decoded);
          dispatch(setRedirect(null));
          history.push(decoded);
        }, 1000);
      } catch (err) {
        console.error("ERROR DECRYPTING REDIRECT", err);
        dispatch(setRedirect(null));
      }
    }
  }, [redirect, dispatch, history]);
};
