import React from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import "./index.css";
import store from "./app/store";
import * as serviceWorker from "./serviceWorker";
import Routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";

import { ApolloClient, InMemoryCache, ApolloProvider, from } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "apollo-link";
import { onError } from "@apollo/client/link/error";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import endpoint from "api/endpoint";
import config from "./config";
import { baseLogout } from "./app/rootReducer";
import { FeatureFlagProvider } from "./hocs/FeatureFlag/FeatureFlag.provider";

let persistor = persistStore(store);

const httpLink = createUploadLink({
  headers: {
    "keep-alive": true,
  },
  uri: `${endpoint.SERVER_LOCAL_GRAPHQL}`,
});

const authMiddleware = (authToken) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });

const errorLink = (handleLogout) =>
  onError(({ graphQLErrors, networkError }) => {
    // const dispatch = useDispatch();
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case "UNAUTHENTICATED":
            // TODO: prompt user they are being redirected to login
            handleLogout();
            return;
          case "UNAUTHORIZED":
            console.log("unauthorized");
            return;
          default:
            console.log("default", err);
            return;
        }
      }
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

const AppApolloClient = ({ children }) => {
  const loginData = useSelector((state) => state.login);
  const authToken = loginData?.authToken;
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.removeItem(config.KEEP_ME_LOGGED_IN_KEY);
    dispatch(baseLogout());
  };

  const client = new ApolloClient({
    link: from([errorLink(handleLogout), authMiddleware(authToken).concat(httpLink)]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

ReactDOM.render(
  <Provider store={store}>
    <FeatureFlagProvider>
      <PersistGate loading={null} persistor={persistor}>
        <AppApolloClient>
          <Routes />
        </AppApolloClient>
      </PersistGate>
    </FeatureFlagProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
