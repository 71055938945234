import { createSlice } from "@reduxjs/toolkit";
import { find } from "lodash";

export const IntegrationsSlice = createSlice({
  name: "integrationsData",
  initialState: {
    slackChannels: [],
    msTeamsChannels: [],
    integrations: [],
    teamsIntegration: null,
  },
  reducers: {
    saveSlackChannels: (state, action) => {
      state.slackChannels = action.payload ?? [];
    },
    saveMSTeamsChannels: (state, action) => {
      state.msTeamsChannels = action.payload ?? [];
    },
    saveIntegrations: (state, action) => {
      state.integrations = action.payload;

      const teamsIntegration = find(
        state.integrations,
        (integration) => integration?.type === "TEAMS"
      );
      if (teamsIntegration) {
        state.teamsIntegration = teamsIntegration;
      }
    },
  },
});

export const { saveSlackChannels, saveMSTeamsChannels, saveIntegrations } =
  IntegrationsSlice.actions;

export default IntegrationsSlice.reducer;
