import React from "react";
import editIcon from "../../assets/edit-icon.png";
import KatilystLink from "../../hocs/KatilystLink";
import config from "../../config";

const HeaderLogo = ({ logo, permissions, setEdit, defaultLogo }) => {
  const displayLogo = logo ?? defaultLogo;
  return (
    <div className="toolbar_logo d-flex">
      <KatilystLink to={config.HOME_ROUTE}>
        <img
          alt="Project Header logo"
          src={displayLogo}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultLogo;
          }}
          style={{
            margin: "auto"
          }}
        />
      </KatilystLink>
      {permissions.includes("admin") && (
        <button
          data-testid="HEADER_LOGO_EDIT_BUTTON"
          onClick={() => setEdit(true)}
          style={{
            padding: 0,
            border: 0,
            background: 0,
            alignSelf: "flex-end"
          }}
        >
          <img alt="edit-icon" src={editIcon} style={{ height: 20, width: 20 }} />
        </button>
      )}
    </div>
  );
};

export default React.memo(HeaderLogo);
