import React, { memo, useCallback, useEffect } from "react";
import { keyBy } from "lodash";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { DELETE_WEBHOOK, SAVE_WEBHOOK } from "../../../api/integrations";
import DeleteRow from "../../DeleteRow/DeleteRow";
import { useSubmit } from "hooks/form.hooks";
import CustomSelect from "components/CustomSelect";
import { ActionFormButton } from "theme/StyledComponents";
import { useSelector } from "react-redux";
import { selectProjectData } from "features/userData/userDataSlice";

function WebhookForm(props) {
  const currentProject = useSelector(selectProjectData);
  const { index, parentForm, channels } = props;
  const integration = parentForm.getValues();
  const webhook = integration.webhooks[index];

  const [deleteWebhook] = useMutation(DELETE_WEBHOOK, {
    variables: { webhookId: webhook.id }
  });

  const [saveWebhook] = useMutation(SAVE_WEBHOOK);

  const handleDeleteWebhook = useSubmit({
    mutation: deleteWebhook,
    dataPath: DELETE_WEBHOOK.definitions[0].name.value,
    onSuccess: () => parentForm.remove(index)
  });

  const handleDeleteClick = useCallback(() => {
    if (!webhook?.createdAt) {
      parentForm.remove(index);
    } else {
      handleDeleteWebhook();
    }
  }, [webhook, handleDeleteWebhook]);

  const {
    formState: { isDirty },
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    control
  } = useForm({
    defaultValues: webhook,
    channelId: channels[0]?.id
  });

  const channelDict = keyBy(channels, "id");
  const channelId = watch("channelId");

  const handleSaveWebhook = useSubmit({
    mutation: saveWebhook,
    dataPath: SAVE_WEBHOOK.definitions[0].name.value,
    onSuccess: () => {
      reset({ channelId });
      parentForm?.refetchIntegrations();
    },
    extractFormData: () => {
      return {
        id: webhook?.id || null,
        channelId,
        channelName: channelDict[channelId].name,
        accountMessagingIntegrationId: integration.id,
        projectId: currentProject?.id
      };
    }
  });

  useEffect(() => {
    // * MB:this ensures that dropdown shows correct channel on refresh if channels are re-requested
    setValue("channelId", webhook.channelId);
  }, [channels, webhook, setValue]);

  return (
    <div className="d-flex justify-content-start px-3 mt-4 w-100">
      <span>
        <CustomSelect
          formRegister={register}
          name="channelId"
          label="CHANNEL"
          options={channels}
          displayKey="name"
          width="20rem"
          maxWidth="20rem"
          defaultValue={channels.find((channel) => channel.id === channelId)}
          control={control}
        />
      </span>

      <span className="ms-auto d-flex justify-content-between" style={{ minWidth: "9rem" }}>
        <ActionFormButton
          data-testid="CHANNEL_SUBMIT"
          type="button"
          onClick={handleSubmit(handleSaveWebhook)}
          className="btn btn-outline-success shadow border-0"
          disabled={!isDirty}
        >
          <i className="fa fa-check" />
        </ActionFormButton>

        <ActionFormButton
          type="button"
          className="btn shadow border-0 btn-outline-primary"
          buttonType="undo"
          onClick={() =>
            reset({
              channelId: webhook?.channelId ? webhook?.channelId : channels[0]?.id
            })
          }
          disabled={!isDirty}
        >
          <i className="fa fa-undo" />
        </ActionFormButton>
        <DeleteRow handleDelete={handleDeleteClick} shouldUseModal={Boolean(webhook?.createdAt)} />
      </span>
    </div>
  );
}

WebhookForm.propTypes = {
  index: PropTypes.number,
  parentForm: PropTypes.shape({
    register: PropTypes.func,
    getValues: PropTypes.func,
    remove: PropTypes.func,
    refetchIntegrations: PropTypes.func
  }),
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  )
};

export default memo(WebhookForm);
