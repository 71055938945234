import React, { memo, useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

// * COMPONENTS
import { ActionFormButton, StyledCardShadow, StyledPillButton } from "../../theme/StyledComponents";
import { Card, CardBody, Col, Modal, ModalBody, Row, UncontrolledTooltip } from "reactstrap";
import CSVUploadCustomActionForm from "./CSVUploadCustomActionForm";
import CSVActionUploadFileSelection from "./CSVActionUploadFileSelection";
import CSVActionUploadMappingsTable from "./CSVActionUploadMappingsTable";
import CustomCheckbox from "../CustomCheckbox";
import CustomRadioButton from "../CustomRadioButton";
import CustomSelect from "../CustomSelect";
import ColumnSelectionValidation from "./ColumnSelectionValidation";
import ConfirmationModal from "../ConfirmationModal";
import LoadColumnAndActionMappingModal from "./LoadColumnAndActionMappingModal";
import SaveColumnAndActionMappingModal from "./SaveColumnAndActionMappingModal";

// * MUTATIONS
import {
  COMPARE_ACTION_UPLOAD_CONFIGS,
  CREATE_ACTION_MAPPING_CONFIG,
  GET_UPLOAD_CONFIGS,
  SUBMIT_CSV_UPLOAD_ACTION,
  UPDATE_UPLOAD_ACTION_MAPPING_CONFIG,
  VALIDATE_ACTION_COLUMN,
  VALIDATE_DATE_COLUMN,
  VALIDATE_EMAIL_COLUMN
} from "../../api/csv-upload";

// * Selectors / Utils
import { setGlobalLoading } from "../../features/Common/CommonSlice";
import { useHandleError, useHandleSuccess } from "../../hooks/api.hooks";
import {
  callSubmit,
  compareConfigs,
  handleConfigCreate,
  handleConfigUpdate,
  useValidateActionColumn,
  useValidateActionDateColumnAndFormat,
  useValidateEmailColumnSelection
} from "../../utils/csv.utils";
import { useGetUserAndActions } from "../../hooks/application";
import messages from "../../api/messages";
import RoleSelectionForNewUsers from "../Forms/RoleSelectionForNewUsers";

// * CSVActionUploadModal handles the upload, config creation, and submission of a CSV Bulk Action Log
const CSVActionUploadModal = ({ toggle, projectId, queries }) => {
  // * MODAL BODY METHODS
  const dispatch = useDispatch();
  const handleError = useHandleError();
  const handleSuccess = useHandleSuccess();

  // * CONFIRMATION MODALS
  const [showConfirm, setShowConfirm] = useState(false);
  const [showChangedFieldsConfirmationModal, setShowChangedFieldsConfirmationModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const toggleConfirm = () => setShowConfirm(!showConfirm);
  const toggleConfirmationInputConfirm = () =>
    setShowChangedFieldsConfirmationModal(!showChangedFieldsConfirmationModal);
  const toggleWarning = () => setShowWarning(!showWarning);
  const handleWarningClose = () => {
    toggleWarning();
    toggle();
  };

  const handleReset = () => {
    reset(); // * form reset method
    setFile(null);
    setValidFile(false);
    setMapToPredefinedActionsRows([]);
    setValue("savedMappingName", null);
    setSelectedColumnAndActionMapping(null);
    setChangedFields([]);
    setConfigStateReadyForSubmit(true); // * "true" because no config is selected so if modal body is valid -> submit
    setConfigLoadedOrSaved(false);
    setHeaderRow([]);
  };

  // * send resetFileSelection boolean to file selection component then reset modal state
  const resetModalState = () => {
    setResetFileSelection(true);
    handleReset();
  };

  // * MUTATIONS & QUERIES
  const { refetch: refetchUser } = useGetUserAndActions();

  const [compareActionUploadConfigs] = useMutation(COMPARE_ACTION_UPLOAD_CONFIGS);
  const [createActionMappingConfig, { loading: createActionMappingConfigLoading }] =
    useMutation(CREATE_ACTION_MAPPING_CONFIG);
  const { data: actionUploadConfigsResponse, refetch: refetchConfigs } = useQuery(GET_UPLOAD_CONFIGS, {
    variables: { projectId },
    fetchPolicy: "no-cache"
  });
  const initialConfigsResponse = actionUploadConfigsResponse?.getActionMappingConfigsByProjectId?.configs;
  const [validateEmailColumn] = useMutation(VALIDATE_EMAIL_COLUMN);
  const [validateDateColumn] = useMutation(VALIDATE_DATE_COLUMN);
  const [submitUploadAction, { loading: submitActionLoading }] = useMutation(SUBMIT_CSV_UPLOAD_ACTION);
  const [updateActionMappingConfig] = useMutation(UPDATE_UPLOAD_ACTION_MAPPING_CONFIG);
  const [validateSelectedColumn] = useMutation(VALIDATE_ACTION_COLUMN);

  // * STATE INITIALIZATION
  const [actionUploadConfigs, setActionUploadConfigs] = useState(initialConfigsResponse);
  const [configId, setConfigId] = useState(null);
  const [configName, setConfigName] = useState(null);
  const [configLoadedOrSaved, setConfigLoadedOrSaved] = useState(false);
  const [configStateReadyForSubmit, setConfigStateReadyForSubmit] = useState(true);
  const [customActionFromConfig, setCustomActionFromConfig] = useState(null);
  const [logId, setLogId] = useState(null);
  const [mapToPredefinedActionsRows, setMapToPredefinedActionsRows] = useState([]);
  const [resetFileSelection, setResetFileSelection] = useState(false);
  const [selectedPredefinedActionMaps, setSelectedPredefinedActionMaps] = useState([]);

  const handleActionMappingRefetch = () => {
    refetchConfigs().then((configsResult) => {
      const configsFromRefetch = configsResult?.data?.getActionMappingConfigsByProjectId?.configs;
      setActionUploadConfigs(configsFromRefetch);
    });
  };

  // * FILE SELECTION METHODS & PROPERTIES
  const [file, setFile] = useState(null);
  const [headerRow, setHeaderRow] = useState([]);
  const [submittable, setSubmittable] = useState(false);
  const [validFile, setValidFile] = useState(false);
  const resettable = validFile;
  const handleSelectedActionMapping = (foundConfig, id) => {
    let found = foundConfig;
    if (id) {
      found = actionUploadConfigs.find((config) => config.id === id);
    }
    setSelectedColumnAndActionMapping(found);
    setConfigId(found.id);
    setConfigName(found.configName);
    setConfigLoadedOrSaved(true); // * fresh config => skip change comparison until something changes
  };

  // * COLUMN & ACTION MAPPING
  const [actionColumnMessage, setActionColumnMessage] = useState({
    success: false,
    message: "",
    displayValue: null
  });
  const [actionDateColumnMessage, setActionDateColumnMessage] = useState({
    success: false,
    message: "",
    displayValue: null
  });
  const [changedFields, setChangedFields] = useState([]);
  const [columnAndActionMappingModalOpen, setColumnAndActionMappingModalOpen] = useState(false);
  const [emailColumnMessage, setEmailColumnMessage] = useState({
    success: false,
    message: "",
    displayValue: null
  });
  const [saveMappingModalOpen, setSaveMappingModalOpen] = useState(false);
  const [selectedColumnAndActionMapping, setSelectedColumnAndActionMapping] = useState(null);
  const toggleColumnAndActionMappingModal = () => setColumnAndActionMappingModalOpen(!columnAndActionMappingModalOpen);
  const toggleSaveMappingModal = () => setSaveMappingModalOpen(!saveMappingModalOpen);

  // * TBD(AF): synced with backend and token id used for key value in CustomSelect
  const dateFormats = [
    { format: "MM/DD/YYYY", id: 1 },
    { format: "YYYY/MM/DD", id: 2 },
    { format: "YYYY-MM-DD", id: 3 },
    { format: "MM-DD-YYYY", id: 4 },
    { format: "MM.DD.YYYY", id: 5 },
    { format: "YYYY.MM.DD", id: 6 },
    { format: "YYYYMMDD", id: 7 },
    {
      format: "DD Month, YYYY",
      id: 8
    }
  ];

  // * The FORM
  const {
    control,
    formState: { dirtyFields },
    getValues,
    register,
    reset,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      actionDateMappedColumnFormat: null,
      actionDate: null,
      mapToPredefinedActions: true,
      mapToCustomAction: false,
      addNewUsers: false,
      selectedRole: "user",
      sendRuleTriggerNotifications: true
    }
  });

  const addNewUsers = watch("addNewUsers");
  const sendRuleTriggerNotifications = watch("sendRuleTriggerNotifications");
  const mapToPredefinedActions = watch("mapToPredefinedActions");
  const mapToCustomAction = watch("mapToCustomAction");
  const customAction = watch("customAction");
  const addedCustomMappingName = watch("savedMappingName");
  const actionMappedColumn = watch("actionMappedColumn");
  const actionDateMappedColumn = watch("actionDateMappedColumn");
  const actionDateMappedColumnFormat = watch("actionDateMappedColumnFormat");
  const emailMappedColumn = watch("emailMappedColumn");
  const selectedRole = watch("role");

  const customActionSubmit = (customAction) => {
    setCustomActionRowValid(true);
    setValue("customAction", customAction);
  };

  const setRole = useCallback(
    (role) => {
      setValue("role", role);
    },
    [setValue]
  );

  // * USE EFFECTS DISTRICT 🏢
  useEffect(() => {
    if (!!file) {
      setResetFileSelection(false);
    }
  }, [file, setResetFileSelection]);

  // * handle radio selection
  useEffect(() => {
    if (mapToCustomAction) {
      setValue("mapToPredefinedActions", false);
      setSelectedPredefinedActionMaps([]);
    }
  }, [mapToCustomAction, setValue, setActionColumnMessage, setSelectedPredefinedActionMaps]);

  // * handle radio selection
  useEffect(() => {
    if (mapToPredefinedActions) {
      setValue("mapToCustomAction", false);
      setValue("customAction", null);
    }
  }, [mapToPredefinedActions, setValue, setActionColumnMessage]);

  // * set values on config selection or update
  useEffect(() => {
    if (selectedColumnAndActionMapping) {
      setConfigLoadedOrSaved(true);
      setConfigStateReadyForSubmit(true);
      setValue("savedMappingName", null);
      setValue("role", selectedColumnAndActionMapping.role ?? null);
      setValue("addNewUsers", selectedColumnAndActionMapping.addNewUsers ?? false);
      setValue("sendRuleTriggerNotifications", selectedColumnAndActionMapping.sendRuleTriggerNotifications ?? false);

      // * if there is a customAction on the config
      if (selectedColumnAndActionMapping.customActionName) {
        setValue("mapToCustomAction", true);
        setValue("mapToPredefinedActions", false);
        setCustomActionFromConfig(null);
        const customActionForChildComponent = {
          actionMappedColumn: selectedColumnAndActionMapping.actionMappedColumn,
          customActionName: selectedColumnAndActionMapping.customActionName ?? null,
          customActionAttributeId: selectedColumnAndActionMapping.customActionAttributeId ?? null,
          customActionModifierId: selectedColumnAndActionMapping.customActionModifierId ?? null,
          customActionOperatorId: selectedColumnAndActionMapping.customActionOperatorId ?? null,
          customActionValue: selectedColumnAndActionMapping.customActionValue ?? null
        };
        setCustomActionFromConfig(customActionForChildComponent);
        setValue("actionMappedColumn", null);
        dirtyFields["actionMappedColumn"] = false;
        // * else the config is a mapToPredefinedActions config
      } else {
        setValue("mapToPredefinedActions", true);
        setValue("mapToCustomAction", false);
        setCustomActionFromConfig(null);
        !!selectedColumnAndActionMapping.actionMappedColumn
          ? (dirtyFields["actionMappedColumn"] = true)
          : (dirtyFields["actionMappedColumn"] = false);
        setValue(
          "actionMappedColumn",
          !!selectedColumnAndActionMapping.actionMappedColumn ? selectedColumnAndActionMapping.actionMappedColumn : null
        );
      }

      setValue(
        "emailMappedColumn",
        !!selectedColumnAndActionMapping.emailMappedColumn ? selectedColumnAndActionMapping.emailMappedColumn : null
      );
      !!selectedColumnAndActionMapping.emailMappedColumn
        ? (dirtyFields["emailMappedColumn"] = true)
        : (dirtyFields["emailMappedColumn"] = false);
      !!selectedColumnAndActionMapping.actionDateMappedColumn
        ? (dirtyFields["actionDateMappedColumn"] = true)
        : (dirtyFields["actionDateMappedColumn"] = false);
      setValue(
        "actionDateMappedColumn",
        !!selectedColumnAndActionMapping.actionDateMappedColumn
          ? selectedColumnAndActionMapping.actionDateMappedColumn
          : null
      );
    }
  }, [selectedColumnAndActionMapping, setValue, dirtyFields, file, configName, configId]);

  // * custom hook - set column validation: actionColumn
  useValidateActionColumn(
    actionMappedColumn,
    dirtyFields,
    setActionColumnMessage,
    file,
    projectId,
    logId,
    validateSelectedColumn,
    setSelectedPredefinedActionMaps
  );

  // * custom hook: set column validation: actionDateColumn
  useValidateActionDateColumnAndFormat(
    actionDateMappedColumn,
    setActionDateColumnMessage,
    logId,
    setValue,
    dirtyFields,
    file,
    validateDateColumn,
    projectId
  );

  // * custom hook - set column validation: emailColumn
  useValidateEmailColumnSelection(
    emailMappedColumn,
    logId,
    dirtyFields,
    setEmailColumnMessage,
    file,
    validateEmailColumn,
    projectId
  );

  // * setSubmittable
  useEffect(() => {
    const valid =
      resettable &&
      validFile &&
      !!actionDateMappedColumn &&
      !!emailMappedColumn &&
      actionDateMappedColumnFormat &&
      emailColumnMessage.success &&
      actionDateColumnMessage.success &&
      (mapToPredefinedActions
        ? !!actionMappedColumn && actionColumnMessage.success && selectedPredefinedActionMaps.length > 0
        : customAction);
    setSubmittable(valid);
  }, [
    selectedPredefinedActionMaps,
    getValues,
    resettable,
    validFile,
    customAction,
    actionColumnMessage.success,
    actionMappedColumn,
    mapToPredefinedActions,
    actionDateColumnMessage.success,
    emailMappedColumn,
    emailColumnMessage.success,
    actionDateMappedColumn,
    mapToPredefinedActionsRows,
    actionDateMappedColumnFormat
  ]);

  // * global loading circle
  useEffect(() => {
    if (submitActionLoading || createActionMappingConfigLoading) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
    return () => dispatch(setGlobalLoading(false));
  }, [dispatch, submitActionLoading, createActionMappingConfigLoading]);

  const extractFormValues = () => {
    const base = getValues();
    return {
      actionDateMappedColumn: base.actionDateMappedColumn,
      actionDateMappedColumnFormat: base.actionDateMappedColumnFormat,
      actionMappedColumn: mapToCustomAction ? base.customAction?.actionMappedColumn : base.actionMappedColumn,
      emailMappedColumn: base.emailMappedColumn,
      addNewUsers,
      role: selectedRole,
      sendRuleTriggerNotifications,
      customActionName: mapToCustomAction ? base.customAction?.customActionName ?? null : null,
      customActionModifierId: mapToCustomAction ? base.customAction?.modifierId ?? null : null,
      customActionAttributeId: mapToCustomAction ? base.customAction?.attributeId ?? null : null,
      customActionOperatorId: mapToCustomAction ? base.customAction?.operatorId ?? null : null,
      customActionValue: mapToCustomAction
        ? base.customAction?.value === ""
          ? null
          : +base.customAction?.value
        : null,
      customActionUploadMappings: mapToPredefinedActions ? selectedPredefinedActionMaps : []
    };
  };

  // * Mutation Calls
  const handleSavedMapping = (valuesFromSaveModal) => {
    // * if there is a new name: create a whole new config
    if (!!valuesFromSaveModal.configName) {
      const updateValues = extractFormValues();
      handleConfigCreate(
        { ...updateValues, configName: valuesFromSaveModal.configName },
        createActionMappingConfig,
        projectId,
        handleError
      ).then((res) => {
        if (res.data.createActionMappingConfig.success) {
          setConfigLoadedOrSaved(true);
          refetchConfigs().then((configsResult) => {
            const configsFromRefetch = configsResult?.data?.getActionMappingConfigsByProjectId?.configs;
            setActionUploadConfigs(configsFromRefetch);
            const found = configsFromRefetch.find((config) => config.id === res.data.createActionMappingConfig.data);
            if (found) {
              handleSuccess(`CONFIG: ${found.configName} saved!`);
              handleSelectedActionMapping(found, null);
              toggleSaveMappingModal();
            }
          });
          toggleSaveMappingModal();
        }
      });
    }
    // * if there is a configId: update sent config with modal state values
    if (!!valuesFromSaveModal.id) {
      const configFromSaveModal = actionUploadConfigs.find((config) => config.id === valuesFromSaveModal.id);
      const updateValues = extractFormValues();
      handleConfigUpdate(
        configFromSaveModal.id,
        { ...updateValues, configName: configFromSaveModal.configName },
        updateActionMappingConfig,
        projectId,
        handleError
      ).then((res) => {
        if (res.data.updateUploadActionMappingConfig.success) {
          setConfigLoadedOrSaved(true);
          refetchConfigs().then((configsResult) => {
            const configsFromRefetch = configsResult?.data?.getActionMappingConfigsByProjectId?.configs;
            setActionUploadConfigs(configsFromRefetch);
            const found = configsFromRefetch.find((config) => config.id === configFromSaveModal.id);
            if (found) {
              handleSelectedActionMapping(null, found.id);
              handleSuccess(`CONFIG: ${found.configName} saved!`);
              toggleSaveMappingModal();
            }
          });
          toggleSaveMappingModal();
        }
      });
    }
  };

  const callCompareConfigs = async () => {
    if (configLoadedOrSaved) {
      const currentModalState = extractFormValues();
      const comparisonRes = await compareConfigs(currentModalState, compareActionUploadConfigs, projectId, configId);
      if (comparisonRes?.data?.compareUploadActionMappingConfigs?.changedFields?.length > 0) {
        setChangedFields(comparisonRes.data.compareUploadActionMappingConfigs.changedFields);
        setConfigStateReadyForSubmit(false);
        toggleConfirmationInputConfirm();
      } else {
        setChangedFields([]);
        setConfigStateReadyForSubmit(true);
        toggleConfirm();
      }
    } else {
      setChangedFields([]);
      setConfigStateReadyForSubmit(true);
      toggleConfirm();
    }
  };

  const handleChangedFieldsModal = () => {
    toggleConfirmationInputConfirm();
    toggleSaveMappingModal();
    setConfigStateReadyForSubmit(true);
  };

  const handleDeclineChangedFieldsModal = () => {
    toggleConfirmationInputConfirm();
    setConfigStateReadyForSubmit(true);
    setConfigLoadedOrSaved(false);
    toggleConfirm();
  };

  const handleCallSubmit = async () => {
    const valuesForSubmit = extractFormValues();
    const res = await callSubmit(valuesForSubmit, submitUploadAction, configId, projectId, file);
    // * response handling and component toggling
    const success = res.data.submitUploadAction.success;
    const msg = res.data.submitUploadAction.message ?? null;
    if (success) {
      handleSuccess(msg);
      queries.refetchProjectUsers();
      refetchUser();
      handleReset();
      toggle();
    } else {
      msg ? handleError(msg) : handleError();
    }
  };

  const changedFieldsModalText = messages.CSV_CHANGED_FIELDS_WARNING_MODAL(changedFields.length);
  const confirmationModalText = messages.CSV_CONFIRMATION_MODAL;
  const closeModalWarningText = messages.CSV_WARNING_MODAL;

  const debouncedOnSubmit = debounce(handleCallSubmit, 1000, { leading: true });
  const [customActionValid, setCustomActionRowValid] = useState(true);

  return (
    <>
      {showConfirm && configStateReadyForSubmit && (
        <ConfirmationModal
          type="confirm"
          toggle={toggleConfirm}
          handleSubmit={debouncedOnSubmit}
          bodyText={confirmationModalText}
          loading={submitActionLoading}
        />
      )}
      {showChangedFieldsConfirmationModal && (
        <ConfirmationModal
          type="warning"
          toggle={handleDeclineChangedFieldsModal}
          handleSubmit={handleChangedFieldsModal}
          bodyText={changedFieldsModalText}
          loading={submitActionLoading}
        />
      )}
      {showWarning && (
        <ConfirmationModal
          type="warning"
          toggle={toggleWarning}
          handleSubmit={handleWarningClose}
          bodyText={closeModalWarningText}
          loading={submitActionLoading}
        />
      )}
      <Modal isOpen toggle={toggle} size="xl" style={{ height: "92%" }}>
        <ModalBody className="text-center px-0 pb-2">
          <div
            className="px-5"
            style={{
              position: "relative",
              paddingTop: "1rem",
              paddingBottom: "1rem",
              borderBottom: "2px solid #0d6efd"
            }}
          >
            <h2 style={{ display: "inline" }}>CSV Log Action Upload</h2>
            <div
              className="d-inline-flex justify-content-around"
              style={{
                width: "20%",
                position: "absolute",
                top: "1rem",
                right: 0
              }}
            >
              <UncontrolledTooltip hideArrow placement="top" target="submit">
                Submit action upload log
              </UncontrolledTooltip>
              <UncontrolledTooltip hideArrow placement="top" target="reset">
                Clear selections and file
              </UncontrolledTooltip>
              <UncontrolledTooltip hideArrow placement="top" target="cancel">
                Cancel
              </UncontrolledTooltip>
              <ActionFormButton
                id="submit"
                type="button"
                onClick={callCompareConfigs}
                className="btn btn-outline-success shadow border-0"
                disabled={!submittable}
                data-testid="SUBMIT_CSV_MODAL"
              >
                <i className="fa fa-check" />
              </ActionFormButton>

              <ActionFormButton
                type="button"
                className="btn shadow border-0 btn-outline-primary"
                buttonType="undo"
                id="reset"
                onClick={resetModalState}
                disabled={!resettable}
                data-testid="RESET_CSV_MODAL"
              >
                <i className="fa fa-undo" />
              </ActionFormButton>

              <ActionFormButton
                id="cancel"
                onClick={() => (resettable ? toggleWarning() : toggle())}
                className="btn shadow border-0 btn-outline-danger"
                data-testid="CLOSE_CSV_MODAL"
              >
                <i className="fa fa-times" />
              </ActionFormButton>
            </div>
          </div>
          <CSVActionUploadFileSelection
            projectId={projectId}
            emitHandleReset={handleReset}
            handleRefetchConfigs={handleActionMappingRefetch}
            emitValidFile={setValidFile}
            emitFile={setFile}
            emitHeaderRowForDisplay={setHeaderRow}
            emitConfigId={setConfigId}
            emitConfigName={setConfigName}
            emitLogId={setLogId}
            resetFileSelection={resetFileSelection}
          />
          <div style={{ fontSize: "14px", color: "grey" }}>Note that the first row is treated a header row</div>
          <div className="d-flex flex-row justify-content-around w-75 m-auto mt-2">
            <StyledPillButton
              className="mx-auto button-upload"
              data-testid="CSV_LOAD_CONFIG_MODAL"
              disabled={!validFile || actionUploadConfigs?.length === 0}
              onClick={toggleColumnAndActionMappingModal}
            >
              <i className="fa fa-plus" />
              &nbsp; Load Config
            </StyledPillButton>
            {selectedColumnAndActionMapping ? (
              <div data-testid="SELECTED_CONFIG_NAME">Config: {selectedColumnAndActionMapping.configName}</div>
            ) : addedCustomMappingName ? (
              <div>New/Updated Config: {addedCustomMappingName}</div>
            ) : (
              <div />
            )}

            <StyledPillButton
              className="mx-auto button-upload"
              data-testid="CSV_SAVE_CONFIG_MODAL"
              disabled={!validFile || (!customActionValid && !mapToPredefinedActions)}
              onClick={toggleSaveMappingModal}
            >
              <i className="fa fa-plus" />
              &nbsp; Save Config
            </StyledPillButton>
          </div>
          {/* LOAD OPTIONS CHECKBOXES */}
          <h4 className="mt-2">Load Options</h4>
          <div className="d-flex flex-row justify-content-around m-auto">
            <div className="d-flex flex-column m-auto">
              <div className="d-flex m-auto mb-2">
                <CustomCheckbox
                  testId="CSV_ADD_NEW_USERS"
                  checked={addNewUsers}
                  name={`addNewUsers`}
                  formRegister={register}
                  disabled={!file}
                />
                <div className="m-auto ms-2"> Add unrecognized users to the project with the role:</div>
              </div>
              <RoleSelectionForNewUsers
                setRole={setRole}
                small={true}
                disabled={!addNewUsers}
                hideSelectionMessage={true}
                roleFromConfig={selectedRole}
              />
            </div>

            <div className="d-flex flex-row justify-content-around mt-1 m-auto">
              <CustomCheckbox
                testId="CSV_SEND_RULE_TRIGGER_NOTIFICATIONS"
                checked={sendRuleTriggerNotifications}
                name={"sendRuleTriggerNotifications"}
                formRegister={register}
                disabled={!validFile}
              />
              <div className="ms-2 m-auto"> Send notifications where applicable based on role</div>
            </div>
          </div>
          {/* RADIO BUTTON GROUP SELECTORS */}
          <h4 className="mt-5 mb-2">Column Mapping</h4>
          <div className="d-flex flex-row justify-content-around">
            <div className="d-flex flex-row justify-content-around">
              <CustomRadioButton
                testId="CSV_MAP_TO_PREDEFINED_ACTIONS"
                checked={!!mapToPredefinedActions}
                name="mapToPredefinedActions"
                formRegister={register}
                disabled={!file}
              />
              <div className="m-auto ms-2">CSV Rows Map to Predefined Actions</div>
            </div>
            <div className="d-flex flex-row justify-content-around">
              <CustomRadioButton
                testId="CSV_MAP_TO_CUSTOM_ACTION"
                checked={!!mapToCustomAction}
                name="mapToCustomAction"
                formRegister={register}
                disabled={!file}
              />
              <div className="m-auto ms-2">All CSV Rows Map to Same Custom Action</div>
            </div>
          </div>
          {/* REQUIRED FIELDS MAPPING CSV INDEX MAPPING */}
          <div
            className="w-100 pt-4"
            style={{
              paddingRight: "2rem",
              marginLeft: "1rem",
              paddingLeft: "2rem"
            }}
          >
            <StyledCardShadow
              className="py-3"
              style={{ overflowY: "auto", height: mapToPredefinedActions ? "370px" : "415px" }}
            >
              <Card className="mb-2 shadow-sm">
                <CardBody>
                  <Row>
                    <h5 className="text-start">Required Fields</h5>
                  </Row>
                  <Row className="pb-3">
                    <Col md={2} className="d-flex align-items-center justify-content-center">
                      Email
                    </Col>
                    <Col className="text-start" md={3}>
                      <CustomSelect
                        formRegister={register}
                        name="emailMappedColumn"
                        defaultValue={
                          headerRow.find((row) => row.column === emailMappedColumn) ?? {
                            name: "Select a CSV FIELD",
                            column: "select option",
                            id: 1000
                          }
                        }
                        label="CSV FIELD"
                        options={headerRow}
                        displayKey="name"
                        maxWidth="100%"
                        width="100%"
                        valueKey="column"
                        disabled={!file}
                        control={control}
                      />
                    </Col>
                    <Col>
                      <ColumnSelectionValidation
                        testId="emailMappedColumn"
                        success={emailColumnMessage.success}
                        message={emailColumnMessage.message}
                        displayValue={emailColumnMessage.displayValue}
                      />
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col md={2} className="d-flex align-items-center justify-content-center">
                      Action
                    </Col>
                    {mapToCustomAction ? (
                      <Col className="text-start align-baseline" md={10}>
                        <CSVUploadCustomActionForm
                          headerRow={headerRow}
                          emitCustomAction={customActionSubmit}
                          projectId={projectId}
                          logId={logId}
                          file={file}
                          customActionFromConfig={customActionFromConfig}
                          emitCustomActionValid={setCustomActionRowValid}
                        />
                      </Col>
                    ) : (
                      <>
                        <Col className="text-start" md={3}>
                          <CustomSelect
                            formRegister={register}
                            name="actionMappedColumn"
                            label="CSV FIELD"
                            defaultValue={
                              headerRow.find((row) => row.column === actionMappedColumn) ?? {
                                name: "Select a CSV FIELD",
                                column: "select option",
                                id: 1000
                              }
                            }
                            options={[
                              {
                                name: "Select a CSV FIELD",
                                column: "select option",
                                id: 1000
                              },
                              ...headerRow
                            ]}
                            displayKey="name"
                            maxWidth="100%"
                            width="100%"
                            valueKey="column"
                            disabled={!file}
                            control={control}
                          />
                        </Col>
                        <Col>
                          <ColumnSelectionValidation
                            testId="actionMappedColumn"
                            success={actionColumnMessage.success}
                            message={actionColumnMessage.message}
                            displayValue={actionColumnMessage.displayValue}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row className="pb-3">
                    <Col md={2} className="d-flex align-items-center justify-content-center">
                      Action Date
                    </Col>
                    <Col className="text-start" md={3}>
                      <CustomSelect
                        formRegister={register}
                        name="actionDateMappedColumn"
                        label="CSV FIELD"
                        defaultValue={
                          headerRow.find((row) => row.column === actionDateMappedColumn) ?? {
                            name: "Select a CSV FIELD",
                            column: "select option",
                            id: 1000
                          }
                        }
                        options={[{ name: "Select a CSV FIELD", column: "select option", id: 1000 }, ...headerRow]}
                        displayKey="name"
                        maxWidth="100%"
                        width="100%"
                        valueKey="column"
                        disabled={!file}
                        control={control}
                      />
                    </Col>
                    <Col>
                      <ColumnSelectionValidation
                        testId="actionDateMappedColumn"
                        success={actionDateColumnMessage.success}
                        message={actionDateColumnMessage.message}
                        displayValue={actionDateColumnMessage.displayValue}
                      />
                    </Col>
                  </Row>
                  <Row className="pb-3">
                    <Col md={2} className="d-flex align-items-center justify-content-center"></Col>
                    <Col md={3}>
                      <CustomSelect
                        formRegister={register}
                        name="actionDateMappedColumnFormat"
                        label="DATE FORMAT"
                        options={dateFormats}
                        displayKey="format"
                        defaultValue={
                          dateFormats.find((date) => date.format === actionDateMappedColumnFormat) ?? dateFormats[0]
                        }
                        maxWidth="100%"
                        width="100%"
                        valueKey="format"
                        disabled={!file}
                        control={control}
                      />
                    </Col>
                    <Col>
                      {actionDateColumnMessage.success && (
                        <ColumnSelectionValidation
                          testId="actionDateMappedColumnFormat"
                          success={
                            actionDateColumnMessage.success && !actionDateMappedColumnFormat
                              ? false
                              : actionDateColumnMessage.success && !!actionDateMappedColumnFormat
                          }
                          message={
                            !!actionDateColumnMessage.message &&
                            !!actionDateMappedColumn &&
                            !!actionDateMappedColumnFormat
                              ? "Check selected format matches csv values or reformat file"
                              : !!actionDateColumnMessage.message &&
                                  !!actionDateMappedColumn &&
                                  !actionDateMappedColumnFormat
                                ? "INVALID DATE FORMAT Please select matching format or reformat date column"
                                : ""
                          }
                          displayValue={null}
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </StyledCardShadow>
          </div>
          {/* ACTION MAPPING CSV FIELD SECTION */}
          {mapToPredefinedActions && (
            <CSVActionUploadMappingsTable
              projectId={projectId}
              configId={configId}
              emitActionMaps={setSelectedPredefinedActionMaps}
              actionMappedColumn={actionMappedColumn}
              successMessage={actionColumnMessage.success}
              file={file}
              handleError={handleError}
            />
          )}
        </ModalBody>
        {/* * ACTION MAPPING MODALS * */}
        {columnAndActionMappingModalOpen && (
          <LoadColumnAndActionMappingModal
            columnAndActionMaps={actionUploadConfigs}
            projectId={projectId}
            configId={configId}
            toggleLoadColumnAndActionMappingModal={toggleColumnAndActionMappingModal}
            emitRefetchActionMappingConfigs={handleActionMappingRefetch}
            emitSelectedMapping={handleSelectedActionMapping}
            handleError={handleError}
            handleSuccess={handleSuccess}
          />
        )}
        {saveMappingModalOpen && (
          <SaveColumnAndActionMappingModal
            columnAndActionMaps={actionUploadConfigs}
            projectId={projectId}
            configId={configId}
            toggleSaveColumnAndActionMappingModal={toggleSaveMappingModal}
            emitRefetchActionMappingConfigs={handleActionMappingRefetch}
            emitSavedMapping={handleSavedMapping}
            currentConfigName={configName}
            handleError={handleError}
            handleSuccess={handleSuccess}
          />
        )}
      </Modal>
    </>
  );
};

export default memo(CSVActionUploadModal);
