import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

/*
 * Generic reusable confirmation modal
 * two types so far: "warning" or "confirm"
 *"warning" type uses the default "No" || "Yes" button text
 *
 * type = "confirm" | "warning"
 * */
const ConfirmationModal = ({ handleSubmit, toggle, bodyText, type, loading = false }) => {
  return (
    <Modal isOpen>
      <ModalBody className="text-center" data-testid="CONFIRMATION_MODAL_BODY">
        {bodyText}
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center", borderTop: "none" }}>
        <Button color="danger" onClick={toggle} data-testid="CANCEL_CONFIRMATION_MODAL" disabled={loading}>
          {type === "confirm" ? "Not yet" : "No"}
        </Button>
        <Button color="success" onClick={handleSubmit} data-testid="submit-action" disabled={loading}>
          {type === "confirm" ? "Do it" : "Yes"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(ConfirmationModal);
