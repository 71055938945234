import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledLabel = styled.span`
  color: #78909c;
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
`;

const HiddenInput = styled.input.attrs({ type: "checkbox" })`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const StyledCheckbox = styled.span`
  background-color: ${(props) => (props.disabled ? "#d1d1d1" : "white")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: #ebaf77;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.75);
  &:hover {
    color: #ebaf77;
  }
`;

const Icon = styled.i`
  transform: scale(1.75);
`;

function CustomCheckbox({ checked = false, formRegister, name, label, testId, disabled }) {
  const registerForm = formRegister(name);

  return (
    <div>
      {!!label && <StyledLabel>{label}</StyledLabel>}
      <label style={{ display: "block" }}>
        <HiddenInput name={name} {...registerForm} disabled={disabled} id="custom-checkbox-hidden-input" />
        <StyledCheckbox disabled={disabled} className="btn" aria-hidden="true" data-testid={testId}>
          {checked && <Icon className="fa fa-check" />}
        </StyledCheckbox>
      </label>
    </div>
  );
}

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  formRegister: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool
};

CustomCheckbox.defaultProps = {
  formRegister: () => {},
  name: "",
  testId: "",
  disabled: false,
  label: ""
};

export default CustomCheckbox;
