import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Logo from "assets/header-logo.png";

import authenticateUser from "api/auth";
import { registerUserRoute, passphraseRecoverRoute } from "routes";
import { setEnablePassphrase, setRedirectLoading } from "features/login/loginSlice";
import config from "../../config";
import {
  FormErrorText,
  StyledPillButton,
  AuthenticationCard,
  AuthenticationContainer,
  AuthFlowLink
} from "theme/StyledComponents";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { FEATURE_FLAGS } from "../../hocs/FeatureFlag/utils";
import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";
import { StyledCheckbox } from "../../components/CustomCheckbox";
import { Icon } from "@mui/material";
import Joi from "joi";

/*
 * Login component validates email input first before "locking" email and showing passphrase input
 * This is to enable SSO check to properly redirect user to SSO login
 * Note: email is validated and warning is shown upon loss of focus to FormGroup
 */
const Login = () => {
  const allowUserRegister = useFeatureFlag(FEATURE_FLAGS.USER_REGISTRATION);
  const dispatch = useDispatch();
  const history = useHistory();

  const { enablePassphrase } = useSelector((state) => state.login); // why is this on application state?
  const { redirectLoading } = useSelector((state) => state.login);

  const [checked, setChecked] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [emailValid, setEmailValid] = useState(null);
  const [focus, setFocus] = useState(true);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const emailAddress = urlParams.get("email")?.replace(/\s/g, "+"); // TODO(MB) this is to handle not encoding/decoding url params correctly yet
  const usePassphraseFromParams = urlParams.get("p") === "true";

  // * Form
  const {
    register,
    getValues,
    watch,
    formState: { errors, dirtyFields }
  } = useForm({
    defaultValues: { passphrase: "", email: emailAddress }
  });
  const emailInput = watch("email");

  useEffect(() => {
    if (dirtyFields["email"]) {
      dispatch(setRedirectLoading(false));
      const schema = Joi.string().email({ tlds: { allow: false } });
      const test = !schema.validate(emailInput).error;
      setEmailValid(test);
    }
  }, [emailInput, setEmailValid, dirtyFields, dispatch]);

  useEffect(() => {
    dispatch(setEnablePassphrase(false));
  }, [dispatch]);

  useEffect(() => {
    if (usePassphraseFromParams) {
      dispatch(setEnablePassphrase(true));
    }
  }, [dispatch, usePassphraseFromParams]);

  const loginUser = useCallback(() => {
    const formData = getValues();
    dispatch(authenticateUser.authenticate(formData, history));
  }, [dispatch, getValues, history]);

  const loginUserPassphrase = useCallback(() => {
    const formData = getValues();
    dispatch(authenticateUser.authenticatePassphrase(formData, history));
  }, [dispatch, getValues, history]);

  const handleLogin = useCallback(() => {
    if (enablePassphrase) {
      loginUserPassphrase();
    } else if (emailValid) {
      setEmailSubmitted(true);
      loginUser();
    }
  }, [enablePassphrase, loginUserPassphrase, loginUser, emailValid]);

  const handleKeepLoggedIn = (checked) => {
    localStorage.setItem(config.KEEP_ME_LOGGED_IN_KEY, checked);
  };

  const handleChangeEmail = () => {
    dispatch(setEnablePassphrase(false));
    setEmailSubmitted(false);
  };

  const toggleChecked = () => {
    handleKeepLoggedIn(!checked);
    setChecked(!checked);
  };

  return (
    <AuthenticationContainer>
      <AuthenticationCard>
        <img alt="Katilyst logo" src={Logo} width="100%" />
        <Form className="pt-4">
          <div className="mb-3">
            <FormGroup className="mt-5 mb-4" onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
              <span>
                <UncontrolledInput
                  style={{ width: "100%" }}
                  formRegister={register}
                  validation={{
                    required: "Required",
                    maxLength: 320
                  }}
                  name="email"
                  labelText="EMAIL"
                  height="3.5rem"
                  disabled={emailSubmitted}
                  className={!emailValid && !!emailInput && !focus && "error"}
                />
                <FormErrorText className="m-0" data-testid="EMAIL_VALIDATION_WARNING">
                  {!emailValid && !!emailInput && !focus && "Please enter a valid email"}
                </FormErrorText>
              </span>
            </FormGroup>
          </div>
          {enablePassphrase && (
            <div className="text-center">
              <UncontrolledInput
                labelText="PASSPHRASE"
                name="passphrase"
                formRegister={register}
                className={errors.passphrase && "error"}
                style={{ width: "100%" }}
                height="3.5rem"
                passwordInput
              />
              <FormErrorText className="mt-0">{errors?.passphrase?.message}</FormErrorText>
            </div>
          )}
          <FormGroup className="m-auto text-center d-flex w-50 mb-3 align-baseline">
            <StyledCheckbox type="checkbox" checked={checked} onClick={toggleChecked} disabled={redirectLoading}>
              {checked && <Icon className="fa fa-check" />}
            </StyledCheckbox>
            <div className="m-auto">Keep Me Logged In</div>
          </FormGroup>
          <div className="d-flex justify-content-center flex-row">
            {emailSubmitted && !redirectLoading && (
              <StyledPillButton
                className="mx-auto"
                type="button"
                data-testid="CHANGE_EMAIL"
                onClick={handleChangeEmail}
                style={{ width: "12rem" }}
              >
                Change Email
              </StyledPillButton>
            )}
            <StyledPillButton
              className="mx-auto"
              type="button"
              data-testid="LOGIN"
              onClick={handleLogin}
              style={{ width: "12rem" }}
              disabled={redirectLoading}
            >
              Submit
            </StyledPillButton>
          </div>
        </Form>
        <AuthFlowLink to={registerUserRoute.path} className="text-center mt-3">
          Register
        </AuthFlowLink>
        {allowUserRegister && enablePassphrase && (
          <AuthFlowLink to={passphraseRecoverRoute.path} className="text-center mt-3">
            Forgot passphrase?
          </AuthFlowLink>
        )}
      </AuthenticationCard>
    </AuthenticationContainer>
  );
};

export default memo(Login);
