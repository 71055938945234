import React, { memo } from "react";
import CustomCheckbox from "../CustomCheckbox";
import RoleSelectionForNewUsers from "./RoleSelectionForNewUsers";
import { UncontrolledTooltip } from "reactstrap";

const AutoAddUsersForm = ({ register, autoAddUsers = false, emitSelectedRole, emitAutoAddUsers }) => {
  const emitSetRole = (role) => {
    emitSelectedRole(role);
  };

  const emitAutoAdd = (checked) => {
    emitAutoAddUsers(checked);
  };

  // * Default to observed only
  return (
    <>
      <UncontrolledTooltip target="autoAddUsersCustom">
        Automatically add users to the project when they perform this action in the role specified
      </UncontrolledTooltip>
      <div
        className="d-inline-flex justify-content-start grid gap-2 w-100 align-items-center"
        data-testid="AUTO_ADD_USERS_FORM"
      >
        <div id="autoAddUsersCustom">
          <CustomCheckbox
            formRegister={register}
            name="autoAddUsers"
            checked={autoAddUsers}
            onChange={emitAutoAdd}
            testId="autoAddUsers"
          />
        </div>
        <div className="ms-1">Auto-Add Users as Role</div>
        <RoleSelectionForNewUsers
          setRole={emitSetRole}
          hideSelectionMessage={true}
          disabled={!autoAddUsers}
          customActionForm={true}
          flexRow={true}
        />
      </div>
    </>
  );
};

export default memo(AutoAddUsersForm);
