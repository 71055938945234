import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";

import { GET_USER_AND_ACTIONS } from "api/users";
import { setSelectedUser } from "features/userData/userDataSlice";

// when projectId or selectUser change, this hook will make a request to get actions, connections, connectionRequests etc.
export const useGetUserAndActions = () => {
  const { selectedUser, projectData } = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const { loading, refetch, data } = useQuery(GET_USER_AND_ACTIONS, {
    variables: { userId: selectedUser?.id, projectId: projectData?.id },
    fetchPolicy: "network-only",
    skip: !projectData?.id || !selectedUser?.id,
  });

  useEffect(() => {
    if (data?.userAndActions) {
      dispatch(setSelectedUser(data.userAndActions));
    }
  }, [data?.userAndActions, dispatch]);

  return { loading, refetch };
};
