import React from "react";
import { map, isEmpty } from "lodash";

import { StyledCardShadow } from "theme/StyledComponents";
import AttributeCard from "features/Attributes/AttributeCard";

function AttributeList({ userAttributes }) {
  return (
    <StyledCardShadow className="p-3 d-flex flex-wrap justify-content-between">
      {map(userAttributes, (upav, i) => {
        return (
          <li className="mb-1" key={`${i}-${upav.displayValue}`} style={{ minWidth: "48%" }}>
            <AttributeCard
              primaryImageUrl={upav?.primaryIconUrl}
              secondaryImageUrl={upav?.secondaryIconUrl}
              title={upav?.attribute?.name}
              subTitle={upav?.displayValue || upav?.value}
            />
          </li>
        );
      })}
      {isEmpty(userAttributes) && <h5 style={{ margin: "0 auto" }}>No user attributes yet!</h5>}
    </StyledCardShadow>
  );
}

export default AttributeList;
