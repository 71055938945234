import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";

import { ActionFormButton, FormErrorText } from "theme/StyledComponents";
import { useSubmit } from "../../hooks/form.hooks";
import { CREATE_TEAM, EDIT_TEAM } from "../../api/teams";
import { UncontrolledInput } from "components/CustomInput/CustomInput";

function TeamForm({ handleClearForm = () => ({}), handleSuccess = () => ({}), team = {}, edit = false }) {
  const { register, reset, handleSubmit, formState, watch } = useForm({
    defaultValues: team,
    mode: "onChange"
  });

  const teamName = watch("name");

  const [editTeam] = useMutation(EDIT_TEAM, {
    variables: {
      data: { name: teamName, id: team?.id }
    }
  });

  const [createTeam] = useMutation(CREATE_TEAM, {
    variables: {
      data: {
        name: teamName,
        accountId: team?.accountId
      }
    }
  });

  const onSubmit = useSubmit({
    mutation: edit ? editTeam : createTeam,
    clearForm: handleClearForm,
    useVariables: true,
    dataPath: edit ? EDIT_TEAM.definitions[0].name.value : CREATE_TEAM.definitions[0].name.value,
    onSuccess: () => {
      handleClearForm();
      handleSuccess();
    }
  });

  const handleReset = React.useCallback(() => reset(), [reset]);

  const teamValidation = {
    required: true,
    minLength: {
      value: 3,
      message: "Team name must be at least 3 characters"
    },
    maxLength: {
      value: 36,
      message: "Team name must be no more than 36 characters"
    }
  };

  const canSubmit = formState.isValid && formState.isDirty;

  return (
    <form className="w-100 shadow px-4 py-5" onSubmit={handleSubmit(onSubmit)} data-testid="TEAM_FORM">
      <div className="d-flex mb-4">
        <div className="d-flex flex-column w-100 justify-content-between align-items-center">
          <div className="w-75">
            <UncontrolledInput
              testId="TEAM_NAME"
              formRegister={register}
              name="name"
              labelText="Team Name"
              validation={teamValidation}
            />
            <FormErrorText className="mt-0">{formState.errors?.name?.message}</FormErrorText>
          </div>
          <div className="d-flex justify-content-center">
            <ActionFormButton
              data-testid="SUBMIT_TEAM"
              type="submit"
              className="btn btn-outline-success shadow border-0 mx-3"
              disabled={!canSubmit}
            >
              <i className="fa fa-check" />
            </ActionFormButton>
            <ActionFormButton
              data-testid="UNDO_TEAM"
              type="button"
              className="btn shadow border-0 btn-outline-primary mx-3"
              buttonType="undo"
              onClick={handleReset}
              disabled={!canSubmit}
            >
              <i className="fa fa-undo" />
            </ActionFormButton>
            <ActionFormButton
              data-testid="CANCEL_TEAM"
              type="button"
              className="btn border-0 shadow btn-outline-danger mx-3"
              onClick={handleClearForm}
              buttonType="delete"
            >
              <i className="fa fa-times" />
            </ActionFormButton>
          </div>
        </div>
      </div>
    </form>
  );
}

export default React.memo(TeamForm);
