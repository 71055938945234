import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import config from "../config";

const LogoutConfirmationModal = ({ handleLogout, shouldUseModal }) => {
  let forceLogoutTimer;
  if (shouldUseModal) {
    forceLogoutTimer = setTimeout(() => {
      // Force logout session if user does not confirm within specified time
      handleLogout(false); // indicate the user wants to "logout" without user interaction
    }, config.MAX_PROMPT_BEFORE_LOGOUT);
  }
  return (
    <Modal isOpen={shouldUseModal}>
      <ModalBody className="text-center">Do you want to stay logged in?</ModalBody>
      <ModalFooter style={{ justifyContent: "center", borderTop: "none" }}>
        <Button
          color="success"
          onClick={() => {
            clearTimeout(forceLogoutTimer);
            handleLogout(true);
          }}
        >
          Yes
        </Button>
        <Button
          color="danger"
          onClick={() => {
            clearTimeout(forceLogoutTimer);
            handleLogout(false);
          }}
        >
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(LogoutConfirmationModal);
