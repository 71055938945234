import { createSlice } from "@reduxjs/toolkit";

export const CommonSlice = createSlice({
  name: "commonState",
  initialState: {
    globalLoading: false,
    alert: {
      message: null,
      type: "success",
    },
  },
  reducers: {
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
    showAlert: (state, { payload: { message, type = "success" } }) => {
      if (typeof message === "string") {
        state.alert.message = message;
      } else {
        state.alert.message = null;
      }

      state.alert.type = type;
    },
  },
});

export const { setGlobalLoading, showAlert } = CommonSlice.actions;

export default CommonSlice.reducer;
