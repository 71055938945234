import React, { useEffect, useRef, useState } from "react";
import { StyledPillButton } from "../../theme/StyledComponents";
import { useMutation } from "@apollo/client";
import { GET_HEADER_ROW, UPLOAD_FILE } from "../../api/csv-upload";

// * CSVActionUploadFileSelection: component handles the file upload and initial validation processes
const CSVActionUploadFileSelection = ({
  projectId,
  resetFileSelection = false,
  emitHandleReset,
  emitValidFile,
  emitFile,
  handleRefetchConfigs,
  emitHeaderRowForDisplay,
  emitConfigId,
  emitConfigName,
  emitLogId
}) => {
  const [getHeaderRow] = useMutation(GET_HEADER_ROW);
  const [uploadFile, { loading: uploadFileLoading }] = useMutation(UPLOAD_FILE);

  const hiddenFileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [invalidFileWarning, setInvalidFileWarning] = useState(null);
  const captureFileInputClickEvent = () => hiddenFileInputRef.current.click();

  const handleReset = () => {
    setFile(null);
    emitFile(null);
    emitHeaderRowForDisplay([]);
    handleSelectedFileReset();
  };
  const handleSelectedFileReset = () => (hiddenFileInputRef.current.value = "");
  const handleSelectedFileChange = async (event) => {
    setInvalidFileWarning(null);
    emitHeaderRowForDisplay([]);
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size > 0) {
      await handleSelectedFile(fileUploaded);
    } else {
      setInvalidFileWarning("Invalid CSV Format: File Empty");
    }
  };

  const handleSelectedFile = async (file) => {
    emitHandleReset();
    handleReset();
    await validateSelectedFile(file);
  };

  const validateSelectedFile = async (file) => {
    if (file && file.type === "text/csv" && file.size > 0) {
      setInvalidFileWarning(null);
      const fetchHeaderRes = await getHeaderRow({
        variables: {
          projectId,
          data: {
            file,
            fileCategory: "csv/text"
          }
        }
      });
      if (fetchHeaderRes) {
        setFile(file);
        emitFile(file);
        const parsedData = JSON.parse(fetchHeaderRes.data.getHeaderRow.data);

        const headerRowForDisplay = parsedData.map((str, index) => {
          return { name: `[${index}] ${str}`, id: index, column: str }; // * e.g. name: [1] email
        });

        emitHeaderRowForDisplay(headerRowForDisplay);

        const uploadFileRes = await uploadFile({
          variables: {
            projectId,
            data: {
              file,
              fileCategory: "csv/text"
            }
          },
          onError: (err) => {
            setInvalidFileWarning(err.message);
          }
        });
        if (uploadFileRes) {
          emitConfigId(uploadFileRes.data.uploadFile.configId);
          emitConfigName(uploadFileRes.data.uploadFile.configName);
          emitLogId(uploadFileRes.data.uploadFile.logId);
          handleRefetchConfigs();
        }
      }
      emitValidFile(true);
    } else {
      setInvalidFileWarning("Unable to validate uploaded file. Please reformat and upload again.");
    }
    handleRefetchConfigs();
  };

  useResetFileSelection(resetFileSelection, emitFile, emitHeaderRowForDisplay, setFile, handleSelectedFileReset);

  return (
    <div className="d-flex flex-row justify-content-between w-75 m-auto mt-4 mb-1">
      <StyledPillButton
        className="mx-auto button-upload"
        onClick={captureFileInputClickEvent}
        disabled={uploadFileLoading}
      >
        <i className="fa fa-plus" />
        &nbsp; {uploadFileLoading ? "File Uploading..." : "Select CSV File"}
      </StyledPillButton>
      <input
        type="file"
        data-testid="UPLOAD_REF"
        accept=".csv"
        onChange={handleSelectedFileChange}
        ref={hiddenFileInputRef}
        style={{ display: "none" }}
      />
      {file && !uploadFileLoading && (
        <div className="m-auto" data-testid="CSV_FILE_NAME">
          {!invalidFileWarning && file?.name}
        </div>
      )}
      {invalidFileWarning && (
        <div className="m-auto" style={{ color: "red" }}>
          <i className="fa fa-times" /> {invalidFileWarning}
        </div>
      )}
    </div>
  );
};

export default CSVActionUploadFileSelection;

// * custom hook to handle file selection reset
const useResetFileSelection = (resetFileSelection, emitFile, emitHeaderRowForDisplay, setFile) => {
  useEffect(() => {
    if (resetFileSelection) {
      setFile(null);
      emitFile(null);
      emitHeaderRowForDisplay([]);
    }
  }, [resetFileSelection, emitFile, emitHeaderRowForDisplay, setFile]);
};
