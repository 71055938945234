import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { debounce, find, isEmpty, omit } from "lodash";
import { useMutation } from "@apollo/client";
import confirmAction from "reactstrap-confirm";

import CustomSelect from "components/CustomSelect";
import CustomDatePicker from "components/CustomDatePicker";
import { UncontrolledInput } from "components/CustomInput/CustomInput";
import { CREATE_ACTION, SUBMIT_ACTION } from "api/actions";
import { selectSpecificOperators } from "app/operators.slice";
import { useCheckActionLimit } from "hooks/application.hooks";
import { setGlobalLoading } from "features/Common/CommonSlice";
import { ActionFormButton } from "theme/StyledComponents";
import { Prompt } from "react-router-dom";
import messages from "api/messages";
import { ATTRIBUTES_TYPES } from "features/Attributes/utils";
import { getLimitDisplay } from "features/Actions/utils";
import { useGetUserAndActions } from "hooks/application";
import ActionExpirationForm from "./ActionExpirationForm";
import { DateTime } from "luxon";
import { FeatureFlag } from "hocs/FeatureFlag/FeatureFlag";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import { calculateExpirationDate, checkDateExpired } from "utils/date.utils";

function ActionForm({ handleClearForm, attributes, user, customActions, projectId, handleApproveOrRejectSuccess }) {
  const dispatch = useDispatch();
  const actionOptions = useMemo(
    () => [{ id: "MANUAL_ENTRY", name: "Manual Entry" }, ...customActions],
    [customActions]
  );
  // const [selectedAction, setSelectedAction] = useState(actionOptions[0].id);
  const [plusOperator, assignmentOperator, minusOperator] = useSelector(selectSpecificOperators(["+", "=", "-"]));
  const wholeNumberOperators = [plusOperator, minusOperator, assignmentOperator];

  const checkActionLimit = useCheckActionLimit();

  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { dirtyFields }
  } = useForm({
    defaultValues: {
      action: actionOptions[0],
      actionId: actionOptions[0].id,
      expirationPeriod: null,
      expirationPeriodAmount: null,
      operatorId: assignmentOperator
    }
  });

  const actionId = watch("actionId");
  const name = watch("name");
  const value = watch("value");
  const expirationPeriod = watch("expirationPeriod");
  const expirationPeriodAmount = watch("expirationPeriodAmount");
  const operatorId = watch("operatorId");

  const [selectedAttribute, setSelectedAttribute] = useState(attributes[0]);
  const [selectedAction, setAction] = useState(null);
  const [createAction, { loading: actionLoading }] = useMutation(CREATE_ACTION);
  const [submitAction, { loading: submitActionLoading }] = useMutation(SUBMIT_ACTION);
  const { loading: userLoading } = useGetUserAndActions();

  const getDisplayValue = (formData) => {
    const foundAttribute = attributes.find((att) => att.id === formData?.attributeId);

    if (foundAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER) {
      return formData.value;
    }

    const foundName = foundAttribute?.attributeValues.find((av) => av?.id === formData?.value)?.name;

    return foundName;
  };

  const getFormValuesFromAction = (action) => {
    return {
      value: action.value,
      operatorId: action.operator.id,
      attributeId: action.attribute.id,
      name: action.name,
      type: action.type,
      customActionId: action.id,
      expirationPeriod: action.expirationPeriod,
      expirationPeriodAmount: action.expirationPeriodAmount
    };
  };

  const submitFormAction = async (actionPayload) => {
    await createAction({
      variables: {
        data: actionPayload
      }
    });

    handleApproveOrRejectSuccess();
    handleClearForm();
  };

  const onSubmit = useCallback(
    async (formData) => {
      let forceExpired = true;
      const base =
        formData.actionId !== "MANUAL_ENTRY"
          ? getFormValuesFromAction(actionOptions.find((action) => action.id === formData?.actionId))
          : formData;
      const { success: withinTimeFrame } = await checkActionLimit(base?.customActionId ?? "", user?.id);
      const calculatedExpirationDate = calculateExpirationDate(
        base.expirationPeriod,
        base.expirationPeriodAmount,
        formData.actionDate ? DateTime.fromSQL(formData.actionDate) : DateTime.now()
      );
      const alreadyExpired = checkDateExpired(calculatedExpirationDate);

      if (alreadyExpired) {
        forceExpired = await confirmAction({
          confirmText: "Yes",
          cancelText: "No",
          message: messages.EXPIRED_ACTION_WARNING,
          confirmColor: "btn btn-success shadow border-0",
          cancelColor: "btn btn-danger shadow border-0"
        });
      }

      const actionPayload = {
        ...omit(base, ["expirationPeriod", "expirationPeriodAmount", "expires", "actionId"]),
        projectId,
        actionDate: formData.actionDate,
        userId: user?.id,
        attributeName: selectedAttribute?.name ?? "",
        displayValue: getDisplayValue(base),
        type: "MANUAL_ENTRY",
        expired: alreadyExpired,
        expirationDate: calculatedExpirationDate
      };

      if (forceExpired) {
        if (!withinTimeFrame) {
          const foundAction = actionOptions.find((action) => action.id === base?.customActionId);

          const shouldSubmit = await confirmAction({
            confirmText: "Yes",
            cancelText: "No",
            message: `This action is limited to ${getLimitDisplay(
              foundAction?.limit
            )}. Adding this action will ignore the limit. Are you sure you want to proceed?`,
            confirmColor: "btn btn-success shadow border-0",
            cancelColor: "btn btn-danger shadow border-0"
          });

          if (shouldSubmit) {
            await submitFormAction(actionPayload);
          }
        } else {
          await submitFormAction(actionPayload);
        }
      }
    },
    [
      handleClearForm,
      checkActionLimit,
      selectedAttribute?.name,
      createAction,
      getDisplayValue,
      user?.id,
      projectId,
      handleApproveOrRejectSuccess
    ]
  );

  const debouncedOnSubmit = debounce(onSubmit, 1000, { leading: true });

  useEffect(() => {
    if (userLoading || actionLoading) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
    return () => dispatch(setGlobalLoading(false));
  }, [dispatch, userLoading, actionLoading]);

  const attributeId = watch("attributeId", attributes[0]?.id);

  // TODO(MB) refactor into custom hook
  useEffect(() => {
    if (!!attributeId) {
      const selectedAttr = find(attributes, (opt) => opt.id === attributeId);
      setSelectedAttribute(selectedAttr);
    }
  }, [attributeId, attributes, setValue]);

  // TODO(MB) refactor into custom hook
  useEffect(() => {
    if (!!plusOperator && !!assignmentOperator && selectedAttribute) {
      const operatorId =
        selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? plusOperator.id : assignmentOperator.id;
      reset({ attributeId: selectedAttribute?.id, operatorId });
    }
  }, [selectedAttribute?.type, assignmentOperator, plusOperator, selectedAttribute, reset]);

  // TODO:: update what isValid is if expires is selected (bulk log branch does isValid in a useEffect which would be helpful here
  const [isValid, setIsValid] = useState(false);

  const expires = watch("expires", false);

  useEffect(() => {
    if (!expires) {
      setIsValid(!actionId || actionId === "MANUAL_ENTRY" ? !!name?.trim() && !!value : true);
    } else {
      setIsValid(
        expires &&
          expirationPeriod &&
          expirationPeriodAmount &&
          (!actionId || actionId === "MANUAL_ENTRY" ? !!name?.trim() && !!value : true)
      );
    }
  }, [actionId, name, value, expirationPeriodAmount, expirationPeriod, expires, setIsValid]);

  const handleExpirationValues = (values) => {
    if (values.expires) {
      setValue("expirationPeriod", values.period);
      setValue("expirationPeriodAmount", +values.periodAmount);
      setValue("expires", values.expires);
    } else if (!isValid) {
      setValue("expires", false);
    }
  };

  useEffect(() => {
    if (actionId !== "MANUAL_ENTRY") {
      const action = actionOptions.find((action) => action.id === actionId);
      if (action) {
        setAction(action);
      }
    }
  }, [actionId, setAction]);

  // * Simplified hook to determine form state
  const [showManualEntryForm, setShowManualEntryForm] = useState(true);
  useEffect(() => {
    if (actionId !== "MANUAL_ENTRY") {
      setShowManualEntryForm(false);
    } else {
      setShowManualEntryForm(true);
    }
  }, [actionId, setShowManualEntryForm]);

  return (
    <form
      className="d-inline-flex justify-content-start grid gap-2 w-100 align-items-center flex-wrap"
      onSubmit={handleSubmit(debouncedOnSubmit)}
      data-testid="ACTION_FORM"
    >
      <Prompt
        when={selectedAttribute !== attributes[0] || Object.keys(dirtyFields).length > 0}
        message={messages.UNSAVED_CHANGES}
      />
      <CustomDatePicker formRegister={register} name="actionDate" width="9rem" />
      <CustomSelect
        label="ACTION"
        formRegister={register}
        name="actionId"
        options={actionOptions}
        width={actionId === "MANUAL_ENTRY" ? "10rem" : "12rem"}
        defaultValue={actionOptions.find((a) => a.id === actionId)}
        displayKey="name"
        extraWidth={true}
        control={control}
      />
      {showManualEntryForm ? (
        <>
          <UncontrolledInput labelText="NAME" formRegister={register} name="name" maxWidth="8rem" />
          <strong className="mx-1">=</strong>
          <CustomSelect
            label="ATTRIBUTE"
            formRegister={register}
            name="attributeId"
            options={attributes}
            defaultValue={attributes.find((att) => att.id === attributeId) ?? attributes[0]}
            width="8.5rem"
            displayKey="name"
            control={control}
          />
          {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
            <CustomSelect
              label="OPERATOR"
              formRegister={register}
              name="operatorId"
              width="4rem"
              options={wholeNumberOperators}
              defaultValue={wholeNumberOperators.find((op) => op.id === operatorId) ?? wholeNumberOperators[0]}
              control={control}
            />
          ) : (
            <CustomSelect
              label="OPERATOR"
              formRegister={register}
              name="operatorId"
              width="3.5rem"
              options={[assignmentOperator]}
              defaultValue={[assignmentOperator][0]}
              control={control}
            />
          )}
          {selectedAttribute?.type === ATTRIBUTES_TYPES.WHOLE_NUMBER ? (
            <UncontrolledInput labelText="VALUE" formRegister={register} name="value" maxWidth="4rem" type="number" />
          ) : (
            <CustomSelect
              label="VALUE"
              formRegister={register}
              name="value"
              displayKey="name"
              width="8.5rem"
              options={selectedAttribute?.attributeValues}
              defaultValue={
                selectedAttribute?.attributeValues.find((att) => att.id === value) ??
                selectedAttribute?.attributeValues[0]
              }
              control={control}
            />
          )}
        </>
      ) : (
        <>
          <strong className="mx-1">=</strong>
          <CustomActionTextDisplay actionId={selectedAction?.id} actionOptions={actionOptions} />
        </>
      )}
      <span className="d-flex grid gap-2" style={{ marginLeft: "auto" }}>
        <ActionFormButton
          data-testid="SUBMIT_ACTION"
          disabled={actionLoading || userLoading || !isValid}
          type="submit"
          className="btn btn-outline-success shadow border-0"
        >
          <i className="fa fa-check" />
        </ActionFormButton>
        <ActionFormButton
          type="button"
          className="btn border-1 text-danger btn-lg justify-content-center d-flex"
          onClick={handleClearForm}
          buttonType="delete"
        >
          <i className="fa fa-times" />
        </ActionFormButton>
      </span>
      <FeatureFlag featureName={FEATURE_FLAGS.EXPIRING_ACTIONS}>
        {showManualEntryForm && <ActionExpirationForm emitExpiration={handleExpirationValues} />}
      </FeatureFlag>
    </form>
  );
}

function CustomActionTextDisplay({ actionId, actionOptions }) {
  const action = actionOptions.find((action) => action.id === actionId);
  if (isEmpty(actionId) || isEmpty(action)) {
    return null;
  }

  return (
    <div className="d-flex justify-content-around" style={{ maxWidth: "35%", width: "100%" }}>
      <span>{action.result}</span>
    </div>
  );
}

export default ActionForm;
