import React from "react";
import { ActionFormButton } from "../../theme/StyledComponents";
import DeleteRow from "../DeleteRow/DeleteRow";
import messages from "../../api/messages";

const AttributeFormActionButtons = ({
  dirtyFieldsEmpty,
  isValid,
  saving,
  deleting,
  submit,
  reset,
  shouldShowDeleteModal,
  handleDelete
}) => {
  return (
    <div
      className="d-flex justify-content-around ms-lg-auto me-lg-0 me-sm-auto ms-sm-auto"
      style={{ minWidth: "10rem" }}
    >
      <ActionFormButton
        data-testid="SAVE_ATTRIBUTE"
        type="button"
        onClick={submit}
        className="btn btn-outline-success shadow border-0"
        disabled={dirtyFieldsEmpty || !isValid || saving || deleting}
      >
        <i className="fa fa-check" />
      </ActionFormButton>

      <ActionFormButton
        disabled={dirtyFieldsEmpty || !isValid || saving || deleting}
        data-testid="undo"
        type="button"
        className="btn shadow border-0 btn-outline-primary"
        buttonType="undo"
        onClick={reset}
      >
        <i className="fa fa-undo" />
      </ActionFormButton>
      <span data-testid="delete">
        <DeleteRow
          confirmText={messages.ATTRIBUTE_DELETE_CONFIRM}
          handleDelete={handleDelete}
          shouldUseModal={shouldShowDeleteModal}
        />
      </span>
    </div>
  );
};

export default AttributeFormActionButtons;
