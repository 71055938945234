import apiHandler from "api";
import jwt from "jwt-decode";

import config from "../config";
import { saveAuthToken, setEnablePassphrase, saveUserData, setRedirectLoading } from "features/login/loginSlice";
import endpoint from "./endpoint";
import { showAlert } from "features/Common/CommonSlice";

const authenticateUser = {
  // Asynchronous thunk actions
  authenticate(loginFormData) {
    // Step 1: Check if email is a known sso domain
    // if yes, redirect
    // if not, display passphrase input
    // submit to local plogin
    return async (dispatch) => {
      dispatch(setRedirectLoading(true));
      const result = await apiHandler({
        url: endpoint.LOGIN_SSO,
        method: "POST",
        data: loginFormData,
      });

      const { data } = result;

      if (data.success) {
        // * do not show passphrase input
        dispatch(setEnablePassphrase(false));
        window.location.replace(data.redirect);
        // * wait a sec before resetting redirectLoading so buttons don't 'flash' active too much
        setTimeout(() => {
          dispatch(setRedirectLoading(false));
        }, 1000);
      } else {
        // * SSO not enabled
        dispatch(setEnablePassphrase(true));
        dispatch(setRedirectLoading(false));
      }
    };
  },
  authenticatePassphrase(loginFormData, history) {
    return async (dispatch) => {
      const result = await apiHandler({
        url: endpoint.LOGIN,
        method: "POST",
        data: loginFormData,
      });

      const { token, success, redirect, message } = result.data;

      if (!success) {
        dispatch(
          showAlert({
            message,
            type: "danger",
          }),
        );
        if (redirect)
          return history.push({
            pathname: "/register",
          });
      } else {
        if (redirect) {
          window.location.replace(redirect);
        }

        dispatch(saveAuthToken(token));
        dispatch(saveUserData(jwt(token)));
        history.push(config.HOME_ROUTE);
      }
    };
  },
  register() {
    console.log("Please Register");
  },
  signout(history) {
    return (dispatch) => {
      dispatch(saveAuthToken(null));
      dispatch(saveUserData(null));
      history.push("/login");
    };
  },
};

export default authenticateUser;
