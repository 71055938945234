import React from "react";

const CustomImageDisplay = ({ style, height, className, url, name }) => {
  return (
    <div style={style} className={className}>
      <img
        alt={name ?? "icon"}
        src={url}
        style={{
          maxWidth: "100%",
          objectFit: "cover",
          maxHeight: height,
          ...style
        }}
        onError={(e) => {
          console.error(`Error: CustomImage (name: ${name} url: ${url}) load: ${e}`);
        }}
      />
    </div>
  );
};

export default CustomImageDisplay;
