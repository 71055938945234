import React, { useEffect, useState } from "react";
import { SLACK_APP_INSTALL_URL } from "api/integrations";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { UncontrolledTooltip } from "reactstrap";

import useFeatureFlag from "hocs/FeatureFlag/hooks/useFeatureFlag";
import { FEATURE_FLAGS } from "hocs/FeatureFlag/utils";
import { selectAccountData, selectProjectData } from "features/userData/userDataSlice";
import { selectUserId } from "../../app/selectors/index";
import { encodeQueryParamObject } from "utils/encryption.utils";

const style = {
  alignItems: "center",
  color: "#000",
  backgroundColor: "#fff",
  border: "1px solid #ddd",
  borderRadius: "4px",
  display: "inline-flex",
  fontFamily: "Lato, sans-serif",
  fontSize: "14px",
  fontWeight: 600,
  height: "48px",
  width: "48px",
  justifyContent: "center",
  textDecoration: "none",
  boxShadow: "1px 1px 2px 1px rgba(0,0,0,0.38)"
};

export const useSlackInstallUrl = () => {
  const accountData = useSelector(selectAccountData);
  const selectedProject = useSelector(selectProjectData);
  const [url, setURL] = useState("");
  const { data: slackData } = useQuery(SLACK_APP_INSTALL_URL);
  const userId = useSelector(selectUserId);

  useEffect(() => {
    if (!accountData?.id || !userId || !selectedProject?.id) {
      // button will be disabled without a truthy string for each
      setURL("");
    }
    const state = {
      accountId: accountData?.id,
      userId,
      projectId: selectedProject?.id
    };

    if (slackData?.slackAppInstallUrl) {
      // include state which has userId, projectId and accountId
      setURL(`${slackData?.slackAppInstallUrl}&state=${encodeQueryParamObject(state)}`);
    }
  }, [slackData?.slackAppInstallUrl, accountData?.id, userId, selectedProject?.id]);

  return url;
};

function SlackButton() {
  const integrationsEnabled = useFeatureFlag(FEATURE_FLAGS.INTEGRATION_ACCESS);
  const url = useSlackInstallUrl();
  const disabled = !url;

  return integrationsEnabled ? (
    <a
      href={url}
      style={{
        ...style,
        backgroundColor: disabled ? "rgb(211,211,211, 0.7)" : "white",
        pointerEvents: disabled ? "none" : "inherit"
      }}
      disabled={disabled}
      data-testid="SLACK_BUTTON"
    >
      <SlackSvgLogo />
    </a>
  ) : (
    <>
      <a
        href="#"
        id="slack-tooltip-target"
        style={{
          ...style,
          backgroundColor: "rgb(211,211,211, 0.7)",
          pointerEvents: "pointer-enter"
        }}
        data-testid="SLACK_BUTTON"
      >
        <SlackSvgLogo />
      </a>
      <UncontrolledTooltip hideArrow placement="top" target="slack-tooltip-target">
        Please contact us if you're interested in integrations! <a href="mailto:info@katilyst.com">info@katilyst.com</a>
      </UncontrolledTooltip>
    </>
  );
}

export default SlackButton;

function SlackSvgLogo() {
  const svgStyle = {
    height: "36px",
    width: "36px",
    marginRight: 0
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.8 122.8" style={svgStyle}>
      <path
        d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
        fill="#e01e5a"
      ></path>
      <path
        d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
        fill="#36c5f0"
      ></path>
      <path
        d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
        fill="#2eb67d"
      ></path>
      <path
        d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
        fill="#ecb22e"
      ></path>
    </svg>
  );
}
