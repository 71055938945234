import styled from "styled-components";

export const StyledRadio = styled("input").attrs({
  type: "radio"
})`
  display: none;
`;

export const StyledRadioList = styled("div").attrs({
  className: "card"
})`
  position: absolute;
  top: 2.5rem;
  opacity: 1;

  &:hover {
    opacity: 10;
    z-index: 1000;
  }

  z-index: 1000;
  min-width: 16rem;

  label {
    width: 100%;
    background: #ffffff;
    margin: 0;
    height: 2.5rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;

    &:hover {
      background: #e2edff;
    }
  }
`;
