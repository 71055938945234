import { createSlice, createSelector } from "@reduxjs/toolkit";
import { map, keyBy, get } from "lodash";
import DefaultAvatar from "assets/user-icon.png";

const UserProfileSourceEnum = {
  LOCAL: "LOCAL",
  SSO_SAML: "SSO_SAML"
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState: {
    authToken: null,
    userList: [],
    selectedUser: null,
    userImage: DefaultAvatar,
    accountData: null,
    email: "",
    ssoEnabled: false,
    accountRoles: {},
    userAccountAccess: {
      tier: "FREEMIUM",
      permissions: ["user"]
    },
    redirect: null,
    projectData: null,
    projects: [],
    accounts: []
  },
  reducers: {
    setProjects(state, action) {
      state.projects = action.payload;
    },
    setAccounts(state, action) {
      state.accounts = action.payload;
    },
    setProjectData: (state, action) => {
      if (action) {
        state.projectData = action.payload;
      }
    },
    setAccountData: (state, action) => {
      if (action) {
        state.accountData = action.payload;
      }
    },
    saveUserList: (state, action) => {
      state.userList = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    updateUserList: (state, action) => {
      const newUserData = action.payload;
      const newUserList = map(state.userList, (user) => (user.id === newUserData.id ? newUserData : user));

      state.userList = newUserList;
    },
    setUserImage: (state, action) => {
      state.userImage = action.payload || DefaultAvatar;
    },
    setBaseUserData: (state, action) => {
      state.userImage = action?.payload?.avatar || DefaultAvatar;
      state.ssoEnabled = action?.payload?.source === UserProfileSourceEnum.SSO_SAML;
      state.accountRoles = keyBy(action.payload.userAccounts, "accountId");
      state.email = get(action, "payload.email", "");
    },
    setUserAccountAccess: (state, action) => {
      state.userAccountAccess = action.payload || {
        tier: "FREEMIUM",
        permissions: ["user"]
      };
    },
    setRedirect: (state, action) => {
      state.redirect = action.payload;
    }
  }
});

export const {
  setProjects,
  setAccounts,
  setProjectData,
  setAccountData,
  saveUserList,
  setSelectedUser,
  updateUserList,
  setUserImage,
  setBaseUserData,
  setUserAccountAccess,
  setRedirect
} = userDataSlice.actions;

const useBaseState = (state) => state.userData;

export const selectSelectedUser = createSelector(useBaseState, ({ selectedUser }) => selectedUser);

export const selectProjectData = createSelector(useBaseState, ({ projectData }) => projectData);

export const selectAccountData = createSelector(useBaseState, ({ accountData }) => accountData);

export const selectAccountList = createSelector(useBaseState, ({ accounts }) => accounts ?? []);

export const selectProjectList = createSelector(useBaseState, ({ projects }) => projects ?? []);

export const selectUserEmail = createSelector(useBaseState, ({ email }) => email);

export const selectSSOEnabled = createSelector(useBaseState, ({ ssoEnabled }) => ssoEnabled);

export const selectUserAccountAccess = createSelector(useBaseState, ({ userAccountAccess }) => userAccountAccess);

export const selectProjectDict = createSelector(useBaseState, ({ projects }) => keyBy(projects, "id"));

export const selectAccountDict = createSelector(useBaseState, ({ accounts }) => keyBy(accounts, "id"));

export default userDataSlice.reducer;
