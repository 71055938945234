import { gql } from "@apollo/client";

export const GET_SLACK_CHANNELS = gql`
  query GET_SLACK_CHANNELS($accountId: String!) {
    slackChannels(accountId: $accountId) {
      id
      name
    }
  }
`;
export const GET_MS_TEAMS_CHANNELS = gql`
  query msTeamsChannels($accountId: String!) {
    msTeamsChannels(accountId: $accountId) {
      id
      name
    }
  }
`;

export const GET_INTEGRATIONS = gql`
  query integrations($accountId: String!, $projectId: String!) {
    integrations(accountId: $accountId, projectId: $projectId) {
      id
      integrationTeamName
      integrationTeamId
      type
      createdAt
      webhooks {
        id
        channelName
        channelId
        createdAt
      }
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation deleteIntegration($integrationId: String!) {
    deleteIntegration(integrationId: $integrationId) {
      success
      message
    }
  }
`;

export const DELETE_WEBHOOK = gql`
  mutation deleteWebhook($webhookId: String!) {
    deleteWebhook(webhookId: $webhookId) {
      success
      message
    }
  }
`;

export const SAVE_WEBHOOK = gql`
  mutation saveWebhook($data: AccountMessagingWebhookSaveInput!) {
    saveWebhook(data: $data) {
      success
      message
    }
  }
`;

export const SLACK_APP_INSTALL_URL = gql`
  query SLACK_APP_INSTALL_URL {
    slackAppInstallUrl
  }
`;

export const UPDATE_INTEGRATION_ORDER = gql`
  mutation updateIntegrationOrder($data: UpdateListOrderArgs!) {
    updateIntegrationOrder(data: $data) {
      success
      message
    }
  }
`;
