import { useEffect } from "react";
import messages from "../api/messages";

export const callSubmit = async (valuesForSubmit, submitUploadAction, configId, projectId, file) => {
  return await submitUploadAction({
    variables: {
      configId: configId,
      projectId,
      config: valuesForSubmit,
      data: {
        file,
        fileCategory: "csv/text",
      },
    },
  });
};

const callValidateDateColumn = async (projectId, actionDateMappedColumn, file, validateDateColumn, logId) => {
  return await validateDateColumn({
    variables: {
      projectId,
      logId,
      column: actionDateMappedColumn,
      data: {
        file,
        fileCategory: "csv/text",
      },
    },
  });
};

const callValidateEmailColumn = async (projectId, emailMappedColumn, file, validateEmailColumn, logId) => {
  return await validateEmailColumn({
    variables: {
      projectId,
      logId,
      column: emailMappedColumn,
      data: {
        file,
        fileCategory: "csv/text",
      },
    },
  });
};

export const callValidateSelectedColumn = async (
  actionMappedColumn,
  projectId,
  logId,
  validateSelectedColumn,
  checkWholeNumbers,
  file,
) => {
  return await validateSelectedColumn({
    variables: {
      projectId,
      column: actionMappedColumn,
      logId,
      checkWholeNumbers,
      data: {
        file,
        fileCategory: "csv/text",
      },
    },
  });
};

// * checkConfigName(): ensures config name is unique
export const checkConfigName = async (name, projectId, checkConfigNameUnique) => {
  return await checkConfigNameUnique({
    variables: {
      name,
      projectId,
    },
  });
};

export const compareConfigs = async (valuesForSubmit, compareActionUploadConfigs, projectId, configId) => {
  return await compareActionUploadConfigs({
    variables: {
      configId: configId,
      projectId,
      updatedConfig: valuesForSubmit,
    },
  });
};

export const handleConfigCreate = async (updateValues, createNewActionMappingConfig, projectId, handleError) => {
  return await createNewActionMappingConfig({
    variables: { config: updateValues, projectId: projectId },
    onError: (error) => handleError(error.message),
  });
};

export const handleConfigUpdate = async (
  configIdToUpdate,
  updateValues,
  updateActionMappingConfig,
  projectId,
  handleError,
) => {
  return await updateActionMappingConfig({
    variables: { configId: configIdToUpdate, config: updateValues, projectId: projectId },
    onError: (error) => handleError(error.message),
  });
};

export const useValidateActionColumn = (
  actionMappedColumn,
  dirtyFields,
  setActionColumnMessage,
  file,
  projectId,
  logId,
  validateSelectedColumn,
  setSelectedPredefinedActionMaps,
  customActionFromConfig = null,
) => {
  useEffect(() => {
    if (file) {
      setSelectedPredefinedActionMaps([]);
      setActionColumnMessage({ success: false, message: messages.COLUMN_SELECTION_REQUIRED, displayValue: null });

      if (!!actionMappedColumn && dirtyFields["actionMappedColumn"] && actionMappedColumn !== "select option") {
        callValidateSelectedColumn(
          actionMappedColumn,
          projectId,
          logId,
          validateSelectedColumn,
          false,
          file,
          setActionColumnMessage,
        ).then((res) => {
          if (res?.data.validateActionColumn.success) {
            setActionColumnMessage({
              success: true,
              message: "Valid Action Format in File \n(define Action Mapping(s) below)",
              displayValue: res?.data.validateActionColumn.displayValue,
            });
          } else {
            setActionColumnMessage({
              success: false,
              message: res?.data.validateActionColumn.message,
              displayValue: res?.data.validateActionColumn.displayValue,
            });
          }
        });
      }
    } else {
      setActionColumnMessage({ success: false, message: "", displayValue: null });
    }
  }, [
    actionMappedColumn,
    dirtyFields,
    setActionColumnMessage,
    setSelectedPredefinedActionMaps,
    file,
    projectId,
    validateSelectedColumn,
    logId,
    customActionFromConfig,
  ]);
};

export const useValidateActionDateColumnAndFormat = (
  actionDateMappedColumn,
  setActionDateColumnMessage,
  logId,
  setValue,
  dirtyFields,
  file,
  validateDateColumn,
  projectId,
) => {
  useEffect(() => {
    if (!!file) {
      setActionDateColumnMessage({ success: false, message: messages.COLUMN_SELECTION_REQUIRED, displayValue: null });
      if (
        logId &&
        dirtyFields["actionDateMappedColumn"] &&
        !!actionDateMappedColumn &&
        actionDateMappedColumn !== "select option"
      ) {
        callValidateDateColumn(projectId, actionDateMappedColumn, file, validateDateColumn, logId).then((res) => {
          if (res.data.validateDateColumnFormat.success) {
            const format = res.data.validateDateColumnFormat.format;
            setValue("actionDateMappedColumnFormat", format);
            setActionDateColumnMessage({
              success: true,
              message: "Valid Action Dates in File",
              displayValue: res.data.validateDateColumnFormat.displayValue,
            });
          } else {
            setActionDateColumnMessage({
              success: false,
              message: res.data.validateDateColumnFormat.message,
              displayValue: res.data.validateDateColumnFormat.displayValue,
            });
          }
        });
      }
    } else {
      setActionDateColumnMessage({ success: false, message: "", displayValue: null });
    }
  }, [
    actionDateMappedColumn,
    setActionDateColumnMessage,
    logId,
    setValue,
    dirtyFields,
    file,
    validateDateColumn,
    projectId,
  ]);
};

export const useValidateEmailColumnSelection = (
  emailMappedColumn,
  logId,
  dirtyFields,
  setEmailColumnMessage,
  file,
  validateEmailColumn,
  projectId,
) => {
  useEffect(() => {
    if (file) {
      setEmailColumnMessage({ success: false, message: messages.COLUMN_SELECTION_REQUIRED, displayValue: null });
      if (logId && dirtyFields["emailMappedColumn"] && !!emailMappedColumn && emailMappedColumn !== "select option") {
        callValidateEmailColumn(projectId, emailMappedColumn, file, validateEmailColumn, logId).then((res) => {
          if (res.data.validateEmailColumn.success) {
            return setEmailColumnMessage({
              success: true,
              message: "Valid Email Format in File",
              displayValue: res.data.validateEmailColumn.displayValue,
            });
          } else {
            return setEmailColumnMessage({
              success: false,
              message: res.data.validateEmailColumn.message,
              displayValue: res.data.validateEmailColumn.displayValue,
            });
          }
        });
      }
    } else {
      setEmailColumnMessage({ success: false, message: "", displayValue: null });
    }
  }, [emailMappedColumn, logId, dirtyFields, setEmailColumnMessage, file, validateEmailColumn, projectId]);
};
