import React, { useState } from "react";

import styled from "styled-components";

const CI = styled.div`
  background-color: #e3f8e9;
  color: #5a864b;
  padding: 0 10px;
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  font-size: 0.5rem;
  min-height: 3.125rem;
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-bottom: 2px solid #5a864b;
  border-radius: 5px 5px 0 0;

  &:focus-within {
    border-bottom: 3px solid #5a864b;
  }

  &.error {
    border: 2px solid red;
    border-radius: 5px;
  }

  label {
    font-size: 10px;
    position: absolute;
    left: 5px;
    top: 3px;
  }

  i {
    font-size: 1.5rem;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    border: none;
    background-color: transparent;
    color: ${(props) => props.textColor ?? "#5a864b"};
    padding: 0;
    font-size: 1.2rem;
    width: 100%;

    &:focus-within {
      outline: none;
      box-shadow: none;
      background-color: transparent !important;
      border: none;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: 0.55;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
`;

export const UncontrolledInput = ({
  placeholder,
  labelText,
  name,
  defaultValue,
  formRegister,
  validation = {},
  type,
  passwordInput = false,
  disabled = false,
  ...rest
}) => {
  const INPUT_TYPES = {
    TEXT: "text",
    PASSWORD: "password"
  };
  const [inputType, setType] = useState(passwordInput ? INPUT_TYPES.PASSWORD : type);

  const handlePasswordToggle = () => {
    inputType === INPUT_TYPES.PASSWORD ? setType(INPUT_TYPES.TEXT) : setType(INPUT_TYPES.PASSWORD);
  };

  return (
    <CI {...rest}>
      <label>{labelText}</label>
      <input
        type={inputType}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={name}
        id={name}
        data-testid={rest?.testId}
        {...(!!formRegister && { ...formRegister(name, { ...validation }) })}
        disabled={disabled}
        maxLength="500"
      />
      {passwordInput && (
        <div className="align-self-center">
          {inputType === INPUT_TYPES.PASSWORD ? (
            <i onClick={handlePasswordToggle} className="fa fa-thin fa-eye" />
          ) : (
            <i onClick={handlePasswordToggle} className="fa fa-thin fa-eye-slash" />
          )}
        </div>
      )}
    </CI>
  );
};
