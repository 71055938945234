import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useQueryParams } from "./useQueryParams";
import { useUpdateQueryParams } from "./useUpdateQueryParams";
import { showAlert } from "features/Common/CommonSlice";

export function useErrorMessageFromQueryParams() {
  const updateQueryParams = useUpdateQueryParams();
  const queryParams = useQueryParams();
  const errorMessage = queryParams.get("errorMessage");
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) {
      const decoded = decodeURIComponent(errorMessage);
      console.warn(`Received error message in query params: ${decoded}}`);
      dispatch(showAlert({ message: decoded || "Error", type: "danger" }));
      queryParams.delete("errorMessage");
      updateQueryParams(queryParams, true); // we want to use history.replace so you can navigate backwards
    }
  }, [errorMessage, dispatch, updateQueryParams]); // eslint-disable-line
}
