import React, { useCallback, useState } from "react";
import { Alert } from "reactstrap";
import { useSelector } from "react-redux";
import { get } from "lodash";
import Header from "./Header/Header";
import SideDrawer from "../../components/SideMenu/SideDrawer";
import { GlobalLoading } from "components/Loading";
import { ContentContainer } from "theme/StyledComponents";
import {
  useAccounts,
  useClearAlert,
  useIntegrations,
  useLoggedInUser,
  useMSTeamsChannels,
  useOperators,
  useSlackChannels,
  useUserAccountAccess
} from "hooks/application.hooks";
import {
  useAttributes,
  useCheckUserHasProjects,
  useCustomActions,
  useErrorMessageFromQueryParams,
  useProjectUsers,
  useProjects
} from "hooks/application";

const Layout = ({ children }) => {
  const { alert } = useSelector((state) => state.commonState);
  useAccounts();
  useProjects();
  useAttributes();
  useLoggedInUser();
  useOperators();
  useCustomActions();
  useSlackChannels();
  useMSTeamsChannels();
  useIntegrations();
  useClearAlert(alert?.message);
  useUserAccountAccess();
  useErrorMessageFromQueryParams();
  useCheckUserHasProjects();
  useProjectUsers();

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const { globalLoading } = useSelector((state) => state.commonState);

  const drawerToggleClickHandler = useCallback(() => {
    setSideDrawerOpen(!sideDrawerOpen);
  }, [setSideDrawerOpen, sideDrawerOpen]);

  return (
    <>
      <Header drawerToggleClickHandler={drawerToggleClickHandler} />
      <SideDrawer closeSideDrawer={drawerToggleClickHandler} show={sideDrawerOpen} />
      {globalLoading && <GlobalLoading />}
      <ContentContainer>{children}</ContentContainer>
      <div
        className="position-absolute"
        style={{
          top: "7rem",
          right: "2rem",
          width: "15rem",
          maxHeight: "fit-content"
        }}
      >
        {alert?.message && (
          <Alert
            color={get(alert, "type", "success")}
            isOpen={true}
            fade={true}
            style={{ zIndex: 1600, wordBreak: "break-word" }}
            className="text-left"
            data-testid={`ALERT-${alert.type}`}
          >
            {typeof alert?.message === "string" ? alert?.message : "There was an error, please try again"}
          </Alert>
        )}
      </div>
    </>
  );
};

export default React.memo(Layout);
