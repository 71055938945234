import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card, Collapse } from "reactstrap";

import { StyledCardHeader } from "features/Attributes/Attributes.style";
import VerticalGripIcon from "assets/vertical-grip-icon";
import DeleteRow from "./DeleteRow/DeleteRow";

const CardHeader = styled(StyledCardHeader)`
  height: 4rem;
  background: #f7f9fa;
  border-bottom: 1px solid #d6d6d6;
  border-radius: 10px;
`;

const IconButton = styled.i`
  text-align: left;
  color: #78909c;
  transform: scale(2.5);
`;

const ControlPanel = styled.div`
  min-width: 10rem;
`;

const BodyWrapper = styled(Collapse)`
  padding: 0.75rem 2rem;
`;

function CollapsibleCard({ TitleRow, Body, onDelete, defaultOpen = false, testId }) {
  const [isOpen, setOpen] = React.useState(defaultOpen);

  const toggle = React.useCallback(() => setOpen(!isOpen), [isOpen]);

  return (
    <Card className="my-3">
      <CardHeader>
        <div className="pt-auto h-100 d-flex align-items-center card-header-child">
          <div className="d-flex justify-content-between align-items-center me-3">
            <div className="ps-0 me-3">
              <VerticalGripIcon
                style={{
                  transform: "scale(2.2)"
                }}
              />
            </div>

            <TitleRow />
          </div>
          <div
            className="d-flex justify-content-start pb-2 flex-grow-1"
            onClick={toggle}
            data-testid={`CARD_TOGGLE_${testId}`}
          >
            <IconButton className={`font-weight-bold fa ${!isOpen ? "fa-angle-right" : "fa-angle-down"}`} />
          </div>

          <ControlPanel className="d-flex justify-content-between">
            <span className="ms-auto">
              <DeleteRow handleDelete={onDelete} testId={`DELETE_BUTTON_${testId}`} shouldUseModal />
            </span>
          </ControlPanel>
        </div>
      </CardHeader>
      <BodyWrapper isOpen={isOpen}>
        <Body />
      </BodyWrapper>
    </Card>
  );
}

CollapsibleCard.propTypes = {
  TitleRow: PropTypes.elementType.isRequired,
  Body: PropTypes.elementType.isRequired,
  onDelete: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool
};

export default CollapsibleCard;
